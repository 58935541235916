import React, { useState } from "react";
import AttributeDropdown from "./AttributeDropdown";
import CategoryPagination from "./CategoryPagination";
import DiscreteSlider from "./DiscreteSlider";
import ProductCategoryTabs from "./ProductCategoryTabs";
import ProductCategoryViewToggle from "./ProductCategoryViewToggle";
import ProductListHeader from "./ProductListHeader";
import ShowAllSwitch from "./ShowAllSwitch";
import StatusText from "./StatusText";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import { getValueForKey } from "./helper";
import { Box } from "@material-ui/core";
import ProductViewMain from "./ProductViewMain";
import SmartCopySwitch from "./SmartCopySwitch";
import ProductActions from "./ProductActions";

const ProductViewContainer = (props) => {
  const [resetPageSize, setresetPageSize] = useState(false);
  const [isSmartCopy, SetIsSmartCopy] = useState(false);

  const handleResetPageSize = () => {
    setresetPageSize(!resetPageSize);
  };

  return (
    <>
      <LoadingOverlay active={props.isloading || props.isSaving} />
      <ProductViewHeader
        count={props.count}
        singleEdit={props.singleEdit}
        resetPageSize={resetPageSize}
        dropdownData={props.dropdownData}
        isSmartCopy={isSmartCopy}
        SetIsSmartCopy={SetIsSmartCopy}
      />
      <ProductViewMain {...props} isSmartCopy={isSmartCopy} />
      <ProductViewFooter
        count={props.count}
        saveEdits={props.saveEdits}
        singleEdit={props.singleEdit}
        changesArray={props.changesArray}
        handleConfidence={props.handleConfidence}
        handleResetPageSize={handleResetPageSize}
      />
    </>
  );
};

export default ProductViewContainer;

const ProductViewHeader = (props) => {
  return (
    <>
      <Box width="100%" my={1}>
        <DiscreteSlider
          imageCount={getValueForKey("image_count")}
          resetPageSize={props.resetPageSize}
        />
        <ProductListHeader
          count={props.count}
          singleEdit={props.singleEdit}
          list={getValueForKey("list") === "true"}
          listattribute={getValueForKey("showall") === "true"}
        />
      </Box>
      <Box position="relative" width="100%" my={1}>
        <Box
          display="flex"
          borderBottom="1px solid #80808061"
          justifyContent="space-between"
        >
          <ProductCategoryTabs />
          <ProductCategoryViewToggle />
        </Box>
        <Box display="flex" mt={2} justifyContent="space-between">
          <StatusText
            list={getValueForKey("list") === "true"}
            singleEdit={props.singleEdit}
            listattribute={getValueForKey("showall") === "true"}
            isVariant={getValueForKey("type") === "true"}
          />
          <SmartCopySwitch
            checked={props.isSmartCopy}
            handleChange={() => props.SetIsSmartCopy(!props.isSmartCopy)}
          />
          {getValueForKey("list") === "true" ? (
            <ShowAllSwitch />
          ) : (
            <AttributeDropdown dropdownData={props.dropdownData} />
          )}
        </Box>
      </Box>
    </>
  );
};

const ProductViewFooter = (props) => {
  return (
    props.count > 0 && (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mt={2}
      >
        <CategoryPagination count={props.count} showActions />

        <ProductActions
          show={props.singleEdit || getValueForKey("list") === "true"}
          saveEdits={props.saveEdits}
          handleConfidence={props.handleConfidence}
          handleResetPageSize={props.handleResetPageSize}
        />
      </Box>
    )
  );
};
