import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import AntSwitch from "./AntSwitch";
import { handleReplace, QueryStringToObject } from "./helper";

const ShowAllSwitch = ({
  labelLeft = "",
  labelRight = "Show All",
  urlKey = "showall",
  name = "checkedC",
}) => {
  const history = useHistory();
  useLocation();

  const handleChange = (e) => {
    handleReplace(urlKey, e.target.checked, history);
  };

  return (
    <Box marginLeft="9%" marginTop="6px">
      <Typography component="span" variant="caption">
        <Grid component="label" container spacing={1}>
          <Grid item>{labelLeft}</Grid>

          <Grid item>
            <AntSwitch
              checked={QueryStringToObject()[urlKey] === "true"}
              onChange={handleChange}
              name={name}
            />
          </Grid>
          <Grid item>{labelRight}</Grid>
        </Grid>
      </Typography>
    </Box>
  );
};

export default ShowAllSwitch;
