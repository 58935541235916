import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  onChangeGridSelectAttribute,
  onChangeListSelectAttribute,
} from "../../../reducers/exploreCategoryReducer";
import Select from "./Select";

const SelectDropdown = ({
  dropdownData,
  dropdownStatus,
  productDetails,
  selected,
  listView = false,
  attribute,
  isSmartCopy,
  handleSmartCopyCount,
  handleSmartCopyValue,
  smartCopy,
  ...props
}) => {
  const [value, setValue] = useState(selected);

  useEffect(() => {
    if (dropdownStatus) {
      setValue(selected);
    }
  }, [dropdownStatus]);

  useEffect(() => {
    if (isSmartCopy) {
      if (
        smartCopy?.prev?.id === selected.id &&
        value.value === selected.value
      ) {
        handleSmartCopyCount();
        setValue(smartCopy?.curr);
        handleOnChange(smartCopy?.curr, "add");
      }
    }
  }, [smartCopy?.curr, isSmartCopy]);

  const handleOnChange = (params, action) => {
    if (!listView) {
      props.onChangeGridSelectAttribute(
        { ...params, tenant_attribute_id: productDetails.tenant_attribute_id },
        productDetails,
        action
      );
    } else {
      const payload = {
        product_id: attribute.product_id,
        product_attribute_mapping_id: attribute.product_attribute_mapping_id,
        tenant_attribute_id: attribute.tenant_attribute_id,
        tenant_attribute_value: attribute.tenant_attribute_value,
        new_value: params.value,
        edited: true,
      };
      props.onChangeListSelectAttribute(payload, action);
    }
  };

  const onChange = (obj) => {
    if (obj.value === value.value) return;
    if (isSmartCopy && value.value === selected.value) {
      handleSmartCopyValue({ prev: value, curr: obj });
    }
    setValue(obj);
    selected.label === obj.label
      ? handleOnChange(obj, "delete")
      : handleOnChange(obj, "add");
  };

  return (
    !!dropdownData?.length && (
      <Select
        options={dropdownData}
        selected={selected}
        menuPortalTarget={document.querySelector("body")}
        id="exCatSelectProductAttr"
        name="product-attribute"
        value={value}
        onChange={onChange}
        product_attribute_status={listView?attribute.product_attribute_status:productDetails.product_attribute_status}
      />
    )
  );
};

const mapStateToProps = (state) => ({
  dropdownStatus: state.exploreCategoryReducer.dropdownStatus,
});

export default connect(mapStateToProps, {
  onChangeGridSelectAttribute,
  onChangeListSelectAttribute,
})(SelectDropdown);
