const COLUMNS = [
  {
    Header: "Permission ID",
    accessor: "id",
    disableSortBy: true,
  },
  {
    Header: "Permission Name",
    accessor: "name",
    disableSortBy: true,
  },
];

export default COLUMNS;
