import React, { useReducer, useState } from 'react'
import NotificationFilterComponent from './notification-type-filter'
import StatusFilterComponent from './status-filter'
import './index.css'
import NotificationResults from './notification-results/notificationResults'
import NotificationSettings from './components/notification-settings'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_NOTIFICATION_TYPE':
      return { ...state, selecNotifiType: action.payload }
    case 'SET_SELECTED_STATUS_TYPE':
      return { ...state, selecStatusType: action.payload }
    case 'SET_FILTER_OPTIONS':
      return { ...state, filterOptions: action.payload }
    default:
      return state
  }
}
const NotificationPanelContainer = (props) => {
  const intialState = {
    selecNotifiType: '',
    selecStatusType: { label: 'Active', value: 'active' },
    filterOptions: {
      notification_type: '',
      level: '',
      status: 'active',
    },
  }

  const [state, dispatch] = useReducer(notificationReducer, intialState)
  const [notificationSettings, setNotificationSettings] = useState(false)
  const onNotifiTypeSelect = (option) => {
    dispatch({ type: 'SET_SELECTED_NOTIFICATION_TYPE', payload: option })
  }
  const onStatusSelect = (option) => {
    dispatch({ type: 'SET_SELECTED_STATUS_TYPE', payload: option })
  }

  const onFilterClick = () => {
    const filterOptions = {
      notification_type: state.selecNotifiType ? state.selecNotifiType.id : '',
      level: '',
      status: state.selecStatusType ? state.selecStatusType.value : '',
    }
    dispatch({ type: 'SET_FILTER_OPTIONS', payload: filterOptions })
  }
  return (
    <>
      <div id='notifiPanelDiv' className='notifi-panel-div'>
        <NotificationSettings
          getUserNotificationTypes={props.getUserNotificationTypes}
          setUserNotificationTypes={props.setUserNotificationTypes}
          items={props.userNotificationTypes}
          visible={notificationSettings}
          setNotificationSettings={setNotificationSettings}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <NotificationFilterComponent
              value={state.selecNotifiType}
              onChange={onNotifiTypeSelect}
              isClearable={true}
              getNotificationTypes={props.getNotificationTypes}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StatusFilterComponent
              value={state.selecStatusType}
              onChange={onStatusSelect}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant='contained'
              color='primary'
              className='p-ml-2 p-mt-4 button-primary'
              onClick={onFilterClick}
            >
              Filter
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            justify='flex-end'
            alignContent='flex-end'
            className="settings-btn"
          >
            <Button
              variant='contained'
              color='primary'
              className='p-ml-2 p-mt-4 '
              onClick={() => setNotificationSettings(true)}
            >
              {/* <i className="fa fa-cog" /> */}
              Settings
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction='column'
          xs={12}
          className='notification-results'
        >
          {props.notificationData && (
            <>
              <NotificationResults
                notificationData={props.notificationData}
                getNotifications={props.getNotifications}
                performAction={props.performAction}
                filterOptions={state.filterOptions}
                setNotifications={props.setNotifications}
                count={props.count}
              />
            </>
          )}
        </Grid>
      </div>
    </>
  )
}

NotificationPanelContainer.propTypes = {
  getNotifications: PropTypes.func,
  notificationData: PropTypes.array,
  performAction: PropTypes.func,
  getNotificationTypes: PropTypes.func,
  onFilter: PropTypes.func,
  getUserNotificationTypes: PropTypes.func,
  setNotifications: PropTypes.func,
  count: PropTypes.number,
}

export default NotificationPanelContainer
