import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded'
import { connect } from 'react-redux'
import { deleteBatch } from '../../../actions/dashboardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { blue, red } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import clsx from 'clsx'
import ArrowTooltips from '../../../../../Utils/ArrowTooltips'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        margin: theme.spacing(0, 1),
        position: 'relative',
        width: 36,
        height: 36
    },
    buttonSuccess: {
        backgroundColor: blue[400],
        '&:hover': {
            backgroundColor: blue[700]
        }
    },
    fapButton: {
        width: 36,
        height: 36,
        backgroundColor: red[50],
        boxShadow: 'none',
        color: red[700],
        '&:hover': {
            backgroundColor: red[100]
        }
    },
    fabProgress: {
        color: red[100],
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1
    },
    buttonProgress: {
        color: blue[100],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}))

const DeleteButton = (props) => {
    const [open, setOpen] = React.useState(false)
    const [isDeleting, setisDeleting] = React.useState(false)
    const theme = useTheme()
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleClick = () => {
        setOpen(true)
        //onDelete(true, rowId)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleYes = () => {
        handleClose()
        handleDelete(true)
        props.deleteBatch(props.rowId, props.pageNo, handleDelete,props.queryParams)
    }

    const handleDelete = (params) => {
        setisDeleting(params)
    }
    

    const buttonClassname = clsx({
        [classes.fapButton]: true,
        [classes.buttonSuccess]: props.isDeleting && props.id === props.rowId
    })

    return (
        <>
            <ArrowTooltips
                title='Delete Batch'
                placement='right'
            >
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="delete batch"
                        className={buttonClassname}
                        //onClick={handleButtonClick}
                        id={'delete'+props.rowId}
                        color={props.color}
                        disabled={props.disabled}
                        onClick={handleClick}
                    >
                        <DeleteOutlineRoundedIcon fontSize="small" />
                    </Fab>
                    {isDeleting && (
                        <CircularProgress
                            size={38}
                            className={classes.fabProgress}
                        />
                    )}
                </div>
            </ArrowTooltips>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {'Delete Batch'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Batch?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleYes} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    pageNo: state.dashboardReducer.pageNo,
    id: state.dashboardReducer.id,
    queryParams: state.dashboardReducer.queryParams
})

export default connect(mapStateToProps, { deleteBatch })(DeleteButton)
