import React from 'react';
import PaginatedTable from '../../../Utils/reactTable/table';
import COLUMNS from './columns';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ActionBtns from '../notification-utils/action-buttons';

export class NotificationResults extends React.Component {
  constructor(props) {
    super(props);
    this.setPage.bind(this);
    this.setPerPage.bind(this);
  }
  state = {
    page: 1,
    perPage: 10,
  };

  setPage = (pg) => {
    this.setState({ page: pg });
  };

  setPerPage = (pgs) => {
    this.setState({ perPage: pgs });
  };

  componentDidMount() {
    this.props.getNotifications(
      this.state.page,
      this.state.perPage,
      this.props.filterOptions
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.filterOptions != this.props.filterOptions ||
      prevState.page != this.state.page
    ) {
      this.props.getNotifications(
        this.state.page,
        this.state.perPage,
        this.props.filterOptions
      );
    }
    if (prevProps.filterOptions != this.props.filterOptions) {
      this.setState({
        page: 1,
      });
    }
  }

  UpdatingNotifications = (notification_id) => {
    const updatedData = this.props.notificationData.filter((notification) => {
      return notification.id != notification_id;
    });
    if (updatedData.length == 0) {
      if (this.state.page > 1) {
        this.setState((prevState) => ({
          page: prevState.page - 1,
        }));
      } else {
        this.props.getNotifications(
          this.state.page,
          this.state.perPage,
          this.props.filterOptions
        );
      }
    } else {
      this.props.setNotifications({
        results: [...updatedData],
        count: this.props.count - 1,
      });
    }
  };

  performAction = async (notification, action) => {
    const res = await this.props.performAction(notification, action);
    if (res[1]) {
      switch (action) {
        case 'delete':
          this.UpdatingNotifications(res[0]);
          break;
        case 'restore':
          if (this.props.filterOptions.status == 'deleted') {
            this.UpdatingNotifications(res[0]);
          } else {
            let updatedNotifications = [...this.props.notifications];
            const index = updatedNotifications.findIndex(
              (obj) => obj.id == res[0]
            );
            updatedNotifications[index].deleted = false;
            updatedNotifications[index].unread == true
              ? (updatedNotifications[index].action_can_perform = [
                  'deleted',
                  'read',
                ])
              : (updatedNotifications[index].action_can_perform = [
                  'deleted',
                  'unread',
                ]);
            this.props.setNotifications({
              results: [...updatedNotifications],
              count: this.props.count,
            });
          }
          break;
        case 'read':
          if (this.props.filterOptions.status == 'unread') {
            this.UpdatingNotifications(res[0]);
          } else {
            let updatedNotifications = [...this.props.notificationData];
            const index = updatedNotifications.findIndex(
              (obj) => obj.id == res[0]
            );
            updatedNotifications[index].unread = false;
            updatedNotifications[index].action_can_perform = [
              'delete',
              'unread',
            ];
            this.props.setNotifications({
              results: [...updatedNotifications],
              count: this.props.count,
            });
          }
          break;
        case 'unread':
          if (this.props.filterOptions.status == 'read') {
            this.UpdatingNotifications(res[0]);
          } else {
            let updatedNotifications = [...this.props.notificationData];
            const index = updatedNotifications.findIndex(
              (obj) => obj.id == res[0]
            );
            updatedNotifications[index].unread = true;
            updatedNotifications[index].action_can_perform = ['delete', 'read'];
            this.props.setNotifications({
              results: [...updatedNotifications],
              count: this.props.count,
            });
          }
          break;
        default:
          break;
      }
      toast.success(res[2], {
        autoClose: 15000,
        hideProgressBar: true,
      });
    } else {
      toast.error(res[2], {
        autoClose: 15000,
        hideProgressBar: true,
      });
    }
  };

  render() {
    const notificationTableData = this.props.notificationData
      ? this.props.notificationData.map((notification) => {
          return {
            id: notification.id,
            notificationtype: notification.notification_type,
            notification: notification.verb,
            action_can_perform: notification.action_can_perform,
            active_status: notification.deleted ? 'Deleted' : 'Active',
            read_status: notification.unread ? 'Unread' : 'Read',
          };
        })
      : [];
    const data = notificationTableData;
    let columns = [...COLUMNS,{
      id: 'newactions',
      disableSortBy :true,
      Header: () => 'Actions',
      Cell: ({ row }) => {
        return (
        <ActionBtns
          actions={row.original['action_can_perform']}
          row={row}
          performAction={(notification, action) =>
            this.performAction(notification, action)
          }
        />
      )},
    } ]
    return (
      <>
        {data.length > 0 && (
          <PaginatedTable
          columns={columns}
            rowdata={data}
            currentpage={this.state.page}
           // setPage={this.setPage}  
            paginatorCallback={ async( { pageIndex, pageSize })=>{  
              
              await this.props.getNotifications(  pageIndex+1,pageSize,this.props.filterOptions ) 
          
          return   this.props.notificationData
            ? this.props.notificationData.map((notification) => {
                return {
                  id: notification.id,
                  notificationtype: notification.notification_type,
                  notification: notification.verb,
                  action_can_perform: notification.action_can_perform,
                  active_status: notification.deleted ? 'Deleted' : 'Active',
                  read_status: notification.unread ? 'Unread' : 'Read',
                };
              })
            : [];
          
          
             } }
            perPage={this.state.perPage}
            setPerPage={this.setPerPage}
            totalPage={Math.ceil(this.props?.count / this.state.perPage)}
            totalCount={this.props?.count}
            count={this.props?.count}
            showPagination={true}
            hidePageSelection={true}
            performAction={(notification, action) =>
              this.performAction(notification, action)
            }
          />
        )}
      </>
    );
  }
}

NotificationResults.propTypes = {
  getNotifications: PropTypes.func,
  notificationData: PropTypes.array,
  performAction: PropTypes.func,
  filterOptions: PropTypes.shape({
    notification_type: PropTypes.number,
    level: PropTypes.string,
  }),
  setNotifications: PropTypes.func,
  count: PropTypes.number,
};

export default NotificationResults;
