import React, { Component } from 'react';
import NRFDatePicker from './NRFDatePicker';
import './index.css';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.endDateInput = React.createRef();

    this.state = {
      isCalendarVisible: false,
      startDate: null,
      endDate: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        startDate: this.props.startDate || '',
        endDate: this.props.endDate || '',
      });
    }
    if (this.state.isCalendarVisible !== prevState.isCalendarVisible)
      if (this.state.isCalendarVisible) {
        var elem = document.querySelector('.dr-calendar-container');
        var bounding = elem.getBoundingClientRect();
        // If right side is out of viewport setting element right to zero
        if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
          elem.style.right = 0;
        }
      }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  clearDates = () => {
    this.setState({ startDate: '', endDate: '' }, () => {
      this.props.onDatesChange({
        startDate: null,
        endDate: null,
      });
    });
  };
  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * Close if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isCalendarVisible: false });
    }
  };

  onDateClick = (dt) => {
    if (!this.state.startDate) {
      this.setState(
        {
          startDate: dt,
          // isCalendarVisible: false //commented to stop calendar component from recalculating dispMos state, so that displayed months won't update on date click
        },
        () => {
          this.props.onDatesChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
          this.endDateInput.current.focus();
        }
      );
    } else {
      if (dt.isBefore(this.state.startDate)) {
        this.setState(
          {
            startDate: dt,
            // isCalendarVisible: false //commented to stop calendar component from recalculating dispMos state, so that displayed months won't update on date click
          },
          () => {
            this.props.onDatesChange({
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            });
            this.endDateInput.current.focus();
          }
        );
      } else {
        this.setState({ endDate: dt, isCalendarVisible: false }, () => {
          this.props.onDatesChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        });
      }
    }
  };
  render() {
    return (
      <div ref={this.setWrapperRef}>
        <div className="DateRangePicker DateRangePicker_1">
          <div
            className={`dp-input-container DateRangePickerInput DateRangePickerInput_1 DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput__showClearDates DateRangePickerInput__showClearDates_3 ${
              this.props.disabled && 'DateRangePickerInput__disabled'
            }`}>
            <div className="DateInput DateInput_1">
              <input
                aria-label="Start Date"
                type="text"
                // disabled={this.props.disabled}
                disabled={this.props.disableStartDate}
                id={this.props.startDateId}
                name={this.props.startDateId}
                autoComplete="off"
                aria-describedby="DateInput__screen-reader-message-your_unique_start_date_id1122"
                className={`DateInput_input DateInput_input_1 DateInput_input__readOnly DateInput_input__readOnly_2 DateInput_input__focused_3 ${
                  this.props.disableStartDate && 'DateInput_input__disabled'
                }`}
                placeholder="Start Date"
                value={this.state.startDate && this.state.startDate.format('MM/DD/YYYY')}
                onFocus={() => {
                  this.setState({
                    isCalendarVisible: true,
                  });
                }}></input>
            </div>
            <div
              className="DateRangePickerInput_arrow DateRangePickerInput_arrow_1"
              aria-hidden="true"
              role="presentation">
              <svg
                className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1"
                focusable="false"
                viewBox="0 0 1000 1000">
                <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path>
              </svg>
            </div>
            <div className="DateInput DateInput_1">
              <input
                aria-label="End Date"
                type="text"
                disabled={this.props.disabled}
                id={this.props.endDateId}
                name={this.props.endDateId}
                ref={this.endDateInput}
                placeholder="End Date"
                autoComplete="off"
                readOnly
                aria-describedby="DateInput__screen-reader-message-your_unique_end_date_id1122"
                value={this.state.endDate && this.state.endDate.format('MM/DD/YYYY')}
                className={`DateInput_input DateInput_input_1 DateInput_input__readOnly DateInput_input__readOnly_2 DateInput_input__focused_3 ${
                  this.props.disabled && 'DateInput_input__disabled'
                }`}
                onFocus={() => {
                  this.setState({
                    isCalendarVisible: true,
                  });
                }}></input>
            </div>
            {!this.props.disabled && (this.state.startDate || this.state.endDate) && (
              <button
                onClick={this.clearDates}
                disabled={this.props.disabled}
                type="button"
                aria-label="Clear Dates"
                className="DateRangePickerInput_clearDates DateRangePickerInput_clearDates_1 DateRangePickerInput_clearDates_default DateRangePickerInput_clearDates_default_2">
                <svg
                  className="DateRangePickerInput_clearDates_svg DateRangePickerInput_clearDates_svg_1"
                  focusable="false"
                  viewBox="0 0 12 12">
                  <path
                    fillRule="evenodd"
                    d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"></path>
                </svg>
              </button>
            )}
          </div>
          {this.state.isCalendarVisible && (
            <div
              className="dr-calendar-container"
              onBlur={() => {
                this.setState({
                  isCalendarVisible: false,
                });
              }}>
              <NRFDatePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onClick={this.onDateClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
