import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Icon from "@material-ui/core/Icon";

import AppBar from "@material-ui/core/AppBar";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "0px 15px 0px 15px",
    background: "#f9f9f9",
    border: "none",
    borderRadius: "0",
    //margin-bottom: 10px;
    position: "sticky",

    boxShadow: "none",
    right: 0,
    zIndex: 800,
    top: 0,
    height: "var(--header-height)",
  },
}));
function Header(props) {
  const userName = localStorage.getItem("name")
    ? localStorage.getItem("name").split("@")[0]
    : "User";
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.header}>
      {/*  <nav className="navbar navbar-expand-sm navbar-light bg-light"> */}
      <div className="navbar-brand">
        <span>{props.title}</span>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarMenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <ul className="navbar-nav">
          <li title="Logout" className="nav-item">
            <Link to="/">
              <Icon
                className="fa fa-power-off"
                style={{ fontSize: "1rem", color: "grey" }}
                onClick={() => props.logoutUser()}
              />
            </Link>
          </li>
        </ul>
      </div>
    </AppBar>
  );
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  logoutUser,
})(withRouter(Header));
