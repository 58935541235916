import React, { useState, useEffect, useReducer } from "react";
import produce from "immer";
import styled from "styled-components";
import { useParams } from "react-router";
import { useHistory, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  AS_EXPLORE_CATEGORY,
  AS_SINGLE_EDIT_SAVE,
  AS_EXPLORE_VARIANT_CATEGORY
} from "../../../../../config/api";
import { Container } from "../../../../../commonComponents/viewUtils";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import ShowToast from "../../../../../commonComponents/toast";
import axiosInstance from "../../../../../Utils/axios";
import HeaderBreadCrumbs from "../../../../../Utils/HeaderBreadCrumbs";
import HomeIcon from "@material-ui/icons/Home";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Typography } from "@material-ui/core";
import { fetchPayload } from "../components/helper";
import ProductDetailNavigation from "./ProductDetailNavigation";
import EndOfProducts from "./EndOfProducts";
import DetailImages from "./DetailImages";
import DetailAttributes from "./DetailAttributes";
import VariantDetail from "./VariantDetail";
import ProductDetailActions from "./ProductDetailActions";
import cloneDeep from "lodash/cloneDeep";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

//Copy to Clipboard utility
const handleCopyToClipboard = () => {
  let copyText = document.getElementById("copy-to-clipboard");
  let textArea = document.createElement("textarea");
  textArea.value = copyText.textContent;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("Copy");
  textArea.remove();
  let tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copied!";
};

const pushEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) =>
      ele.product_attribute_mapping_id ===
      action.payload.product_attribute_mapping_id
  );
  duplicateIndex === -1
    ? arr.push(action.payload)
    : arr.splice(duplicateIndex, 1, action.payload);
  return arr;
};

const deleteEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) =>
      ele.product_attribute_mapping_id ===
      action.payload.product_attribute_mapping_id
  );
  if (duplicateIndex !== -1) {
    arr.splice(duplicateIndex, 1);
  }
  return arr;
};

const reducer = produce((state, action) => {
  switch (action.type) {
    case "INIT":
      state.data = [];
      state.fallbackScreen.show = false;
      state.fallbackScreen.message = "";
      break;
    case "SAVE_RESPONSE":
      state.data = action.payload;
      break;
    case "START_LOADING":
      state.loading = true;
      break;
    case "STOP_LOADING":
      state.loading = false;
      break;
    case "SHOW_FALLBACK":
      state.fallbackScreen.show = action.payload.show;
      state.fallbackScreen.message = action.payload.message;
      break;
    case "ADD_ATTRIBUTE":
      state.changesArray = pushEle(state.changesArray, action);
      state.reset = false;
      break;
    case "DELETE_ATTRIBUTE":
      state.changesArray = deleteEle(state.changesArray, action);
      state.reset = false;
      break;
    case "RESET_ATTRIBUTE_CHANGES":
      state.changesArray = [];
      state.reset = true;
      break;
    case "RESET_ATTRIBUTE_CHANGES_ON_SAVE":
      state.changesArray = [];
      break;
    case "NAVIGATE":
      state.navigate = true;
      break;
    case "POST_URL_VARIANT":
      state.postURL = AS_EXPLORE_VARIANT_CATEGORY;
      break;
    default:
      break;
  }
});

const ProductDetail = (props) => {
  const [
    { data, changesArray, reset, loading, fallbackScreen, navigate, postURL },
    dispatch,
  ] = useReducer(reducer, {
    data: [],
    changesArray: [],
    reset: false,
    loading: false,
    fallbackScreen: { show: false, message: "" },
    navigate: false,
    postURL: AS_EXPLORE_CATEGORY
  });
  const { batch, l1, l2, productId } = useParams();
  const history = useHistory();

  const [InitialProductId] = useState(history.location.state);
  // Fetch Product Data API Call
  const fetchData = async (didCancel, productId) => {
    dispatch({ type: "INIT" });
    dispatch({ type: "START_LOADING" });
    try {
      let payload = fetchPayload();
      payload.batch_id = batch;
      payload.l1 = decodeURIComponent(l1);
      payload.l2 = decodeURIComponent(l2);
      payload.view = props.view;
      payload.product_id = `${productId}`;
      delete payload.page_number;
      delete payload.page_size;
      //AS-526 - need tenant_attribute_id
      if(payload.tenant_attribute_id===undefined) delete payload.tenant_attribute_id;
      if(payload.is_variant) dispatch({ type: "POST_URL_VARIANT"});
      if(navigate) delete payload.is_variant;
      const result = await axiosInstance.post(payload.is_variant?AS_EXPLORE_VARIANT_CATEGORY:postURL, payload);
      if (postURL===AS_EXPLORE_VARIANT_CATEGORY || payload.is_variant) {
        if (result.data.data?.count && fallbackScreen.show) {
          dispatch({ type: "SHOW_FALLBACK", payload: { show: false } });
        }
        if (!result.data.data?.count) {
          dispatch({ type: "SHOW_FALLBACK", payload: { show: true } });
        }
        dispatch({ type: "SAVE_RESPONSE", payload: result.data.data});
      } else {
        if (result.data.data.data?.length > 0 && fallbackScreen.show) {
          dispatch({ type: "SHOW_FALLBACK", payload: { show: false } });
        }
        if (result.data.data.data?.length === 0) {
          dispatch({ type: "SHOW_FALLBACK", payload: { show: true } });
        }
        dispatch({ type: "SAVE_RESPONSE", payload: result.data.data.data[0] });
      }
      dispatch({ type: "STOP_LOADING" });
    } catch (error) {
      console.log(postURL,error);
      if (!didCancel) {
        dispatch({ type: "STOP_LOADING" });
        if (error?.response?.data?.data?.message) {
          ShowToast(error.response.data.data.message, "error");
        } else {
          ShowToast("Something Went Wrong", "error");
        }
        dispatch({ type: "SHOW_FALLBACK", payload: { show: true } });
      }
    }
    //AS-494 Fix
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  //Run Fetch Data everytime URL changes to incorporate navigation
  useEffect(() => {
    let didCancel = false;
    fetchData(didCancel, productId);
    return () => {
      didCancel = true;
    };
  }, [window.location.pathname]);

  //Attribute Value Selection Handler for feeding values in Reducer
  const onChangeSelectAttribute = (val, productDetails, operation) => {
    switch (operation) {
      case "reset":
        dispatch({
          type: "RESET_ATTRIBUTE_CHANGES",
        });
        break;
      case "delete":
        dispatch({
          type: "DELETE_ATTRIBUTE",
          payload: {
            product_attribute_mapping_id:
              productDetails.product_attribute_mapping_id,
          },
        });
        break;
      case "add":
        dispatch({
          type: "ADD_ATTRIBUTE",
          payload: {
            edited: true,
            new_value: val.value,
            tenant_attribute_id: val.tenant_attribute_id,
            product_id: data.id,
            product_attribute_mapping_id:
              productDetails.product_attribute_mapping_id,
            tenant_attribute_value: productDetails.label,
          },
        });
        break;
      default:
        return;
    }
  };

  //Attribute Value Selection Handler for feeding values in Reducer for Variants
  const onChangeSelectVariantAttribute = (val, productDetails, operation) => {
    switch (operation) {
      case "reset":
        dispatch({
          type: "RESET_ATTRIBUTE_CHANGES",
        });
        break;
      case "delete":
        dispatch({
          type: "DELETE_ATTRIBUTE",
          payload: {
            product_attribute_mapping_id:
              productDetails.variant_attribute_mapping_id,
          },
        });
        break;
      case "add":
        dispatch({
          type: "ADD_ATTRIBUTE",
          payload: {
            is_variant: true,
            edited: true,
            new_value: val.value,
            tenant_attribute_id: val.tenant_attribute_id,
            product_id: productDetails.product_id,
            product_attribute_mapping_id:
              productDetails.variant_attribute_mapping_id,
            tenant_attribute_value: productDetails.label,
          },
        });
        break;
      default:
        return;
    }
  };

  /* For Forcing user to select a subCategory if Category has been changed, 
    prevents calling API, focuses back on SubCategory dropdown.
  */
  let subCategoryRef = React.useRef(null);
  const subCategoryFocus = () => subCategoryRef.current.focus();

  //Construct the Payload and Save the Edited Product Detail Values, API Call
  const saveEdits = async () => {
    dispatch({ type: "START_LOADING" });
    let requestPayload = {};
    requestPayload.batch_id = batch;
    requestPayload.update_type = "review_update";
    let updateData = cloneDeep(changesArray);
    let allData = data.attributes.map((item) => ({
      product_attribute_mapping_id: item.product_attribute_mapping_id,
      product_id: data.id,
      tenant_attribute_id: item.values.find((arr) => arr.selected)
        .tenant_attribute_id,
      tenant_attribute_value: item.label,
      new_value: null,
      edited: false,
    }));
    data.variant.map((item) => {
      item.attributes.map((o) =>
        allData.push({
          product_attribute_mapping_id: o.variant_attribute_mapping_id,
          product_id: item.id,
          tenant_attribute_id: o.values.find((arr) => arr.selected)
            .tenant_attribute_id,
          tenant_attribute_value: o.label,
          new_value: null,
          edited: false,
          is_variant: true,
        })
      );
    });
    requestPayload.data = allData.map(
      (obj) =>
        updateData.find(
          (o) =>
            o.product_attribute_mapping_id === obj.product_attribute_mapping_id
        ) || obj
    );
    let findCat = updateData.find(
      (i) => i.tenant_attribute_value === "Category"
    );
    let findSubCat = updateData.find(
      (i) => i.tenant_attribute_value === "Sub Category"
    );
    if (findCat) {
      requestPayload.data.push(findCat);
      if (!findSubCat) {
        subCategoryFocus();
        return 0;
      }
    }
    if (findSubCat) {
      requestPayload.data.push(findSubCat);
    }
    //Generate Variant Payload
    let variantRequestPayload = cloneDeep(requestPayload);
    variantRequestPayload.data = requestPayload.data.filter(
      (o) => o.is_variant
    );
    variantRequestPayload.is_variant = true;
    requestPayload.data = requestPayload.data.filter((o) => !o.is_variant);
    //Make separate requests for product and variant
    let request1 = axiosInstance.post(AS_SINGLE_EDIT_SAVE, requestPayload);
    let request2 = axiosInstance.post(
      AS_SINGLE_EDIT_SAVE,
      variantRequestPayload
    );
    //API call
    try {
      await axios.all([request1, request2]).then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          if (
            responseOne.data.status &&
            responseOne.data.status_code === 200 &&
            responseTwo.data.status &&
            responseTwo.data.status_code === 200
          ) {
            dispatch({ type: "RESET_ATTRIBUTE_CHANGES_ON_SAVE" });
            ShowToast(
              <p>
                Changes saved successfully<p></p>
              </p>,
              "success",
              <div style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon size={18} style={{ marginRight: "5px" }} />
                {`  `} Success
              </div>,
              "bottom-right",
              7000
            );
            if (!data.next_obj_id) {
              dispatch({ type: "SHOW_FALLBACK", payload: { show: true } });
            } else {
              dispatch({type:'NAVIGATE'});
              let pathArr = history.location.pathname.split("/");
              pathArr.splice(-1, 1, data.next_obj_id);
              history.push(pathArr.join("/") + history.location.search);
            }
          }
        })
      );
      dispatch({ type: "STOP_LOADING" });
    } catch (e) {
      dispatch({ type: "STOP_LOADING" });
      ShowToast(
        <p>Failed to save changes. Try again</p>,
        "error",
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReportProblemIcon size={18} style={{ marginRight: "5px" }} />
          {`  `} Error
        </div>,
        "bottom-right",
        7000
      );
    }
  };

  //Delete Variant From Page, API Call
  const deleteVariant = async (resetMode, id) => {
    dispatch({ type: "START_LOADING" });
    if (resetMode) {
      dispatch({
        type: "RESET_ATTRIBUTE_CHANGES",
      });
    }
    try {
      let result = await axiosInstance.delete(
        `${AS_SINGLE_EDIT_SAVE}?batch_id=${batch}&product_id=${id}&is_variant=true`
      );
      if (result.data.status && result.data.status_code === 200) {
        dispatch({ type: "RESET_ATTRIBUTE_CHANGES_ON_SAVE" });
        ShowToast(
          <p>
            Changes saved successfully<p></p>
          </p>,
          "success",
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon size={18} style={{ marginRight: "5px" }} />
            {`  `} Success
          </div>,
          "bottom-right",
          7000
        );
      }
    } catch (error) {
      ShowToast(
        <p>Failed to save changes. Try again</p>,
        "error",
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReportProblemIcon size={18} style={{ marginRight: "5px" }} />
          {`  `} Error
        </div>,
        "bottom-right",
        7000
      );
    }
    dispatch({ type: "STOP_LOADING" });
    dispatch({
      type: "SHOW_FALLBACK",
      payload: { show: true, message: "Product has been deleted..." },
    });
  };
  //Handle URL alterations for preserving query params while going back
  const handleGoBackPath = () => {
    history.push({
      pathname: `/attributesmart/dashboard/${batch}/${l1}/${l2}/view`,
      search: history.location.search,
      state: InitialProductId,
    });
  };

  //Header Options for Breadcrumbs and related functionality
  const headerOptions = {
    headerTitle: (
      <HeaderBreadCrumbs
        options={[
          {
            label: "Dashboard",
            id: "dashboard_bc",
            icon: <HomeIcon fontSize="small" />,
            action: () => history.push(`/attributesmart/dashboard/`),
          },
          {
            label: `Explore`,
            id: "explore_bc",
            action: () => history.push(`/attributesmart/dashboard/${batch}`),
          },
          {
            label: decodeURIComponent(l2),
            id: "category_bc",
            action: handleGoBackPath,
          },
          {
            label: data?.name && (
              <CopyText
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div id="copy-to-clipboard" style={{ marginRight: "5px" }}>
                  {data?.name}
                </div>
                <FileCopyIcon style={{ width: "16px", height: "16px" }} />
                <span className="tooltiptext" id="myTooltip">
                  Copy to clipboard
                </span>
              </CopyText>
            ),
            id: "product_bc",
            action: () => handleCopyToClipboard(),
          },
        ]}
      />
    ),
  };

  return (
    <Container headerOptions={headerOptions} id="detail">
      <Prompt when={!!changesArray.length} message="" />
      <LoadingOverlay active={loading} />
      <ProductDetailNavigation
        current_index={data?.current_index}
        count={data?.count}
        prev={data?.prev_obj_id}
        next={data?.next_obj_id}
        history={history}
        first={data?.first_obj_id}
        last={data?.last_obj_id}
        disableCount={fallbackScreen.show}
        navigateToProduct={()=>dispatch({type:'NAVIGATE'})}
      />
      {fallbackScreen.show ? (
        <Row>
          <EndOfProducts
            handlePath={handleGoBackPath}
            history={history}
            message={fallbackScreen.message}
          />
        </Row>
      ) : (
        <Row>
          {data?.length === 0 && (
            <Typography variant="caption" style={{ margin: "10px" }}>
              Loading Data ...
            </Typography>
          )}
          <ImageColumn>
            {data?.images?.length > 0 && (
              <DetailImages images={data?.images}></DetailImages>
            )}
          </ImageColumn>
          <AttributeColumn>
            {data?.attributes?.length > 0 && (
              <>
                <DetailAttributes
                  key={productId}
                  attributes={data.attributes}
                  L1L2Categories={data.L1_L2_categories}
                  confidence_score_percentage={data.confidence_score_percentage}
                  productId={data.id}
                  reset={reset}
                  loading={loading}
                  subCategoryRef={subCategoryRef}
                  subCategoryFocus={subCategoryFocus}
                  onChangeSelectAttribute={onChangeSelectAttribute}
                ></DetailAttributes>
              </>
            )}
          </AttributeColumn>
        </Row>
      )}
      {data?.variant?.length > 0 && !fallbackScreen.show && (
        <VariantDetail
          data={data.variant}
          reset={reset}
          onChangeSelectAttribute={onChangeSelectVariantAttribute}
          deleteVariant={deleteVariant}
          changesArray={changesArray.length !== 0}
        />
      )}
      {data?.attributes?.length > 0 && !fallbackScreen.show && (
        <ProductDetailActions
          saveEdits={saveEdits}
          handlePath={handleGoBackPath}
          onChangeSelectAttribute={onChangeSelectAttribute}
          loading={loading}
        />
      )}
    </Container>
  );
};

ProductDetail.propTypes = {};

const mapStateToProps = (state) => ({
  view: state.attributeSmartReducer.view,
});

export default connect(mapStateToProps, {})(ProductDetail);

const Row = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;
  background-color: white;
  border: 1px solid lightgray;
  margin: 0 10px 0 10px;
  border-radius: 4px;
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
`;

const AttributeColumn = styled.div`
  flex: 3 1 auto;
  display: flex;
  flex-flow: column;
  width: ${(props) => props.width};
`;

const ImageColumn = styled.div`
  min-width: 680px;
  max-width: 680px;
  flex: 2 1 auto;
  position: sticky;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  top: 150px;
  bottom: 0;
  z-index: 2;
  align-self: flex-start;
`;

const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer;
`;

const CopyText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 0.7;
  }
`;
