//We need to integrate Select dropdown, and Notification Table;
//We need to have three components => One is for Status dropdown; Two is for Notification Type;  Three is for results Table
import React from 'react'
import NotificationPanelContainer from './notificationPanel'
import { connect } from 'react-redux'
import {
  getNotifications,
  getNotificationTypes,
  onFilter,
  getUserNotificationTypes,
  setUserNotificationTypes,
  onNotificationAction,
  setNotifications,
} from '../../actions/notificationActions'

const NotificationPanelComponent = (props) => {
  return (
    <>
      <NotificationPanelContainer
        getNotifications={props.getNotifications}
        notificationData={props.notificationData}
        getNotificationTypes={props.getNotificationTypes}
        performAction={props.performAction}
        onFilter={props.onFilter}
        getUserNotificationTypes={props.getUserNotificationTypes}
        userNotificationTypes={props.userNotificationTypes}
        setUserNotificationTypes={props.setUserNotificationTypes}
        setNotifications={props.setNotifications}
        count={props.notificationCount}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notificationReducer.notificationData,
    userNotificationTypes: state.notificationReducer.userNotificationTypes,
    notificationData: state.notificationReducer.notificationData,
    notificationCount: state.notificationReducer.notificationCount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (pageIndex, pageSize, filterOptions) =>
      dispatch(getNotifications(pageIndex, pageSize, filterOptions)),
    performAction: (notification, action) =>
      dispatch(onNotificationAction(notification, action)),
    getNotificationTypes: () => dispatch(getNotificationTypes()),
    setNotifications: (notifications) =>
      dispatch(setNotifications(notifications)),
    getUserNotificationTypes: () => dispatch(getUserNotificationTypes()),
    setUserNotificationTypes: (payload) =>
      dispatch(setUserNotificationTypes(payload)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPanelComponent)
