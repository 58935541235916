import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Select from "../utils/select";
import Table from "../../../Utils/reactTable/table";
import COLUMNS from "../utils/permissionTabelColumns";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import _ from "lodash";
import {
  ToggleLoader,
  updateGrpPermissions,
  setGroups,
} from "../../../actions/adminActions";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  grpTableContainer: {
    marginTop: 5,
  },
});

const EditGroup = (props) => {
  const classes = useStyles();
  const [selectedGroup, setGroupChange] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissions, setPermissions] = useState(props.permissions);
  const [selectedPermissionObjects, setSelectedPermissionObjects] = useState(
    []
  );
  const getGroupOptions = () => {
    return props.groups.map((group) => {
      return { label: group.name, value: group.id };
    });
  };

  const selectedPermissionHandler = (selectedPermissions) => {
    setSelectedPermissionObjects(selectedPermissions);
  };

  const onUpdate = async () => {
    props.toggleLoader(true);
    const grpId = selectedGroup.value;
    const grpName = selectedGroup.label;
    const permissionArr = selectedPermissionObjects.map((permission) => {
      return permission.original.id;
    });
    const requestData = {
      id: grpId,
      name: grpName,
      permissions: [...permissionArr],
    };
    try {
      const response = await props.updateGrpPermissions(requestData);
      const updatedGroups = props.groups.map((group) => {
        if (group.id == response.data.data.id) {
          return {
            id: response.data.data.id,
            name: response.data.data.name,
            permissions: response.data.data.permissions,
          };
        }
        return group;
      });
      props.setGroups(updatedGroups);
      props.toggleLoader(false);
      toast.success("Group Permissions mapped successfully", {
        autoClose: 15000,
        hideProgressBar: true,
      });
    } catch (error) {
      props.toggleLoader(false);
      toast.error("Group Permissions Mapping Failed", {
        autoClose: 15000,
        hideProgressBar: true,
      });
    }
  };

  const getTablePermissions = (group) => {
    let grpObject = props.groups.filter((grp) => {
      return grp.id == group.value;
    })[0];
    const PermissionSpltArr = _.partition(props.permissions, (grp) => {
      return _.includes(grpObject.permissions, grp.id);
    });
    const selectedIds = PermissionSpltArr[0].map((selectedPermission, idx) => {
      const idx_str = idx + "";
      return { idx_str: true };
    });
    setPermissions([...PermissionSpltArr[0], ...PermissionSpltArr[1]]);
    setSelectedPermissions(selectedIds);
  };
  useEffect(() => {
    if (selectedGroup && props.groups) {
      getTablePermissions(selectedGroup);
    }
  }, [selectedGroup, props.groups]);

  return (
    <>
      <Grid container spacing={5} className={classes.grpTableContainer}>
        <Grid item xs={12} sm={3}>
          <label>Edit Group</label>
          <Select
            options={getGroupOptions()}
            isMulti={false}
            value={selectedGroup}
            onChange={(option) => setGroupChange(option)}
          />
        </Grid>

        {selectedGroup && (
          <Grid item xs={12} sm={7}>
            <div id="admnDivPerm" className="permission-table">
              <Table
                rowdata={permissions ? permissions : props.permissions}
                columns={COLUMNS}
                selectedRows={selectedPermissions}
                rowSelection={true}
                showPagination={true}
                selectedRowHandler={selectedPermissionHandler}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {selectedGroup && (
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          className="grp-perm-btn-grp"
        >
          <Button
            className="grp-perm-btn"
            variant="contained"
            color="primary"
            onClick={onUpdate}
          >
            Update
          </Button>
          <Button
            className="grp-perm-btn"
            variant="contained"
            color="primary"
            onClick={() => {
              setGroupChange("");
              setSelectedPermissions([]);
            }}
          >
            Reset
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: state.adminReducer.groups,
    permissions: state.adminReducer.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (loadStatus) => dispatch(ToggleLoader(loadStatus)),
    updateGrpPermissions: (reqData) => dispatch(updateGrpPermissions(reqData)),
    setGroups: (grpData) => dispatch(setGroups(grpData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
