import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getgroups,
  ToggleLoader,
  getpermissions,
  setGroups,
  setPermissions,
  setPermissionDataFetchStatus,
  setGrpDataFetchStatus,
} from "../../../actions/adminActions";
import EditGroup from "./editGroup";
import CreateGroup from "./createGroup";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "./grpPermissionMapper.scss";
import Grid from "@material-ui/core/Grid";
const GrpPermissionMapper = (props) => {
  const [errorMsg, seterrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        props.toggleLoader(true);
        if (!props.isGrpDataFetched) {
          const groupsData = await props.getGroups();
          props.setGroups(groupsData.data.data);
          props.setGroupDataFetchStatus();
        }
        if (!props.isPermissionDataFetched) {
          const permissionsData = await props.getPermissions();
          props.setPermissions(permissionsData.data.data);
          props.setPermissionDataFetchStatus();
        }
        props.toggleLoader(false);
        setShowComponent(true);
      } catch (error) {
        setShowComponent(false);
        seterrorMsg("Something went wrong!");
        setOpen(true);
        props.toggleLoader(false);
      }
    };
    fetchData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }
    setOpen(false);
    seterrorMsg("");
  };
  return (
    <>
      {showComponent ? (
        <div className="grp-container">
          <div>
            <CreateGroup />
          </div>
          <div>
            <EditGroup />
          </div>
        </div>
      ) : (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "50vh" }}
          >
            Can't Fetch Data
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
          >
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    overlayLoaderState: state.loaderReducer.overlayLoaderState,
    isGrpDataFetched: state.adminReducer.isGrpDataFetched,
    isPermissionDataFetched: state.adminReducer.isPermissionDataFetched,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (loadStatus) => dispatch(ToggleLoader(loadStatus)),
    getGroups: () => dispatch(getgroups()),
    getPermissions: () => dispatch(getpermissions()),
    setGroups: (groups) => dispatch(setGroups(groups)),
    setPermissions: (permissions) => dispatch(setPermissions(permissions)),
    setGroupDataFetchStatus: () => dispatch(setGrpDataFetchStatus()),
    setPermissionDataFetchStatus: () =>
      dispatch(setPermissionDataFetchStatus()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrpPermissionMapper);
