import React, { Component } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import posthog from 'posthog-js';
import { POSTHOG_API,POSTHOG_PROD_API } from './config/api';
import { POSTHOG_KEY } from './config/constants';
import Login from './commonComponents/auth';
import store from './store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'font-awesome/css/font-awesome.min.css';
import Layout from './commonComponents/layout';
//Authentication Testing - Firebase Connectivity
import { getTokenId } from '../src/actions/authActions';
import 'firebaseui/dist/firebaseui.css';
import Home from './commonComponents/home';
import AttributeSmart from './modules/attribute-smart';
import NotFound from './commonComponents/notFound/NotFound';
import { verifyTokenStatus } from './actions/authActions';
import ConfirmPrompt from './commonComponents/confirmPrompt';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import EmailLogin from './commonComponents/auth/emaillogin';
import TokenListener from './hoc/listener';

class App extends Component {
  constructor() {
    super();
    
    posthog.init(POSTHOG_KEY, { api_host: POSTHOG_API });
    this.state = {
      app: '',
      confirm: false,
      confirmCallback: null,
    };
  }
  selectApp = (app) => {
    this.setState({ app });
  };

  // setConfirm, getConfirmation and ConfirmPrompt are used to show confirm prompt
  setConfirm = () => {
    this.setState({ confirm: false });
  };
  getConfirmation = (_, confirmCallback) => {
    this.setState({ confirm: true, confirmCallback });
  };

  componentDidMount() {
    let loc = new URL(window.location.origin);
    store.dispatch(getTokenId(loc.hostname));
    //Checking the status of the token before allowing the user to access the routes
    store.dispatch(verifyTokenStatus());
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <Router getUserConfirmation={this.getConfirmation}>
            {this.state.confirm && (
              <ConfirmPrompt
                confirmCallback={this.state.confirmCallback}
                setConfirm={this.setConfirm}
              />
            )}
            <TokenListener>
              <div className="App">
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/emaillogin" component={EmailLogin} />
                  <Redirect exact from="/" to="/login" />
                  <Route exact path={`/home`} component={Home} />
                  <Route path="/marksmart" component={Layout} />
                  <Route path="/attributesmart" component={AttributeSmart} />
                  <Route exact path="/*" component={NotFound} />
                </Switch>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
              </div>
            </TokenListener>
          </Router>
        </Provider>
      </>
    );
  }
}

export default App;
