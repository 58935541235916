import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import States from "./states"
import Transitions from './transitions';
import WorkflowTab  from './workflowtab';
import TransitionApprovals from './transitionapprovals';
import {getStates, getTranstions, getWorkflows, getTranstionApprovals} from "../../actions/workflowActions"
import { getgroups, setGroups, setGrpDataFetchStatus } from "../../actions/adminActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '$primary-color',
    margin: '3%'
  },
}));

 function Workflow(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showTab, setShowTab] = useState(false)

  useEffect( async() => {
 
    await props.getTranstions();
    await props.getStates();
    await props.getWorkflows();
    await props.getTranstionApprovals();
    if (!props.isGrpDataFetched) {
      const groupsData = await props.getgroups();
      await props.setGroups(groupsData.data.data);
      await props.setGrpDataFetchStatus();
    } 
    setShowTab(true)
  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
    {showTab && <> <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="States" {...a11yProps(0)} />
          <Tab label="Workflows" {...a11yProps(1)} />
          <Tab label="Transitions" {...a11yProps(2)} />
          <Tab label="Transition Approvals" {...a11yProps(3)} />
        </Tabs>
      <TabPanel value={value} index={0}>
        <States></States>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WorkflowTab> </WorkflowTab>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Transitions> </Transitions>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TransitionApprovals> </TransitionApprovals>
      </TabPanel>
      </>}
    </div>
  );
}



const mapStateToProps = (state) =>  {
  return  ({
  sidebarMenu: state.layoutReducer.sidebarMenu,
  states :state.workflowReducer.states,
  isGrpDataFetched : state.adminReducer.isGrpDataFetched
})};

const mapActionsToProps = {
  getStates,
  getTranstions,
  getWorkflows,
  getTranstionApprovals,
  getgroups,
  setGroups,
  setGrpDataFetchStatus,
};
export default connect(mapStateToProps, mapActionsToProps)(Workflow)