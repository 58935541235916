import produce from "immer";

const pushGridEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) => ele.product_id === action.payload.product_id
  );
  duplicateIndex === -1
    ? arr.push(action.payload)
    : arr.splice(duplicateIndex, 1, action.payload);
  return arr;
};

const deleteGridEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) => ele.product_id === action.payload.product_id
  );
  if (duplicateIndex !== -1) {
    arr.splice(duplicateIndex, 1);
  }
  return arr;
};

const pushListEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) =>
      ele.product_id === action.payload.product_id &&
      ele.tenant_attribute_id === action.payload.tenant_attribute_id
  );
  duplicateIndex === -1
    ? arr.push(action.payload)
    : arr.splice(duplicateIndex, 1, action.payload);
  return arr;
};

const deleteListEle = (arr, action) => {
  let duplicateIndex = arr.findIndex(
    (ele) =>
      ele.product_id === action.payload.product_id &&
      ele.tenant_attribute_id === action.payload.tenant_attribute_id
  );
  if (duplicateIndex !== -1) {
    arr.splice(duplicateIndex, 1);
  }
  return arr;
};

const initialState = {
  changesArray: [],
  dropdownStatus: false,
};

const exploreCategoryReducer = produce((state = initialState, action = {}) => {
  switch (action.type) {
    case "ADD_GRID_ATTRIBUTE":
      state.changesArray = pushGridEle(state.changesArray, action);
      state.dropdownStatus = false;
      break;
    case "DELETE_GRID_ATTRIBUTE":
      state.changesArray = deleteGridEle(state.changesArray, action);
      state.dropdownStatus = false;
      break;
    case "ADD_LIST_ATTRIBUTE":
      state.changesArray = pushListEle(state.changesArray, action);
      state.dropdownStatus = false;
      break;
    case "DELETE_LIST_ATTRIBUTE":
      state.changesArray = deleteListEle(state.changesArray, action);
      state.dropdownStatus = false;
      break;
    case "RESET_ATTRIBUTE_CHANGES":
      state.changesArray = [];
      state.dropdownStatus = true;
      break;
    case "RESET_ATTRIBUTE_REDUCER":
      state.changesArray = [];
      state.dropdownStatus = false;
      break;
    default:
      return state;
  }
});

export default exploreCategoryReducer;

export const resetChangesArray = () => (dispatch) => {
  dispatch({ type: "RESET_ATTRIBUTE_CHANGES" });
};
export const resetReducer = () => (dispatch) => {
  dispatch({ type: "RESET_ATTRIBUTE_REDUCER" });
};

export const onChangeListSelectAttribute = (params, action) => (dispatch) => {
  switch (action) {
    case "delete":
      dispatch({
        type: "DELETE_LIST_ATTRIBUTE",
        payload: {
          product_id: params.product_id,
          tenant_attribute_id: params.tenant_attribute_id,
        },
      });
      break;
    case "add":
      dispatch({
        type: "ADD_LIST_ATTRIBUTE",
        payload: params,
      });
      break;
    default:
      return;
  }
};
export const onChangeGridSelectAttribute = (val, productDetails, operation) => (
  dispatch
) => {
  switch (operation) {
    case "delete":
      dispatch({
        type: "DELETE_GRID_ATTRIBUTE",
        payload: {
          product_id: productDetails.product_id,
        },
      });
      break;
    case "add":
      dispatch({
        type: "ADD_GRID_ATTRIBUTE",
        payload: {
          label: val.label,
          edited: true,
          value: val.value,
          new_value: val.value,
          tenant_attribute_id: val.tenant_attribute_id,
          product_id: productDetails.product_id,
          product_attribute_mapping_id:
            productDetails.product_attribute_mapping_id,
          tenant_attribute_value: productDetails.tenant_attribute_value,
        },
      });
      break;
    default:
      return;
  }
};
