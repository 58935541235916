import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Tooltip } from '@material-ui/core';

const borderMap = {
  "Reviewed": "8px solid #006d00cc",
  "Edited": "8px solid #42a5f5",
  "Pending": "1px solid lightgrey"
}
  
const borderColorMap = {
  "Reviewed": "#006d00cc",
  "Edited": "#42a5f5",
  "Pending": "none"
}

const ProductDetailEditDropdown = ({
  attribute,
  index,
  onChangeSelectAttribute,
  reset,
}) => {
  const defaultValue=attribute.values.length>0?attribute.values.find((item) => item.selected):{ value: '', label: '' };
  const nonEditable = defaultValue.tenant_attribute_value_id === -1;
  const [val, setVal] = useState(defaultValue);
  useEffect(() => {
    if (reset) {
      setVal(defaultValue);
    }
  }, [reset]);

  const dropdownStyles = {
    indicatorSeparator: (base) => ({
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      zIndex: '10 !important',
    }),
    control: (base) => ({
      ...base,
      pointerEvents: nonEditable ? 'none' : 'all',
      backgroundColor: nonEditable ? '#d3d3d382' : '#fff',
      border: '1px solid lightgrey',
      borderLeft: `${
        val.label === defaultValue.label
          ? borderMap[attribute.product_attribute_status]
          : '8px solid orange'
      }`,
      boxShadow: val.label !== defaultValue.label ? 'orange' : 'lightgrey',
      '&:hover': {
        borderColor:
          val.label !== defaultValue.label ? 'orange !important' : borderColorMap[attribute.product_attribute_status],
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: nonEditable && 'none',
      color: 'inherit',
      padding: '0px !important',
      paddingRight: '5px !important',
    }),
  };

  return (
    <Tooltip
      title={nonEditable ? 'Cannot edit this value!' : ''}
      placement='right'
      arrow
    >
      <div key={index}>
        <Select
          id='productDetaildropdown'
          options={attribute.values}
          styles={dropdownStyles}
          className='product-detail-attribute-select'
          isSearchable={false}
          isClearable={false}
          name='product-attribute-edit'
          value={val}
          onChange={(obj) => {
            setVal(obj);
            defaultValue.label === obj.label
              ? onChangeSelectAttribute(obj, attribute, 'delete')
              : onChangeSelectAttribute(obj, attribute, 'add');
          }}
        />
      </div>
    </Tooltip>
  );
};

export default ProductDetailEditDropdown;
