import { CHANGE_FILTER_DATA } from '../actions/types';

export  const initialState = {
  filterInitialData:[]
};

export default  function  (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filterInitialData:action.payload
      };
  
    default:
      return state;
  }
}

