import React, { useEffect, useState, useReducer } from 'react'
import axiosInstance from '../../Utils/axios'
import { SingleSelect, MultiSelect } from '../../commonComponents/filters'
import {FILTER_PRODUCT_DETAILS ,FILTER_ELEMENTS }from '../../config/api';
import { find, indexOf } from 'lodash'
import { connect } from 'react-redux'
import Loader from '../../Utils/Loader/loader'
import PaginatedTable from '../../Utils/reactTable/table'
import ProductFilterGroup from './ProductFilterGroup'
import './ProductFilter.css'


const reducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCTS_FILTERS_INIT':
      return { ...state, isLoading: true, isError: false, data: [] }
    case 'PRODUCTS_FILTERS_UPDATE':
      return { ...state, isUpdating: true }
    case 'PRODUCTS_FILTERS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        isError: false,
        data: action.payload,
      }
    case 'PRODUCTS_FILTERS_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: true,
        data: [],
      }
    default:
      break
  }
}

//load data on mount

function ProductsFilter(props) {
  const [{ isLoading, isError, isUpdating, data }, dispatch] = useReducer(
    reducer,
    {
      isLoading: false,
      isUpdating: false,
      isError: null,
      data: [],
    }
  )
  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [pageCount, setPageCount] = useState(2);
const [filterDependency,setDependency]=useState([]);
  const updation = false;



  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: 'PRODUCTS_FILTERS_INIT' })
      try {
        let response = await axiosInstance({
          url: 'common/filter_elements/',
          method: 'GET',
          params: {
            type: 'product',
            is_display: 'true',
          },
        })

        const filterElements = response.data.data.results.map(async (key) => {
          const { data } = await axiosInstance({
            url: key.data_url,
            method: 'POST',
            data: {
              filter_id: key.filter_keyword,
              fields_values: [],
              page: 1,
              filter_type: 'hierarchy',
            },
          })
          key.initialData = data.data
          return key
        })     // }
        const { data: details } = await axiosInstance({
          url: 'product/product_details/',
          method: 'POST',
          data: {
            filter_id: 1,
            fields_values: [],
            page: 1,
            filter_type: 'hierarchy',
          },
        })
        filterElements.push(details)
        await Promise.all(filterElements);
        setPageCount(details.data.next_page);
     

        setRowData([...details.data.results])
        setRowCount(details.data.count)
        let filterData = response.data.data.results.map(element => {
          return {
            key: element.filter_keyword,
            data: element.initialData
          }
        });
        // localStorage.setItem('filterData',JSON.stringify(filterData))
        if (!didCancel) {
          props.changeFilterData(filterData);
          dispatch({ type: 'PRODUCTS_FILTERS_SUCCESS', payload: response.data.data.results })
        }

      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'PRODUCTS_FILTERS_ERROR' })
        }
      }

    }

    fetchData();

    return () => {
      //cleanup
      didCancel = true
    }
  }, [])

  const updateData = async (dependency) => {
    dispatch({ type: 'PRODUCTS_FILTERS_UPDATE' })
    let didCancel = false;
    try {
      let initialFilterElements = [...data]
      const filterElements = initialFilterElements.map(async (key) => {
        const { data } = await axiosInstance({
          url: key.data_url,
          method: 'POST',
          data: {
            filter_id: key.filter_keyword,
            fields_values: dependency,
            page: 1,
            filter_type: 'hierarchy',
          },
        })
        key.initialData = data.data
        return key
      })     // }
      const { data: details } = await axiosInstance({
        url: 'product/product_details/',
        method: 'POST',
        data: {
          filter_id: 1,
          fields_values: dependency,
          page: 1,
          filter_type: 'hierarchy',
        },
      })

      filterElements.push(details)
      await Promise.all(filterElements);
      setPageCount(details.data.next_page);
      setRowData(details.data.results)
      setRowCount(details.data.count)
      let filterData = initialFilterElements.map(element => {
        return {
          key: element.filter_keyword,
          data: element.initialData
        }
      });
      // localStorage.setItem('filterData',JSON.stringify(filterData))
      if (!didCancel) {
        props.changeFilterData(filterData);
        setDependency(dependency)
        dispatch({ type: 'PRODUCTS_FILTERS_SUCCESS', payload: initialFilterElements })
      }

    } catch (error) {
      if (!didCancel) {
        dispatch({ type: 'PRODUCTS_FILTERS_ERROR' })
      }
    }

  }


 
 
  const fetchData = React.useCallback( async({ pageSize, pageIndex }) => {
    console.log('fetch data ')
    const { data: details } = await axiosInstance({
      url: 'product/product_details/',
      method: 'POST',
      data: {
        fields_values: filterDependency,
        page: pageIndex +1,
      },
    })
    return details.data.results
  }, [])


  

  const renderContent = (isError, isLoading, isUpdating, data) => {
    if (isError) {
      return <div>Error ...</div>
    }
    if (isLoading) {
      return <div id="filterContainer" className='filterContainer' > Loading ...</div>
    }


    return (

      <Loader loader={isUpdating ? true : false} >
        <div data-testid='filterContainer' className='filterContainer' >

          <ProductFilterGroup
            filters={data}
            update={updateData}
            updation={updation}
          />
          <div  data-testid='resultContainer' className='resultContainer'  >
            <PaginatedTable
              columns={[{
                Header: 'Description',
                accessor: 'name',
                disableSortBy: true,
              },
              {
                Header: 'Value',
                accessor: 'id',
                disableSortBy: true,
              },

              ]}
              loader={isUpdating ? true : false}
              totalCount={rowCount}
              pageCount={pageCount}
              rowdata={rowData}
              showPagination={true}
              hidePageSelection={true}
              paginatorCallback={fetchData}
            // style={{body:{overflow:'auto', height:'250px'} ,table:{overflow:'unset',height:'300px'}}}
            >  </PaginatedTable>
          </div>
        </div>
      </Loader>

    )
  }



  return (
    <React.Fragment>
      {renderContent(isError, isLoading, isUpdating, data)}
    </React.Fragment>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeFilterData: (data) =>
      dispatch({ type: 'CHANGE_FILTER_DATA', payload: data }),
  }
}

export default connect(null, mapDispatchToProps)(ProductsFilter) ;
//export  {ProductsFilter};
