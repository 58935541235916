import React from 'react';
import { toast } from 'react-toastify';
import './style.scss';
// import "react-toastify/dist/ReactToastify.css";

const ShowToast = (
  message = '',
  type = 'info',
  header = '',
  position = 'bottom-right',
  autoClose = 5000,
  closeOnClick = true,
  pauseOnHover = true
) => {
  toast(
    <>
      {header && (
        <b>
          {header}
          <br />
        </b>
      )}
      {message}
    </>,
    {
      position,
      autoClose,
      closeOnClick,
      pauseOnHover,
      type,
    }
  );
};

export default ShowToast;
