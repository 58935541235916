import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import { ROLE_COLUMNS, GROUP_COLUMNS } from "../utils/usrRoleGrpColumns";
import Table from "../../../Utils/reactTable/table";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  setUsers,
  updateUsrRoleGrpData,
  ToggleLoader,
} from "../../../actions/adminActions";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  updateBtn: {
    marginRight: 20,
  },
});
const RoleGrpTables = (props) => {
  const classes = useStyles();
  const usrObj = _.find(props.users, { email: props.selectedUser.email });
  const [grpTableData, setGrpTableData] = useState(props.groups);
  const [roleTableData, setRoleTableData] = useState(props.roles);
  const [selectedGrps, setSelectedGrps] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRoleObjects, setSelectedRoleObjects] = useState([]);
  const [selectedGrpObjects, setSelectedGrpObjects] = useState([]);
  const GroupTableData = () => {
    const grpSpltArr = _.partition(props.groups, (grp) => {
      return _.includes(usrObj.groups, grp.id);
    });
    const selectedIds = grpSpltArr[0].map((selectedGrp, idx) => {
      const idx_str = idx + "";
      return { idx_str: true };
    });
    setGrpTableData([...grpSpltArr[0], ...grpSpltArr[1]]);
    setSelectedGrps(selectedIds);
  };
  const RoleTableData = () => {
    const roleSpltArr = _.partition(props.roles, (role) => {
      return _.includes(usrObj.roles, role.id);
    });
    const selectedIds = roleSpltArr[0].map((selectedRole, idx) => {
      const idx_str = idx + "";
      return { idx_str: true };
    });
    setRoleTableData([...roleSpltArr[0], ...roleSpltArr[1]]);
    setSelectedRoles(selectedIds);
  };
  useEffect(() => {
    GroupTableData();
    RoleTableData();
  }, [props.selectedUser, props.users]);

  const selectedRoleHandler = (roles) => {
    setSelectedRoleObjects(roles);
  };
  const selectedGroupHandler = (groups) => {
    setSelectedGrpObjects(groups);
  };

  const updateUserRoleGrps = async () => {
    props.toggleLoader(true);
    const updatedRoles = selectedRoleObjects.map((role) => {
      return role.original.id;
    });
    const updatedGrps = selectedGrpObjects.map((group) => {
      return group.original.id;
    });
    const userId = usrObj.id;
    try {
      const response = await props.updateUserRoleGrps({
        user_id: userId,
        roles: updatedRoles,
        groups: updatedGrps,
      });
      if (
        response.data.data == "Groups and roles mapped to user successfully"
      ) {
        const updatedUsrData = props.users.map((user) => {
          if (user.id == userId) {
            return {
              ...user,
              roles: [...updatedRoles],
              groups: [...updatedGrps],
            };
          }
          return user;
        });
        props.setUsers(updatedUsrData);
        props.toggleLoader(false);
        toast.success(
          usrObj.email + " succesfully mapped to roles and groups",
          {
            autoClose: 15000,
            hideProgressBar: true,
          }
        );
      }
    } catch (error) {
      props.toggleLoader(false);
      toast.error("Can't map User to roles and groups", {
        autoClose: 15000,
        hideProgressBar: true,
      });
    }
  };
  return (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <Chip
            size="small"
            icon={<FaceIcon />}
            label={props.selectedUser.email}
            clickable
            color="primary"
            onDelete={props.handleReset}
            deleteIcon={<CancelIcon />}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.updateBtn}
            onClick={updateUserRoleGrps}
          >
            Update
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className="admn-rols-table">
            <label>Roles</label>
            <Table
              rowdata={roleTableData}
              columns={ROLE_COLUMNS}
              selectedRows={selectedRoles}
              selectedRowHandler={selectedRoleHandler}
              rowSelection={true}
              showPagination={true}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="admn-grps-table">
            <label>Groups</label>
            <Table
              rowdata={grpTableData}
              columns={GROUP_COLUMNS}
              selectedRows={selectedGrps}
              selectedRowHandler={selectedGroupHandler}
              rowSelection={true}
              showPagination={true}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: state.adminReducer.groups,
    users: state.adminReducer.users,
    roles: state.adminReducer.roles,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (loadStatus) => dispatch(ToggleLoader(loadStatus)),
    updateUserRoleGrps: (reqData) => dispatch(updateUsrRoleGrpData(reqData)),
    setUsers: (users) => dispatch(setUsers(users)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleGrpTables);
