import React, { memo, useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useParams } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    "&::-webkit-scrollbar": {
      width: "5px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#44677baa",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#44677b",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    PaperProps={{
      style: {
        maxHeight: "320px",
        width: "220px",
      },
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "&:focus": {
      backgroundColor: "theme.palette.common.white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
  selected: {
    backgroundColor: "red !important",
  },
}))(MenuItem);

const CustomStepper = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: "5px",
    // paddingBottom: '15px',
    fontSize: "12px",
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontSize: "12px",
      marginBottom: "10px",
    },
    "& .MuiStep-horizontal": {
      padding: "0 !important",
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      fontWeight: 500,
    },
  },
}))(Stepper);

const L1L2Popup = ({
  L1_L2_categories,
  handleRecategorization,
  state,
  handleModal,
}) => {
  const { open, anchorEl, productDetails } = state;

  const { batch } = useParams();
  const classes = useStyles();
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const handleReset = () => {
    setCategory(null);
    setSubCategory(null);
    setActiveStep(0);
  };

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, []);

  const saveEdits = async () => {
    let requestPayload = {};
    requestPayload.batch_id = batch;
    requestPayload.update_type = "review_update";
    requestPayload.data = [];
    requestPayload.data.push({
      product_attribute_mapping_id: productDetails.L1_category_detail.id,
      product_id: productDetails.id,
      tenant_attribute_id:
        productDetails.L1_category_detail.tenant_attribute_id,
      tenant_attribute_value:
        productDetails.L1_category_detail.tenant_attribute__name,
      new_value: category.value,
      edited: true,
    });
    requestPayload.data.push({
      product_attribute_mapping_id: productDetails.L2_category_detail.id,
      product_id: productDetails.id,
      tenant_attribute_id:
        productDetails.L2_category_detail.tenant_attribute_id,
      tenant_attribute_value:
        productDetails.L2_category_detail.tenant_attribute__name,
      new_value: subCategory.value,
      edited: true,
    });
    handleRecategorization("l1_l2", requestPayload);
  };

  const updateModal = () => {
    handleModal({
      anchorEl: null,
      open: false,
      productDetails: {},
    });
  };

  const handleClose = (event) => {
    event.stopPropagation();
    updateModal();
  };

  const handleNext = (event) => {
    event.stopPropagation();
    setActiveStep(activeStep + 1);
  };

  const handleSave = (event) => {
    event.stopPropagation();
    updateModal();
    saveEdits();
  };

  function getSteps() {
    return ["Category", "Subcategory", "Confirm"];
  }

  function getStepContent(step) {
    if (step === 0) {
      return L1_L2_categories.map((val) => {
        return (
          <StyledMenuItem
            id={`RecategorisationStep1-${productDetails.product_id}`}
            style={{ fontSize: "13px" }}
            key={val.id}
            onClick={(e) => {
              setCategory({
                label: val.label,
                value: val.value,
                tenant_attribute_id: val.id,
              });
              handleNext(e);
            }}
          >
            {val.label}
            {productDetails?.L1_category_detail?.value === val.value && (
              <CheckCircleIcon style={{ color: "green" }} />
            )}
          </StyledMenuItem>
        );
      });
    } else if (step === 1) {
      let subCat = [];
      if (category?.value) {
        subCat = L1_L2_categories?.find(
          (val) => val.id === category.tenant_attribute_id
        )["sub_category_list"];
      }
      return subCat.map((val) => {
        return (
          <StyledMenuItem
            id={`RecategorisationStep2-${productDetails.product_id}`}
            style={{ fontSize: "13px" }}
            key={val.id}
            onClick={(e) => {
              setSubCategory({
                label: val.label,
                value: val.value,
                tenant_attribute_id: val.id,
              });
              handleNext(e);
            }}
          >
            {val.label}
            {productDetails?.L2_category_detail?.value === val.value && (
              <CheckCircleIcon style={{ color: "green" }} />
            )}
          </StyledMenuItem>
        );
      });
    } else
      return (
        <>
          <div
            style={{ padding: "12px" }}
            id={`RecategorisationStep3-${productDetails.product_id}`}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold" }}
              variant="caption"
            >
              Category:{" "}
            </Typography>
            <p style={{ fontSize: "13px", marginTop: "4px" }}>
              {category?.label}
            </p>
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold" }}
              variant="caption"
            >
              Subcategory:{" "}
            </Typography>
            <p style={{ fontSize: "13px", marginTop: "4px" }}>
              {subCategory?.label}
            </p>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button
              id="saveRecategorisationBtn"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              id="cancelRecategorisationBtn"
              variant="contained"
              color="primary"
              size="small"
              style={{
                color: "#4f677b",
                backgroundColor: "rgb(239 239 239 / 40%)",
                marginLeft: "10px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </>
      );
  }

  const steps = getSteps();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={`RecategorisationDiv-${productDetails.product_id}`}
    >
      <StyledMenu
        id={`RecategorisationMenu-${productDetails.product_id}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={classes.root}
          id={`RecategorisationBase-${productDetails.product_id}`}
        >
          <CustomStepper
            activeStep={activeStep}
            alternativeLabel
            id={`RecategorisationStepper-${productDetails.product_id}`}
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                id={`RecategorisationStepLabel-${productDetails.product_id}`}
              >
                <StepLabel style={{ fontSize: "12px !important" }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </CustomStepper>
          <div id={`RecategorisationStepperMenu-${productDetails.product_id}`}>
            {getStepContent(activeStep)}
          </div>
        </div>
      </StyledMenu>
    </div>
  );
};

export default memo(L1L2Popup);
