import React, { useEffect, useReducer, useRef, useCallback } from "react";
import update from "immutability-helper";
import CardWrapper from "./CardWrapper";
import { AS_EXPLORE_SUMMARY } from "../../../../../config/api";
import CardSkeleton from "./CardSkeleton";
import axiosInstance from "../../../../../Utils/axios";
import SortButton from "./SortButton";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: 32,
  },
  container: {
    display: "flex",
    alignItems: "center",
    padding: "2px 0",
    width: "calc(100vw - 280px)",
    overflowX: "scroll",
    scrollBehavior: "auto|smooth|initial|inherit",
    marginLeft: 60,
    marginRight: 60,
    "&::-webkit-scrollbar": {
      height: 0,
      width: 0,
      background: "transparent",
    },
    "& > *": {
      margin: "0 8px",
    },
    "& > :first-child": {
      margin: 0,
    },
    "& > :nth-child(2)": {
      margin: "0 8px 0 2px",
    },
    "& > :nth-last-child(2)": {
      margin: "0 2px 0 8px",
    },
    "& > :last-child": {
      margin: 0,
    },
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(2, 0),
    textTransform: "capitalize",
  },
  nav: {
    position: "absolute",
    height: 100,
    width: 45,
    lineHeight: 100,
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "0 1px 3px #888",
    userSelect: "none",
    cursor: "pointer",
    opacity: 0.5,
    zIndex: 1,

    "& > svg": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: 36,
      height: 36,
    },
    "&:hover": {
      transition: "opacity 200ms",
      opacity: 1,
    },
  },
  next: {
    right: 0,
    borderRadius: "3px 0 0 3px",
    clip: "rect(-10px, 45px, 110px, -10px)",
  },
  prev: {
    left: 0,
    borderRadius: "0 3px 3px 0",
    clip: "rect(-10px, 67px, 163px, 0px)",
  },
}));

// -ms-user-select: none;
// -webkit-user-select: none;

// &:hover {
//     transition: opacity 200ms;
//     opacity: 1;
// }

// right: 0;
// border-radius: 3px 0 0 3px;
// clip: rect(-10px, 45px, 110px, -10px);
// padding-left: 5px;

const reducer = (state, action) => {
  switch (action.type) {
    case "SUMMARY_INIT":
      return { ...state, isloading: true };
    case "SUMMARY_SUCESS":
      return update(state, {
        isloading: { $set: false },
        data: { $push: action.payload.data },
        page: { $set: action.payload.page },
      });
    case "SUMMARY_ERROR":
      return { ...state, isloading: false, isError: true };
    case "HARD_RESET":
      return {
        isloading: false,
        isError: false,
        data: [],
        page: 1,
        sortBy: action.payload,
      };
    default:
      break;
  }
};

function SummaryRow(props) {
  const [{ isloading, isError, data, page, sortBy }, dispatch] = useReducer(
    reducer,
    {
      isloading: false,
      isError: false,
      data: [],
      page: 1,
      sortBy: props.sortOptions[0].value,
    }
  );
  const classes = useStyles();
  const scrollMultiplier = 4;
  const containerRef = useRef(null);
  const observer = useRef(null);

  const fetchData = async () => {
    try {
      dispatch({ type: "SUMMARY_INIT" });
      const response = await axiosInstance.get(AS_EXPLORE_SUMMARY, {
        params: {
          batch_id: props.batch,
          category: props.category,
          page: page,
          orderby: sortBy,
          view: props.view,
        },
      });
      const { next, l2_categories } = response.data.data;
      let payload = {
        data: l2_categories,
        page: next,
      };
      dispatch({ type: "SUMMARY_SUCESS", payload: payload });
    } catch (error) {
      console.log(error);
      dispatch({ type: "SUMMARY_ERROR" });
    }
  };

  const onPrevious = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -220 * scrollMultiplier,
        behavior: "smooth",
      });
    }
  };

  const onNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 220 * scrollMultiplier,
        behavior: "smooth",
      });
    }
  };

  const lastCardElementRef = useCallback(
    (node) => {
      if (isloading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page !== "-1") {
          fetchData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isloading, page]
  );

  useEffect(() => {
    fetchData();
  }, [sortBy]);

  const onSort = (params) => {
    if (params !== sortBy) {
      dispatch({ type: "HARD_RESET", payload: params });
    }
  };

  return (
    <div id={"L1 Category_" + props.id} className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="subtitle2" gutterBottom>
          {props.category}
        </Typography>
      </div>
      <Container
        disableGutters
        maxWidth={false}
        className={classes.container}
        ref={containerRef}
        id={"L2_category_" + props.id}
      >
        <div
          className={clsx(classes.nav, classes.prev)}
          onClick={onPrevious}
          id={"Prev_" + props.id}
        >
          <ChevronLeftIcon />
        </div>
        <React.Fragment>
          {data &&
            data.map((item, i) => {
              if (data.length === i + 1) {
                return (
                  <CardWrapper
                    key={i}
                    ref={lastCardElementRef}
                    data={item}
                    category={props.category}
                    view={props.view}
                  />
                );
              } else {
                return (
                  <CardWrapper
                    key={i}
                    data={item}
                    category={props.category}
                    view={props.view}
                  />
                );
              }
            })}
        </React.Fragment>
        {isloading && <CardSkeleton count={7} />}
        <div
          className={clsx(classes.nav, classes.next)}
          onClick={onNext}
          id={"Next_" + props.id}
        >
          <ChevronRightIcon />
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  view: state.attributeSmartReducer.view,
});

export default connect(mapStateToProps, {})(SummaryRow);
