import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ToggleLoader,
  setGroups,
  creategroup,
} from "../../../actions/adminActions";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Select from "../utils/select";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});
const CreateGroup = (props) => {
  const classes = useStyles();
  const [modalStatus, setModalStatus] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const onChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleModalClose = () => {
    setModalStatus(false);
    resetGroupForm();
  };

  const resetGroupForm = () => {
    setGroupName("");
    setSelectedPermissions([]);
  };
  const permissionOptions = () => {
    return props.permissions.map((permission) => {
      return { label: permission.name, value: permission.id };
    });
  };

  const createGroup = async () => {
    const permissns = selectedPermissions.map((permission) => {
      return permission.value;
    });
    try {
      props.toggleLoader(true);
      const response = await props.createGroup({
        name: groupName,
        permissions: permissns,
      });
      let updatedGroups = [
        {
          id: response.data.data.id,
          name: response.data.data.name,
          permissions: response.data.data.permissions,
        },
        ...props.groups,
      ];
      props.setGroups(updatedGroups);
      resetGroupForm();
      toast.success("Group Created Succesfully", {
        autoClose: 15000,
        hideProgressBar: true,
      });

      props.toggleLoader(false);
    } catch (error) {
      toast.error(error.response.data.data.name[0], {
        autoClose: 15000,
        hideProgressBar: true,
      });
      props.toggleLoader(false);
    }
  };
  return (
    <>
      <Grid container>
        <Button
          id="admnBtnCrtGrp"
          variant="contained"
          color="primary"
          onClick={() => setModalStatus(true)}
        >
          Create Group
        </Button>
        <Dialog
          onClose={() => handleModalClose()}
          aria-labelledby="customized-dialog-title"
          open={modalStatus}
          fullWidth={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          classes={{
            paperFullWidth: classes.paperFullWidth,
          }}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              Create Group
              <IconButton aria-label="close" onClick={() => handleModalClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.dialogContentRoot,
            }}
          >
            <form id="admnFormGrp">
              <div className="form-group">
                <TextField
                  name="groupName"
                  label="GroupName"
                  variant="outlined"
                  required
                  value={groupName}
                  onChange={onChange}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-group">
                <label>Permissions</label>
                <Select
                  options={permissionOptions()}
                  isMulti={true}
                  value={selectedPermissions}
                  onChange={(options) => setSelectedPermissions(options)}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleModalClose()}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => {
                createGroup();
              }}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.adminReducer.permissions,
    groups: state.adminReducer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (loadStatus) => dispatch(ToggleLoader(loadStatus)),
    createGroup: (grpData) => dispatch(creategroup(grpData)),
    setGroups: (groups) => dispatch(setGroups(groups)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
