import styled from 'styled-components'

export const SidebarToggle = styled.div`
    width: var(--width);
    padding: var(--padding);
    cursor: pointer;
    margin-bottom: 3px;
`

export const SidebarHeader = styled.div``

export const SidebarIcon = styled.span`
    padding: var(--padding) 0;
    display: flex;
`

export const SidebarItemLabelText = styled.span`
    opacity: 0;
    visibility: hidden;
`

export const SidebarItemLabel = styled.div`
    display: flex;
    align-items: center;

    ${SidebarIcon} {
        &:before {
            content: '';
            padding-left: var(--padding);
        }
        svg {
            width: 18px;
        }

        &:after {
            content: '';
            padding-right: var(--padding);
        }
    }

    ${SidebarItemLabelText} {
        white-space: nowrap;
        font-size: 13px;
        color: white;
    }
`

export const SidebarItem = styled.li`
    width: 100%;
    height: 100%;

    a {
        text-decoration: none;
    }

    &:hover,
    &.active {
        background: var(--hover-color) 0% 0% no-repeat padding-box;
    }
`

export const SidebarItemsWrapper = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 1rem;

    ${SidebarItem} {
        a.active ${SidebarItemLabel} {
            background: var(--hover-color) 0% 0% no-repeat padding-box;
        }
    }

    ${SidebarItem}:not(:last-child) {
        margin-bottom: 1px;
    }
`

export const SidebarBody = styled.div`
    width: 100%;
`

export const SidebarWrapper = styled.nav`
    --selected-color: ${({ theme }) => theme.selectedColor};
    --width: ${({ theme }) => theme.width};
    --font-color: ${({ theme }) => theme.fontColor};
    --active-width: ${({ theme }) => theme.activeWidth};
    --hover-color: ${({ theme }) => theme.hoverColor};
    --slide-time: ${({ theme }) => theme.slideTime};
    --font-size: ${({ theme }) => theme.fontSize};
    --bg-color: ${({ theme }) => theme.bgColor};
    --padding: ${({ theme }) => theme.padding};

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99999;
    width: var(--width);
    background: linear-gradient(135deg, #44677b 0%, #436378 100%);
    transition: width var(--slide-time) ease;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: inset 0px 0px 20px 2px #0003;

    &.active {
        width: var(--active-width);

        ${SidebarItemLabel} {
            ${SidebarItemLabelText} {
                transition: opacity var(--slide-time) ease-in-out
                    var(--slide-time);
                opacity: 1;
                visibility: visible;
            }
        }
    }
`

SidebarWrapper.defaultProps = {
    theme: {
        selectedColor: '#5a7c90',
        width: '42px',
        fontColor: 'fff',
        fontSize: '0.625rem',
        activeWidth: '185px',
        hoverColor: '#547689',
        slideTime: '200ms',
        bgColor: '#44677b',
        padding: '12px'
    }
}
