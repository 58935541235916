import { combineReducers } from 'redux';
import authReducer from './authReducer';
import loaderReducer from './loaderReducer';
import layoutReducer from './layoutReducer';
import adminReducer from './adminReducer';
import dashboardReducer from '../modules/attribute-smart/reducers/dashboardReducer';
import exploreCategoryReducer from '../modules/attribute-smart/reducers/exploreCategoryReducer';
import filterReducer from'./filterReducer';
import tenantReducer from './tenantReducer';
import notificationReducer from './notificationReducer';
import attributeSmartReducer from '../modules/attribute-smart/reducers/attributeSmartReducer';
import workflowReducer from './workflowReducer'

export default combineReducers({
  adminReducer,
  authReducer,
  layoutReducer,
  loaderReducer,
  dashboardReducer,
  filterReducer,
  exploreCategoryReducer,
  tenantReducer,
  notificationReducer,
  attributeSmartReducer,
  workflowReducer
});
