import React, { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import './settings.css';
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AUTO_GET_NEW_OPTIMIZED_DATA } from '../../../actions/types';
const useStyles = makeStyles((theme) => ({
  labelBox: {
    backgroundColor: '#5a7c90',
    color: 'white',
    alignItems: 'center',
    height: 'max-content',
    padding: '8px',
    marginRight: '5px',
  },
}));
const Settings = (props) => {
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(props.value);
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (!isUpdating) {
      setUpdatedValue(props.value);
    }
  }, [isUpdating]);
  const cancelEditing = () => {
    setUpdatedValue(props.value);
    setIsEditable(false);
  };

  const onUpdate = async () => {
    setIsUpdating(true);
    setIsEditable(false);
    const res = await props.updateFunc({
      id: props.id,
      label: props.label,
      value: updatedValue,
    });
    setIsUpdating(false);
  };

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Box className={classes.labelBox}>{props.label}</Box>
        <TextField
          disabled={!isEditable}
          id={props.label}
          value={updatedValue}
          variant="outlined"
          size="small"
          onChange={(event) => setUpdatedValue(event.target.value)}
        />
        <Grid item>
          {isEditable ? (
            <span>
              <i
                className="fa fa-times-circle  set-btns"
                onClick={() => cancelEditing()}
              ></i>
            </span>
          ) : (
            <span>
              <i
                className="fa fa-edit set-btns"
                onClick={() => setIsEditable(true)}
              ></i>
            </span>
          )}
        </Grid>
        <Grid item>
          <button
            type="btn"
            id="setBtnSave"
            className="set-btn-save"
            disabled={!(isEditable || isUpdating)}
          >
            <i
              className="fa fa-save fa-save-icon"
              onClick={() => onUpdate()}
            ></i>
          </button>
        </Grid>
        <Grid item>
          <div id="setUpdLoader" className="set-upd-loader">
            <BeatLoader color="#4f677b" loading={isUpdating} size={6} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

Settings.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default connect(null, null)(Settings);
