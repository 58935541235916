import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { InputSwitch } from 'primereact/inputswitch'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import styled from 'styled-components'

const ListItem = styled.i`
  list-style: none;
  display: flex;
  width: 50%;
  margin: 1%;
  align-items: center;
`
const ListItemText = styled.div`
  vertical-align: super;
  display: inline-block;
  flex: 1;
  margin: 1%;
`

const NotificationSettings = (props) => {
  const [items, setItems] = useState(props.items)

  useEffect(() => {
    props.getUserNotificationTypes()
  }, [])

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const switchNotificationSetting = (event) => {
    let itemsCopy = [...items]
    itemsCopy[event.target.id].is_active = event.value
    setItems(itemsCopy)
  }
  const setUserNotificationTypes = () => {
    let enabledItems = []
    let disabledItems = []
    items.forEach(item => { 
      if(item.is_active) enabledItems.push(item.id)
      else disabledItems.push(item.id)
  })
    props.setUserNotificationTypes({
      enable_user_notification_type_ids: enabledItems,
      disable_user_notification_type_ids: disabledItems,
    }).then((res) => {
      props.setNotificationSettings(false)
    })
  }
  const footer = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-check'
        onClick={() => props.setNotificationSettings(false)}
      />
      <Button
        label='Save'
        icon='pi pi-times'
        onClick={setUserNotificationTypes}
      />
    </div>
  )
  return (
    <>
      <Dialog
        dismissableMask
        header='Manage notification settings'
        footer={footer}
        visible={props.visible}
        style={{ width: '30vw' }}
        onHide={() => props.setNotificationSettings(false)}
      >
        <Divider />
        <ul>
          {items.map((item, i) => (
            <ListItem key={item.id}>
              <ListItemText>{item.notification_type_name}</ListItemText>
              <InputSwitch
                checked={item.is_active}
                id={i}
                onChange={switchNotificationSetting}
              ></InputSwitch>
            </ListItem>
          ))}
        </ul>
        
        <Divider />
      </Dialog>
    </>
  )
}

export default NotificationSettings
