import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import VariantView from "./VariantView";
const VariantDetail = ({
  data,
  onChangeSelectAttribute,
  reset,
  deleteVariant,
  changesArray,
}) => {
  const [listMode, setListMode] = useState(false);
  return (
    <ContainerDiv>
      <HeaderDiv>
        <div>
          <Typography
            variant="inherit"
            style={{
              fontSize: "17px",
              fontWeight: "600",
            }}
          >
            Variants
          </Typography>
        </div>
        <SwitchDiv>
          <Toggle listMode={listMode} setListMode={setListMode} />
        </SwitchDiv>
      </HeaderDiv>
      <BodyDiv>
        <VariantView
          data={data}
          listMode={listMode}
          onChangeSelectAttribute={onChangeSelectAttribute}
          reset={reset}
          deleteVariant={deleteVariant}
          changesArray={changesArray}
        />
      </BodyDiv>
    </ContainerDiv>
  );
};

export default VariantDetail;

const ContainerDiv = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid lightgray;
  margin: 0 10px 0 10px;
  border-radius: 4px;
  padding: 20px 0 20px 0;
`;

const HeaderDiv = styled.div`
  display: flex;
  margin: 0 20px 0 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid lightgray;
`;
const SwitchDiv = styled.div`
  margin: 0 auto;
`;

const BodyDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Toggle = ({ listMode, setListMode }) => (
  <>
    <ToggleButtonGroup
      value={listMode}
      exclusive
      onChange={() => setListMode(!listMode)}
      aria-label="view"
      size="small"
    >
      <ToggleButton value={false} aria-label="grid">
        <ViewModuleIcon />
      </ToggleButton>
      <ToggleButton value={true} aria-label="list">
        <ViewListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  </>
);
