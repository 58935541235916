import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { NavLink, withRouter } from 'react-router-dom'

import { ChevronsLeft, ChevronsRight } from 'react-feather'
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

import { ThemeProvider } from 'styled-components'
import logo from '../../assets/logo.png';

const sidebarIcon = (itemIcon, itemTitle) => {
  if (itemIcon instanceof Object) {
    return itemIcon
  } else {
    return <img className='icon' src={itemIcon} alt={itemTitle} />
  }
}
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  '&a': {
    textDecoration: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    background: '#091523',
    overflowX: 'hidden',

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#091523',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem',
  },
  list: {
    flex: 1,
  },
  listItemIcon: {
    color: 'unset',
    minWidth: '40px',
  },
  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
    '&:hover': {
      color: 'white',
      backgroundColor: 'grey',
    },
  },
  listItemText: {
    '& span': {
      fontSize: '0.9rem',
    },
  },

  listitemSidebar: {
    color: '#8493a5bd',
    display: 'flex',
    alignItems: 'center',
    '&:hover ': {
      color: 'white',
    },
  },
  sidebarMenu: {
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    textDecoration: 'none',
  },

  sidebarFooter: {
    bottom: '10px',
    color: 'white',
    textAlign: 'center',
    padding: '14px',
    fontSize: '13px',
  },
  footerText: {
    fontSize: '0.8rem',
    whiteSpace: 'normal',
  },
  sidebarMiddle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  userText: {
    textAlign: 'left',
    color: 'white',
    marginLeft: '10px',
    wordWrap: 'break-word',
  },
  userProfile: {
    display: 'flex',
    flexDirection: 'row',
    padding: '14px',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    margin: '10% 7%'
  },
  logoImage: {
      height: '100%'
  }
}))

const drawerWidth = 220

function SideBar({ options, pathPrefix, match, location, theme }) {
  const wrapperRef = useRef()
  const [isActive, setisActive] = useState(false)

  const classes = useStyles()

  const toggleSideBarExpansionHandler = (expand) => {
    setisActive((isActive) => !isActive)
  }
  let userName = localStorage.getItem('name')
    ? localStorage.getItem('name').split('@')[0]
    : 'User'
  const shortName = userName
    .split('.')
    .map((item) => item[0].toUpperCase())
    .join('')
  userName = userName
    .split('.')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ')

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => {
      document.removeEventListener('mousedown', handleClick, false)
    }
  }, [])

  const handleClick = (e) => {
    if (wrapperRef.current.contains(e.target)) {
      return
    }
    setisActive(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant='permanent'
        ref={wrapperRef}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isActive,
          [classes.drawerClose]: !isActive,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isActive,
            [classes.drawerClose]: !isActive,
          }),
        }}
      >
        <div
          style={{ padding: '14px' }}
          onClick={() => toggleSideBarExpansionHandler()}
        >
          {!isActive && <ChevronsRight color='white' size='18' />}
          {isActive && <ChevronsLeft color='white' size='18' />}
        </div>

        <div className={classes.logo}>
          <img src={logo} className={classes.logoImage} />
        </div>

        <div className={classes.userProfile}>
          <Avatar className={classes.small}>{shortName}</Avatar>
          {isActive && <span className={classes.userText}>{userName}</span>}
        </div>
        <div className={classes.sidebarMiddle}>
          <List className={classes.list}>
            {options.map((item, i) => (
              <ListItem
                id={'sidebar-item-' + i}
                className={classes.listItem}
                button
                key={item.title}
              >
                <NavLink
                  to={pathPrefix + item.link}
                  className={classes.sidebarMenu}
                  id={item.title}
                >
                  <div className={classes.listitemSidebar}>
                    <Tooltip title={item.title}>
                      <ListItemIcon className={classes.listItemIcon}>
                        {sidebarIcon(item.icon, item.title)}
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.title}
                    />
                  </div>
                </NavLink>
              </ListItem>
            ))}
          </List>

          <div className={classes.sidebarFooter}>
            <p className={classes.footerText}>
              {isActive ? (
                ' Copyrights ©2021 Impact Analytics. All Rights Reserved.'
              ) : (
                <span>
                  © <br />
                  2021
                </span>
              )}
            </p>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  )
}

SideBar.defaultProps = {
  options: [],
  pathPrefix: '',
  theme: {
    width: 'var(--sidebar-width)',
    activeWidth: 'var(--sidebar-active-width)',
    fontColor: 'var(--sidebar-font-color)',
    fontSize: 'var(--sidebar-font-size)',
    selectedColor: 'var(--sidebar-selected-color)',
    hoverColor: 'var(--sidebar-hover-color)',
    bgColor: 'var(--sidebar-background-color)',
    padding: 'var(--sidebar-padding)',
    slideTime: 'var(--sidebar-slide-time)',
  },
}

SideBar.propTypes = {
  options: PropTypes.array,
  pathPrefix: PropTypes.string,
  theme: PropTypes.object,
}

export default withRouter(SideBar)
