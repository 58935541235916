import React, { memo } from "react";
import { connect } from "react-redux";
import ProductView from "./ProductView";

const ProductCategoryList = (props) => {
  return <ProductView view={props.view} {...props} />;
};

const mapStateToProps = (state) => ({
  view: state.attributeSmartReducer.view,
});

export default connect(mapStateToProps, {})(memo(ProductCategoryList));
