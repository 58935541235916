import React from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router-dom";
import { getValueForKey } from "./helper";
import { connect } from "react-redux";
import { resetChangesArray as resetChangesArr } from "../../../reducers/exploreCategoryReducer";

const L1L2Button = ({
  resetChangesArray,
  changesArr,
  handleRecategorization,
  productDetails,
  handleModal,
  handleWarning,
}) => {
  const { batch } = useParams();

  const unTagProduct = (current) => {
    handleRecategorization("delete", {
      batch_id: batch,
      product_id: productDetails.product_id,
      current,
    });
  };

  const updateModal = (anchorEl) => {
    handleModal({
      anchorEl: anchorEl,
      open: true,
      productDetails: productDetails,
    });
  };

  const updateWarning = (message, cb1, cb2 = () => null) => {
    handleWarning({
      status: true,
      header: "Confirm",
      message,
      callback: (current) => {
        cb1();
        cb2(current);
      },
    });
  };

  const handleClick = (event) => {
    event.stopPropagation();
    const target = event.currentTarget;
    if (changesArr) {
      const message =
        "Are you sure you want to leave this page without saving changes ?";
      updateWarning(message, resetChangesArray, () => updateModal(target));
    } else {
      updateModal(target);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();

    if (changesArr) {
      let message =
        "Are you sure you want to leave this page without saving changes and delete this product from this batch ?";
      if (getValueForKey("type") === "true") {
        message =
          "Are you sure you want to leave this page without saving changes and delete this variant or all variants of this product from this batch ?";
      }
      updateWarning(message, resetChangesArray, unTagProduct);
    } else {
      let message =
        "Are you sure you want to delete this product from this batch?";
      if (getValueForKey("type") === "true") {
        message =
          "Do you want to delete this variant or all variants of this product ?";
      }
      updateWarning(message, () => null, unTagProduct);
    }
  };

  return (
    <ActionsDiv
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={`RecategorisationDeletionDiv-${productDetails.product_id}`}
    >
      <IconButton
        id={`RecategorisationBtn-${productDetails.product_id}`}
        aria-label="recategorization"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ArrowDropDownIcon
          id="L1L2ReCatBtn"
          color={"primary"}
          style={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
          }}
        />
      </IconButton>
      <IconButton
        id={`DeletionBtn-${productDetails.product_id}`}
        aria-label="untag"
        aria-controls="confirm-menu"
        aria-haspopup="true"
        onClick={handleDelete}
      >
        <CloseIcon
          id="L1L2DeleteBtn"
          color={"primary"}
          style={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
            padding: "4px",
          }}
        />
      </IconButton>
    </ActionsDiv>
  );
};

const mapStateToProps = (state) => ({
  changesArr: !!state.exploreCategoryReducer.changesArray?.length,
});
export default connect(mapStateToProps, { resetChangesArray: resetChangesArr })(
  L1L2Button
);

const ActionsDiv = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  width: 220px;
  justify-content: space-between;
  cursor: default !important;
`;
