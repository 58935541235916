import React, { useReducer, useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Select, { components, defaultTheme } from "react-select";
import { Inbox, Filter, CheckSquare } from 'react-feather';
import axiosInstance from '../../../Utils/axios';
import { find, indexOf, cloneDeep, debounce } from 'lodash';

const { colors } = defaultTheme;
const selectStyles = {
    control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    noOptionsMessage: styles => ({
        ...styles,
        height: '300px'
    })
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'OPTION_INIT':
            return { ...state, isLoading: true };
        case 'OPTION_ERROR':
            return { ...state, isLoading: false, isError: true }
        case 'OPTION_SUCCESS':
            return { ...state, isLoading: false, isError: false, data: [...state.data,...action.payload] }
            case 'OPTION_SET':
                return { ...state, isLoading: false, isError: false, data: [...action.payload] }
        case 'OPTION_RESET':
            return { ...state, isLoading: false, isError: false, data: [] }
        case 'SEARCH_INIT':
            return { ...state, isSearching: true };
        case 'SEARCH_SUCCESS':
            return { ...state, isSearching: false, searchData: [...state.searchData, ...action.payload] };
        case 'SEARCH_RESET':
            return { ...state, isSearching: false, searchData: [] }
        default:
            break;

    }
}




function MultiSelect(props) {
    const [isOpen, setisOpen] = useState(false);
    const [page, setpage] = useState(2);
    const [optionSelected, setoptionSelected] = useState([])
    const [prevDependency, setprevDependency] = useState(null)
    const [nextSet, setNextSet] = useState([])
    const [searchPage, setsearchPage] = useState(1);
    const [searchValue, setsearchValue] = useState('')
    const firstTimeRender = useRef(true);
    const [{ isLoading, isError, data, isSearching, searchData }, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: false,
        data: [],
        isSearching: false,
        searchData: [],
    });



    const getDependency = (params) => {
        //todo - calculate the dependency selections for current select
        //that is find all selected values before selecting the current

        // console.log('incoming dependency--', props, props.dependency)

        let dependency = cloneDeep(props.dependency);
        let index = indexOf(dependency, find(dependency, { 'field': props.filter_keyword }))
        if (index !== -1) {
            dependency = dependency.slice(0, index)
            return dependency
        } else {
            return dependency
        }

    }


    const fetchData = useCallback(async (page) => {
        if (page === 1) {
            dispatch({ type: 'OPTION_RESET' })
        }

        // dispatch({ type: 'OPTION_INIT' })
        try {
            const response = await axiosInstance({
                url: props.data_url,
                method: 'POST',
                data: {
                  filter_id: props.filter_keyword,
                  fields_values: prevDependency,
                  page: page,
                  filter_type: 'hierarchy',
                },
              })


            setNextSet(response.data.data.results)
            // dispatch({ type: 'OPTION_SUCCESS', payload: response.data.data.results })
            setpage(response.data.data.next_page)
            // setpage(page+1);

        } catch (error) {
            dispatch({ type: 'OPTION_ERROR' })
        }

    },
        [page, prevDependency],
    )


    const fetchSearch = useCallback(async (searchPage, searchValue) => {

        dispatch({ type: 'SEARCH_INIT' })
        try {
            const response =await axiosInstance({
              url: props.data_url,
              method: 'POST',
              data: {
                filter_id: props.filter_keyword,
                fields_values: prevDependency,
                page: searchPage,
                search:searchValue,
                filter_type: 'hierarchy',
              },
            })

            dispatch({ type: 'SEARCH_SUCCESS', payload: response.data.data.results })
            setsearchPage(response.data.data.next_page)

        } catch (error) {
            //dispatch({ type: 'OPTION_ERROR' })
            console.log('error');
        }

    },
        [searchPage, searchValue, prevDependency],
    )
    // useEffect(() => {
    //         console.log('Next Set', page)
    //         //setpage(1)

    //         dispatch({ type: 'OPTION_SUCCESS', payload: nextSet})

    //     return () => {
    //         // cleanup
    //     }
    // }, [JSON.stringify(nextSet)])

    useEffect(() => {
        if (!firstTimeRender.current) {
            // console.log('prevDependency', prevDependency)
            // setpage(1)

            fetchData(2)
        }

        return () => {
            // cleanup
        }
    }, [JSON.stringify(prevDependency)])

    useEffect(() => {
        if (!firstTimeRender.current) {
            fetchSearch(searchPage, searchValue)
        }
        return () => {
            //cleanup
        }
    }, [searchValue])

    // useEffect(() => {
    //     console.log('data red',props)
    //     if (props.updation) {
    //     console.log("first time render  " ,props.initialData)
    //     dispatch({ type: 'OPTION_SUCCESS', payload: props.initialData ?props.initialData.results :[]})
    //     setpage(2)
    //     }
    //     return () => {
    //         //cleanup
    //     }
    // }, [data])


    useEffect(() => {
        // dispatch({ type: 'OPTION_SUCCESS', payload: props.initialData ?props.initialData.results :[]})
        //setpage(2)
        firstTimeRender.current = false
        return () => {
            //cleanup
        }
    }, [])


    const dropdownOpen = (params) => {

        //load the data on initial dropdown click
        // if (page === 1) {
        //     fetchData()
        //     setprevDependency(getDependency())
        // }
        let initialData = props.filterData.filter(item => item.key === props.filter_keyword);
        dispatch({ type: 'OPTION_SET', payload: initialData ? initialData[0].data.results : [] })
        setprevDependency(getDependency())

        //todo - check if the dependency list of selected  value has changed or not
        // if yes set all state to initial and fire new data fetch req
        // else do nothing
        setisOpen(!isOpen)
    }


    const dropdownClose = async (params) => {
        //todo - 
        // verify if there is difference between the previous selected values and current
        // if yes send selected options list to parent on close to save
        // else if selected values is null remove the option from the selected value of parent
        // else do nothing
        dispatch({ type: 'SEARCH_RESET' });
        setsearchPage(1);
        setsearchValue('');
        fetchData(2);
        props.updateDependency({ 'filter_id': props.filter_keyword }, optionSelected)
        setisOpen(!isOpen)


    }

    const dropdownSelectedMessage = (params) => {
        //todo-
        // items selected
        if (optionSelected.length) {
            if (optionSelected.length === data.length) {
                return ('All Selected')
            } else {
                return (`${optionSelected.length} ${optionSelected.length > 1 ? ' items' : ' item'} selected`)
            }
        }
        return ('Select...')
    }

    const onSelectAll = (params) => {
        //todo -
        // select all options on clicked
        //select only if there is data to pe selected and not all selected
        if (data.length && data.length !== optionSelected.length) {
            console.log('select all')
            setoptionSelected(data)
        }

    }

    const onClearAll = (params) => {
        //todo -
        //clear all options on clicked if there is something to be cleared
        if (optionSelected.length) {
            console.log('clear all')
            setoptionSelected([])
        }

    }

    const onMenuScrollToBottom = (params) => {
        console.log('onMenuScrollToBottom---', page, prevDependency)
        //if data already loading dont call fetchData again
        if (!isLoading) {
            //do nothing as already scroll
            dispatch({ type: 'OPTION_SUCCESS', payload: nextSet })
            if (page) {
            fetchData(page)
            }else{
            setNextSet([])
            }
        }
        //todo
        //if current loaded result is search result then onmenusroll should call to load search data not option data

    }

    const onChange = (selected, event) => {
        //on option selected
        setoptionSelected(selected)
    }



    const search = useCallback(

        debounce(searchKey => {
            console.log(searchKey)
            setsearchValue(searchKey)
        }, 500),
        [],
    )


    const handleSearch = (event) => {
        //todo check if search input values are spaces or special characters
        //before calling search

        if (event.target.value.length > 0) {
            search(event.target.value)
        } else {
            dispatch({ type: 'SEARCH_RESET' });
        }
    }


    return (
        <Dropdown
            isOpen={isOpen}
            onClose={dropdownClose}
            className="dropdown-wrapper"
          
            target={
                <button
                    //iconAfter={<ChevronDown />}
                    data-testid='dropdown-button'
                    onClick={dropdownOpen}
                    isSelected={isOpen}
                    className="dropdown-button"
                >
                    <span className="selected-text">{dropdownSelectedMessage()}</span>
                    <span className="toggle-icon"><ChevronDown /></span>
                </button>
            }
        >
            <div className="filter-options-group">
                <div className="filter-options-item">
                    <div className="filter-options-item-icon">
                        <Filter size="16" />
                    </div>
                    <div   data-testid='dropdown-clear' className="filter-options-item-text" onClick={onClearAll}>
                        Clear Filter from <span style={{ textTransform: 'uppercase' }}>{props.label}</span>
                    </div>
                </div>
                <div className="filter-options-item">
                    <div className="filter-options-item-icon">
                        <CheckSquare size="16" />
                    </div>
                    <div className="filter-options-item-text" onClick={onSelectAll}>
                        Select All from  <span style={{ textTransform: 'uppercase' }}>{props.label}</span>
                    </div>
                </div>

            </div>
            <div>
                <div class="search-select__control">
                    <div class="search-select__value-container">
                        <div class="multi-select__input" style={{ display: 'inline-block' }}>
                            <input
                                id="search-select-input" autocapitalize="none" autocomplete="off" autocorrect="off"
                                spellcheck="false" tabindex="0" type="text" aria-autocomplete="list"
                                placeholder="Search..."
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div class="multi-select__indicators">
                        <div style={{ color: 'rgb(204, 204, 204)', height: '24px', width: '32px' }}><svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fill-rule="evenodd"></path></svg></div>
                    </div>
                </div>
            </div>
            <Select
                //style select
                className="multi-select-container"
                classNamePrefix="multi-select"
                styles={selectStyles}
                placeholder="Search..."

                //dropdown behaviour change
                menuIsOpen
                autoFocus
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                isMulti

                //custom components
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Option,
                    NoOptionsMessage,
                    MenuList,
                    LoadingMessage,
                }}

                //custom functions and data load
                options={searchData.length ? searchData : data}
                value={optionSelected}
                isLoading={isLoading || isSearching}
                // onMenuScrollToBottom=
               onScroll= {onMenuScrollToBottom}
               
                onChange={onChange}

            />
        </Dropdown>
    )
}
const mapStateToProps =(state)=>{
    console.log("sate",state)
    return {
        filterData:state.filterReducer.filterInitialData
    }
}



export default connect(mapStateToProps )(MultiSelect)



//custom select components 

const LoadingMessage = props => {
    let loadingElement = [];
    for (let i = 0; i < 10; i++) {
        loadingElement.push(
            <div className="multi-select__option" style={{ padding: ' 8px 12px' }}>
                <div class="checkbox">
                    <input type="checkbox"
                        checked=''
                        onChange={() => null} />
                    <label for="checkbox"><span>Loading...</span></label>
                </div>
            </div>
        )
    }
    return (
        <>
            {loadingElement}
        </>
    );
};


const MenuList = (props) => {
    let loadingElement = [];
    loadingElement.push(props.children)
    if (props.isLoading) {
        for (let i = 0; i < 4; i++) {
            loadingElement.push(
                <div className="multi-select__option" style={{ padding: ' 8px 12px' }}>
                    <div class="checkbox">
                        <input type="checkbox"
                            checked=''
                            onChange={() => null} />
                        <label for="checkbox"><span>Loading...</span></label>
                    </div>
                </div>
            )
        }
    }


    return (
        <div className="ScorllCheck" onScroll={(e)=>{
            let element = e.target
                if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                    // do something at end of scroll
                  props.selectProps.onScroll();
                }
                }}> 
                    <components.MenuList  className="menulistScroll" {...props}>
            {loadingElement}
        </components.MenuList>
        </div>
    )
}

const Option = props => {
    return (
        <components.Option {...props} className="loading" >
            <div class="checkbox">
                <input type="checkbox"
                     data-testid={`${props.label}select`}
                    checked={props.isSelected}
                    onChange={() => null} />
                <label   for="checkbox"><span data-testid={props.label} >{props.label}</span></label>
            </div>
        </components.Option>
    );
};

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props} >
            <div>
                <div>
                    No Data
                    </div>
                <Inbox size={32} />
            </div>
        </components.NoOptionsMessage>
    );
};

// component to create dropdown dropdown
const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: 'absolute',
                zIndex: 2,
            }}
            {...props}
        />
    );
};

const Blanket = props => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
        }}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose, className }) => (
    <div style={{ position: 'relative' }} className={className}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
const Svg = p => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);
const DropdownIndicator = () => (
    <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);
const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
        <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </Svg>
);