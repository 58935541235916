import React, { useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import './style.scss';
import {
  downloadErrorReport,
  fetchDashboardData,
  handleShowModal,
  handlePageNo,
} from '../../actions/dashboardActions';
import {
  toggleView
} from '../../actions/attributeSmartActions'
import Table from './components/DashboardTable';
import { Link, useHistory } from 'react-router-dom';
import { Container } from '../../../../commonComponents/viewUtils';
import HeaderBreadCrumbs from '../../../../Utils/HeaderBreadCrumbs';
import HomeIcon from '@material-ui/icons/Home';
import { Box, Typography } from '@material-ui/core';
import ExportButton from './components/ExportButton';
import DeleteButton from './components/DeleteButton';
import ErrorButton from './components/ErrorButton';
import UploadButton from './components/UploadButton';
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { green, amber, grey } from '@material-ui/core/colors'
import ArrowTooltips from '../../../../Utils/ArrowTooltips';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  alert: {
    fontSize: 10,
    padding: '0 4px',
    '& .MuiAlert-icon': {
      padding: '5px 0',
      marginRight: 5,
      marginLeft: 5,
      '& svg': {
        width: 18,
        height: 18
      }
    },
    '& .alert-text': {
      textTransform: 'uppercase',
      padding: 8
    }
  },
  processed: {
    color: green[600],
    marginLeft: 4,
    // position: 'absolute',
    top: 0,
    height: 14,
    width: 14
  }
  ,
  inprogress: {
    color: amber[900],
    marginLeft: 4,
    // position: 'absolute',
    top: 0,
    height: 14,
    width: 14
  },
  batchDisable: {
    color: grey[700],
    cursor: 'default',
    position: 'relative',
    '&:hover': {
      cursor: 'default',
      textDecoration: 'none'
    },
    '& > *:hover': {
      cursor: 'default',

    }
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const handleDownloadAttributes = (row) =>
  !(row.batch_status === 1 && row.total_image_tagged);

const handleDownloadErrorReport = (row) =>
(
  row.batch_status === 1 &&
  (row.total_images !== row.total_images_uploaded ||
    row.total_images !== row.total_image_tagged || row.total_products_uploaded !== row.total_products_tagged)
);



const Dashboard = (props) => {
  const classes = useStyles()
  const fetchIdRef = React.useRef(0);
  const history = useHistory()
  const columns = useMemo(
    () => [
      {
        Header: () => <>Batch Name</>,
        accessor: 'batch_name',
        // disableSortBy: true,
        Cell: (row) => {
          return (
            <div className="table__batchname">
              <div className="table__batchname--body">
                <BatchCell row={row} classes={classes} setReviewed={props.toggleView} />

              </div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            Products
            Uploaded
          </>
        ),
        accessor: 'total_products_uploaded',
        // disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Products
            Tagged
          </>
        ),
        accessor: 'total_products_tagged',
        // disableSortBy: true,
        Cell: (row) => {
          const warning = handleDownloadErrorReport(row.row.original)
          return (
            <div className="table__images_tagged" style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>

              <Box className='table__images_tagged_text' component='span' position='relative'>
                {warning ?
                  <ErrorButton
                    rowId={row.row.original.id}
                    failedCount={
                      row.row.original.total_images -
                      row.row.original.total_products_tagged

                    }
                    batchName={row.row.original.batch_name}
                  />
                  :
                  ''
                }
                {row.row.values.total_products_tagged}
              </Box>


            </div>
          )
        }
      },
      {
        Header: () => (
          <>
            Tags
            Generated
          </>
        ),
        accessor: 'total_product_tags_generated',
        // disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Tags
            Edited
          </>
        ),
        accessor: 'total_product_tags_edited',
        // disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Tags
            Reviewed
          </>
        ),
        accessor: 'total_product_tags_reviewed',
        // disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Products
            For Review
          </>
        ),
        accessor: 'total_products_suggested_for_review',
        //disableSortBy: true,
        Cell: (row) => {
          return (
            <div>
              <ProductsForReviewCell row={row} setReviewed={props.toggleView} />
            </div>
          )
        },
      },
      {
        Header: () => (
          <>
            Products
            For Finalization
          </>
        ),
        accessor: 'total_products_suggested_for_finalization',
        //disableSortBy: true,
        Cell: (row) => {
          return (
            <div>
              <ProductsForFinalizationCell row={row} setReviewed={props.toggleView} />
            </div>
          )
        },
      },
      {
        Header: () => <>Actions</>,
        accessor: 'actions',
        disableSortBy: true,
        Cell: (row) => {
          return (
            <div id={'actionIndex' + row.row.index} className="table__actions">
              <ExportButton
                rowId={row.row.original.id}
                disabled={handleDownloadAttributes(row.row.original)}
                color='primary'


              />
              <DeleteButton
                rowId={row.row.original.id}
                //onDelete={handleDeleteModal}
                color="secondary"
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const fetchData = (index, refresh = 'false', search = '', ordering = '') => {
    props.handlePageNo(index);
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      props.fetchDashboardData(index, refresh, search.trim(), ordering);
    }
  };

  const headerOptions = {
    headerTitle: (
      <HeaderBreadCrumbs
        options={[
          {
            label: 'Dashboard',
            id: 'dashboard_bc',
            icon: <HomeIcon fontSize="small" />,
            action: () =>
              // history.push(`/attributesmart/dashboard/`)
              props.handlePageNo(0)
          }
        ]}
      />
    ),
    headerActions: [
      <UploadButton
        onSuccess={props.fetchDashboardData}
        onPageChange={props.handlePageNo}
        pageNo={props.payload.pageNo}
      />
    ],
    className: '',
  };

  return (
    <Container
      className="dashboard"
      id="dashboard"
      headerOptions={headerOptions}
    >
      <Table
        isLoading={props.payload.showLoader}
        columns={columns}
        data={props.payload.data}
        totalCount={props.payload.count}
        pageCount={Math.ceil(props.payload.count / 10)}
        fetchData={fetchData}
        handlePageNo={props.handlePageNo}
        pageNo={props.payload.pageNo}
        status={
          <StatusMessage
            message={props.payload.status}
            className={classes.alert}
          />
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  payload: state.dashboardReducer,
});

export default connect(mapStateToProps, {
  fetchDashboardData,
  handleShowModal,
  downloadErrorReport,
  handlePageNo,
  toggleView
})(Dashboard);

const StatusMessage = ({ message, className }) => {
  if (message?.type) {

    return (
      <Alert className={className} severity={message?.type}>
        <span className='alert-text'>{message?.text}</span>
      </Alert>
    )
  }

  return ''
}

const BatchCell = ({ classes, row, setReviewed }) => {

  if (row.row.original.batch_status === 0) {
    return (
      <>
        <ArrowTooltips title='Batch is under process, please try again later' placement='right-end'>

          <span className={classes.batchDisable}>
            <Typography variant="caption" className='batchname' color="text-secondary"  >
              {row.row.values.batch_name}
              <AccessTimeRoundedIcon fontSize='small' className={classes.inprogress} />
            </Typography>
          </span>
        </ArrowTooltips>
        <Typography variant="caption" className='created-on' display="block" color="textSecondary">
          Created On:{' '} {moment(row.row.original.created_on).format('MM/DD/YYYY')}
        </Typography>
      </>
    )
  }
  return (
    <>

      <Link onClick={() => setReviewed(0)} to={`/attributesmart/dashboard/${row.row.original.id}`}
        className={clsx({
          [classes.batchDisable]: row.row.original.batch_status === 0
        })}
      >

        <Typography variant="caption" className='batchname' color="primary"  >
          {row.row.values.batch_name}
          <DoneAllRoundedIcon fontSize='small' className={classes.processed} />
        </Typography>


      </Link>
      <Typography variant="caption" className='created-on' display="block" color="textSecondary">
        Created On:{' '} {moment(row.row.original.created_on).format('MM/DD/YYYY')}
      </Typography>
    </>

  )
}

const ProductsForReviewCell = ({ row, setReviewed }) => {
  if (row.row.original.batch_status === 0) {
    return (
      <div>
        {row.row.original.total_products_suggested_for_review}
      </div>
    )}
  return (
    <Link onClick={() => setReviewed(1)} to={`/attributesmart/dashboard/${row.row.original.id}`}>
      {row.row.original.total_products_suggested_for_review}
    </Link>
  )
}

const ProductsForFinalizationCell = ({ row, setReviewed }) => {
  if (row.row.original.batch_status === 0) {
    return (
      <div>
        {row.row.original.total_products_suggested_for_finalization}
      </div>
    )}
  return (
    <Link onClick={() => setReviewed(2)} to={`/attributesmart/dashboard/${row.row.original.id}`}>
      {row.row.original.total_products_suggested_for_finalization}
    </Link>
  )
}