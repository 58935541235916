import React from "react";
import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getValueForKey, handleReplace } from "./helper";
import Select from "./Select";

const AttributeDropdown = ({ attributes, dropdownData }) => {
  useLocation();
  const history = useHistory();

  const handleValue = () => {
    let selected = "";
    if (getValueForKey("type") === "true" && getValueForKey("variantId")) {
      selected = {
        label: attributes[getValueForKey("variantId")]?.name,
        value: getValueForKey("variantId"),
      };
    }
    if (getValueForKey("type") !== "true" && getValueForKey("productId")) {
      selected = {
        label: attributes[getValueForKey("productId")]?.name,
        value: getValueForKey("productId"),
      };
    }
    return selected;
  };

  const onChange = (obj) => {
    handleReplace(
      getValueForKey("type") === "true" ? "variantId" : "productId",
      obj.value || "",
      history
    );
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="caption" style={{ marginRight: 5 }}>
        Edit Attribute:
      </Typography>
      <Select
        noStyleUpdate
        options={dropdownData?.map((el) => ({
          label: attributes[el]?.name,
          value: el,
        }))}
        menuPortalTarget={document.querySelector("body")}
        id="exCatSelectProductAttr"
        name="product-attribute"
        value={handleValue()}
        onChange={onChange}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  attributes: state.attributeSmartReducer.attributes,
});

export default connect(mapStateToProps)(AttributeDropdown);
