import React, { useEffect } from 'react';
import { Data } from './statusOptions';
import StatusFilter from './statusFilter';
import './index.css';

const StatusFilterComponent = (props) => {
  useEffect(() => {
    //API CALL TO fetch the notification Types
  }, []);
  return (
    <>
      <div id="statusFilterDiv" className="status-filter-div">
        <label>Status</label>
        <StatusFilter {...props} isMulti={false} options={Data} />
      </div>
    </>
  );
};

export default StatusFilterComponent;
