const COLUMNS = [
  {
    Header: 'Notification ID',
    accessor: 'id',
    disableSortBy :true
  },
  {
    Header: 'Notification Type',
    accessor: 'notificationtype',
    disableSortBy :true
  },
  {
    Header: 'Notification',
    accessor: 'notification',
    disableSortBy :true
  },
  {
    Header: 'Active Status',
    accessor: 'active_status',
    disableSortBy :true
  },
  {
    Header: 'Read Status',
    accessor: 'read_status',
    disableSortBy :true
  },
  
];

export default COLUMNS;
