export const Data = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Deleted',
    value: 'deleted',
  },
  {
    label: 'Read',
    value: 'read',
  },
  {
    label: 'Unread',
    value: 'unread',
  },
];
