import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
  root: {
    width: 177,
  },
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
];

function valuetext(value) {
  return `${value}*100`;
}

const FilterSlider = ({
  range,
  onUpdate,
  parentId,
  history,
  confidence,
  initialMinVal,
  initialMaxVal,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(range);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommit = (event, newValue) => {
    onUpdate(parentId, newValue, history);
  };

  useEffect(() => {
    setValue([initialMinVal,initialMaxVal])
  }, [initialMinVal, initialMaxVal, confidence]);

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommit}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={marks}
      />
    </div>
  );
};

export default FilterSlider;
