import {
  SHOW_MODAL,
  SHOW_LOADER,
  SET_PAGE,
  SHOW_LOADER_CLOSE_MODAL,
  DELETE_INIT,
  DELETE_ERROR,
  DELETE_SUCCESS,
  DASHBOARD_INIT,
  DASHBOARD_ERROR,
  DASHBOARD_SUCCESS,
  DOWNLOAD_REPORT_INIT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  DOWNLOAD_ERROR_REPORT_INIT,
  DOWNLOAD_ERROR_REPORT_SUCCESS,
  DOWNLOAD_ERROR_REPORT_ERROR,
  SET_QUERY_PARAMS
} from '../actions/types';

const initialState = {
  data: [],
  count: 1,
  next: null,
  previous: null,
  showModal: false,
  showDeleteModal: false,
  showLoader: false,
  batchToDelete: null,
  pageNo: 0,
  isDeleting: false,
  isDownloading: false,
  isDownloadingError:false,
  id:null,
  status:{},
  queryParams:{}
};

const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        pageNo: action.payload,
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };
    case SHOW_LOADER_CLOSE_MODAL:
      return {
        ...state,
        showLoader: true,
        showDeleteModal: false,
        batchToDelete: null,
      };

    //Dashboard Table Load
    case DASHBOARD_INIT:
      return {
        ...state,
        showLoader: true,
        status: action.payload.status
      }
    case DASHBOARD_ERROR:
      return {
        ...state,
        showLoader: false,
        status: action.payload.status
      }
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        showLoader: false,
        pageNo: state.pageNo,
        showModal: false,
        status: action.payload.status
      };

    //Delete Batch
    case DELETE_INIT:
      return {
        ...state,
        isDeleting: true,
        status: action.payload.status,
        id:action.payload.id
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        status: action.payload.status,
        id:action.payload.id
      };
    case DELETE_ERROR:
      return {
        ...state,
        showLoader: false,
        status: action.payload.status,
        id:action.payload.id
      };

    //Download Report
    case DOWNLOAD_REPORT_INIT:
      return {
        ...state,
        isDownloading: true,
        status: action.payload.status,
        id:action.payload.id
      }
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        status: action.payload.status,
        id:null
      }
    case DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        isDownloading: false,
        status: action.payload.status,
        id:action.payload.id
      }

          //Download Error Report
    case DOWNLOAD_ERROR_REPORT_INIT:
      return {
        ...state,
        isDownloadingError: true,
        status: action.payload.status,
        id:action.payload.id
      }
    case DOWNLOAD_ERROR_REPORT_SUCCESS:
      return {
        ...state,
        isDownloadingError: false,
        status: action.payload.status,
        id:null
      }
    case DOWNLOAD_ERROR_REPORT_ERROR:
      return {
        ...state,
        isDownloadingError: false,
        status: action.payload.status,
        id:action.payload.id
      }
    //Query Params
    case SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload
      }
    default:
      return state;
  }
};

export default dashboardReducer;
