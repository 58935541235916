import React from 'react';
import Loader from 'react-loading-overlay';
import './loader.css';

const LoadingOverlay = ({ loader, color, children }) => {
  return (
    <Loader
      active={loader}
      spinner
      text="Loading..."
      styles={{
        overlay: (base) => ({
          ...base,
          // background: 'rgba(255, 255, 255, 0.89)',
          background: 'rgba(0,0,0,0.1)',
          borderRadius: '0px',
        }),
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: color ? color : '#44677b',
          },
        }),
        content: (base) => ({
          ...base,
          color: color ? color : '#44677b',
        }),
      }}>
      {children}
    </Loader>
  );
};

export default LoadingOverlay;