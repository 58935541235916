import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { SideBar } from '../../commonComponents/sidebar';
import Header from '../../commonComponents/header';
import Dashboard from './views/dashboard';
import { connect } from 'react-redux';
import { Explore, ProductDetail, ExploreCategory } from './views/explore';
import NotFound from '../../commonComponents/notFound/NotFound';
import { Book } from 'react-feather';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { APP_ATTRIBUTE_SMART } from '../../config/constants';
//Added loader on top of header to indicate token verification
import { ProtectedRoute } from '../../commonComponents/layout/ProtectedRoute';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const sideBarOptions = [
  {
    link: '/dashboard',
    icon: <DashboardIcon color="white" size="24" />,
    title: 'Dashboard',
  },
];
const useStyles = makeStyles((theme) => ({

  wrapper:{
    display:'flex',
    flexDirection:'row'
  },
    layout:{
      display:'flex',
     flex:1,
     flexDirection:'column',
     minHeight:'100vh',
     background:'#f9f9f9',
    },

}))


const AttributeSmart = (props) => {
  const { path } = props.match;
  let token = props.authReducer ? props.authReducer.isAuthenticated : false;
  token = !token ? localStorage.getItem('token') : token;
  const classes = useStyles();

  useEffect(() => {
    document.title = APP_ATTRIBUTE_SMART.APP_NAME;
  }, []);

  return (
    <div className="attribute-smart">
      <div className={classes.wrapper} >

      <SideBar options={sideBarOptions} pathPrefix={`${path}`} />
      <div className={classes.layout}>

      <Header title={APP_ATTRIBUTE_SMART.APP_NAME} />
      <div  className={classes.content}>
      <Switch>
        <ProtectedRoute
          exact
          path={`${path}/dashboard`}
          isAuthenticated={props.authReducer.isAuthenticated}
          isTokenVerified={props.authReducer.isTokenVerified}
          component={Dashboard}
          auth={token}
        />
        <ProtectedRoute
          exact
          path={`${path}/dashboard/:batch`}
          isAuthenticated={props.authReducer.isAuthenticated}
          isTokenVerified={props.authReducer.isTokenVerified}
          component={Explore}
          auth={token}
        />
        <ProtectedRoute
          exact
          path={`${path}/dashboard/:batch/:l1/:l2/:mode`}
          isAuthenticated={props.authReducer.isAuthenticated}
          isTokenVerified={props.authReducer.isTokenVerified}
          component={ExploreCategory}
          auth={token}
        />
        <ProtectedRoute
          exact
          path={`${path}/dashboard/:batch/:l1/:l2/view/:productId`}
          isAuthenticated={props.authReducer.isAuthenticated}
          isTokenVerified={props.authReducer.isTokenVerified}
          component={ProductDetail}
          auth={token}
        />
        <Redirect exact from={`${path}`} to={`${path}/dashboard`} />
        <ProtectedRoute
          exact
          path={`${path}/*`}
          isAuthenticated={props.authReducer.isAuthenticated}
          isTokenVerified={props.authReducer.isTokenVerified}
          component={NotFound}
          auth={token}
        />
      </Switch>
   </div>
    </div>
    </div>
    </div>


  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errors: state.errors,
  success: state.success,
});

export default connect(mapStateToProps)(withRouter(AttributeSmart));
