import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CustomCheckBox from "./CustomCheckBox";
import FilterSlider from "./FilterSlider";
import {
  keyHasValue,
  getValueForKey,
  handleAppend,
  handleReplace,
} from "./helper";

const CategoryFilter = ({
  parentLabel,
  isVariant,
  parentId,
  values = [],
  type,
  confidence,
  filtersOpen = false,
}) => {
  const [open, setOpen] = useState(filtersOpen);
  const history = useHistory();

  return (
    <div>
      <FilterToggle
        id={`expCatDiv${parentLabel}`}
        open={open}
        onClick={() => setOpen(!open)}
      >
        <FilterLabel>{parentLabel}</FilterLabel>
        <ToggleArrow open={open} className={`fa fa-chevron-right`} />
      </FilterToggle>

      <FilterContainer open={open}>
        {type === "range" ? (
          <RangeSliderContainer open={open}>
            <FilterSlider
              range={[values[0] * 100, values[1] * 100]}
              onUpdate={handleReplace}
              parentId={parentId}
              history={history}
              confidence={confidence}
              initialMinVal={
                Number(getValueForKey(parentId)?.split(",")[0]) ||
                values[0] * 100
              }
              initialMaxVal={
                Number(getValueForKey(parentId)?.split(",")[1]) ||
                values[1] * 100
              }
            />
          </RangeSliderContainer>
        ) : (
          values.map(({ id, label, count }) => (
            <CustomCheckBox
              checked={keyHasValue(parentId, label) ? true : false}
              onChange={() => handleAppend(parentId, label, history, isVariant)}
              key={id + label}
              label={parentId}
              value={label}
              count={count}
            />
          ))
        )}
      </FilterContainer>
    </div>
  );
};

export default CategoryFilter;

const FilterContainer = styled.div`
  border-radius: 4px;
  padding: ${({ open }) => (open ? "0 16px 4px 16px" : "0 16px")};
  max-height: ${({ open }) => (open ? "none" : "0")};
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`;

const FilterToggle = styled.div`
  cursor: pointer;
  border-top: 1px solid #cec9c957;
  font-size: 13px;
  font-weight: 100;
  padding: 13px 8px;
  padding-bottom: ${({ open }) => (open ? "0px" : "13px")};
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const ToggleArrow = styled.i`
  font-size: 10px;
  font-weight: inherit;
  padding: 7px;
  cursor: pointer;
  -moz-transition: transform 150ms linear;
  -webkit-transition: transform 150ms linear;
  transition: transform 150ms linear;
  -moz-transform: ${({ open }) => (open ? "rotate(-90deg)" : "rotate(90deg)")};
  -webkit-transform: ${({ open }) =>
    open ? "rotate(-90deg)" : "rotate(90deg)"};
  transform: ${({ open }) => (open ? "rotate(-90deg)" : "rotate(90deg)")};
`;

const FilterLabel = styled.span`
  font-size: 0.8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.0666em;
  text-transform: uppercase;
`;

const RangeSliderContainer = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  padding-bottom: 30px;
  width: min-content;
`;
