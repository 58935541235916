import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import SummaryContainer from "./components/SummaryContainer";
import {
  AS_DASHBOARD_EXPORT,
  AS_EXPLORE_SUMMARY,
} from "../../../../config/api";
import ShowToast from "../../../../commonComponents/toast";
import LoadingOverlay from "../../../../commonComponents/loadingOverlay";
import { Container } from "../../../../commonComponents/viewUtils";
import axiosInstance from "../../../../Utils/axios";
import ExportButton from "./components/ExportButton";
import HeaderBreadCrumbs from "../../../../Utils/HeaderBreadCrumbs";
import HomeIcon from "@material-ui/icons/Home";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { toggleView } from "../../actions/attributeSmartActions";

const Explore = (props) => {
  const { batch } = useParams();
  const history = useHistory();

  const [categories, setcategories] = useState(null);
  const [isloading, setisloading] = useState(false);
  const [batchName, setbatchName] = useState(null);

  const init = (paramBatch, paramCategory) => {
    setbatchName(paramBatch);
    setcategories(paramCategory);
  };

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      init(null, null);
      setisloading(true);
      try {
        const result = await axiosInstance.get(AS_EXPLORE_SUMMARY, {
          params: {
            batch_id: batch,
            view: props.view,
          },
        });

        if (!didCancel) {
          init(result.data.data.batch_name, result.data.data.categories);
          setisloading(false);
        }
      } catch (error) {
        if (!didCancel) {
          setisloading(false);
          if (error?.response?.data?.data) {
            ShowToast(error.response.data.data, "error");
          } else {
            ShowToast("Something Went Wrong", "error");
          }
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [batch, props.view]);

  const onExport = async (type) => {
    setisloading(true);
    try {
      const response = await axiosInstance.get(
        `${AS_DASHBOARD_EXPORT}${batch}`,
        {
          params: {
            export_type: type,
          },
        }
      );

      if (response?.data?.data?.spreadsheet_url) {
        setisloading(false);
        let a = document.createElement("a");
        a.href = response.data.data.spreadsheet_url;
        a.click();
      }
    } catch (error) {
      setisloading(false);
      ShowToast("Something went wrong", "error");
    }
  };

  const headerOptions = {
    // headerTitle: `Explore: ${isloading ? '' : batchName}`,
    headerTitle: (
      <HeaderBreadCrumbs
        options={[
          {
            label: "Dashboard",
            id: "dashboard_bc",
            icon: <HomeIcon fontSize="small" />,
            action: () => history.push(`/attributesmart/dashboard/`),
          },
          {
            label: `Explore: ${isloading ? "" : batchName}`,
            id: "explore_bc",
            action: () => history.push(`/attributesmart/dashboard/${batch}`),
          },
        ]}
      />
    ),
    headerActions: [
      // <ExportBtn key={1} id="exportBtn" onExport={onExport} />
      <Box display="flex" alignItems="center">
        <ReviewedSwitch
          status={props.view}
          toggle={props.toggleView}
        />
        <ExportButton onExport={onExport} />
      </Box>,
    ],
  };

  return (
    <React.Fragment>
      <LoadingOverlay active={isloading} />
      <Container
        style={{ fontSize: "0.725rem" }}
        id="explore"
        headerOptions={headerOptions}
      >
        <SummaryContainer
          batch={batch}
          categories={categories}
        ></SummaryContainer>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  view: state.attributeSmartReducer.view,
});

export default connect(mapStateToProps, {
  toggleView,
})(Explore);


const ReviewedSwitch = ({ status, toggle }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleClose = (event, param) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    toggle(param)
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const reviewText = (params) => {
    if (params === 1) {
      return "Products for Review";
    } else if (params === 2) {
      return "Products for Finalization";
    }
    return "All Products";
  };

  return (
    <Box mx={2}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ background: '#f3f1f1' }}
        id={"exploreReviewedBtn"}

      >
        {reviewText(status)}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={(event) => handleClose(event, 0)}>All Products</MenuItem>
                  <MenuItem onClick={(event) => handleClose(event, 1)}>Products for Review</MenuItem>
                  <MenuItem onClick={(event) => handleClose(event, 2)}>Products for Finalization</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
