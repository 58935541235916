import React from "react";
import { Button } from "@material-ui/core";

const ProductDetailButton = ({
  label,
  onClickHandler,
  type,
  loading = false,
  id,
}) => (
  <Button
    variant="contained"
    size="small"
    color="primary"
    style={{
      minWidth: 120,
      minHeight: 40,
      backgroundColor: type === 0 ? "#00000003" : "#3f51b5",
      color: type === 0 ? "#3f51b5" : "white",
      pointerEvents: loading ? "none" : "all",
    }}
    id={id}
    onClick={onClickHandler}
  >
    {label}
  </Button>
);

export default ProductDetailButton;
