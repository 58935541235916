import React, { useState } from 'react'
import { connect } from 'react-redux'
import Loader from '../../../Utils/Loader/loader'
import FormTable from '../components/form-table'
import { getStates } from "../../../actions/workflowActions"
import { STATES } from '../../../config/api';
import axiosInstance from '../../../Utils/axios'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function States(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [states, setStates] = useState([...props.states])

  const saveRequest = async (rowdata) => {
    try {
      setIsLoading(true)
      await axiosInstance({
        url: STATES,
        method: 'POST',
        data: {
          state_list: rowdata,
        }
      })
      const { data } = await axiosInstance({
        url: STATES,
        method: 'GET',
      })
      props.setStatesData(data.data.results);
      setStates(data.data.results)
      setOpenMessage(true)
      setIsLoading(false)

      return { status: true };
    } catch (err) {
      if (err.response && !err.response.data.status) {
        setIsLoading(false)
        return { status: false, message: err.response.data.data };
      }
      setIsLoading(false)
    }
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false)

  }

  return (
    <Loader loader={isLoading} >
      <div>
        <FormTable
          form={[{ label: 'Name', type: 'textfield', name: 'name', showValidationonBlur: true },
          { label: 'Description', type: 'textfield', name: 'description' }]}

          columns={[{ label: 'Name', key: 'label' }, { label: 'Description', key: 'description' }]}

          saveRequest={saveRequest} title='States' rowData={states} />
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openMessage} autoHideDuration={2000} onClose={handleClose} >
          <Alert severity="success">
            State saved successfully.
     </Alert>
        </Snackbar>
      </div>
    </Loader>
  )

}

const mapStateToProps = (state) => {
  return ({
    states: state.workflowReducer.states
  })
};

const mapActionsToProps = (dispatch) => {
  return {
    getStates,
    setStatesData: (data) => dispatch({ type: 'GET_STATES', payload: data }),
  }

};
export default connect(mapStateToProps, mapActionsToProps)(States)
