import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { range } from "lodash";
import { Skeleton } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import L1L2Button from "./L1L2Button";

const ImageSkeleton = ({ id, count }) => {
  return (
    <SkeletonContainer>
      {range(count).map((i) => (
        <Card id={id + i} key={i}>
          <div className="card__image loading"></div>
          <div className="card__title loading"></div>
          <div className="card__description loading"></div>
        </Card>
      ))}
    </SkeletonContainer>
  );
};

ImageSkeleton.propTypes = {
  id: PropTypes.string,
  count: PropTypes.number,
};

ImageSkeleton.defaultProps = {
  id: "cardSkeleton",
  count: 1,
};

export default ImageSkeleton;

const SkeletonContainer = styled.div`
  display: inline-flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
`;

const Card = styled.div`
  background-color: #fff;
  height: auto;
  width: 220px;
  min-width: 220px;
  overflow: hidden;
  margin: 12px;
  border-radius: 5px;
  box-shadow: 9px 17px 45px -29px rgb(0 0 0 / 44%);

  card__image img {
    width: 100%;
    height: 100%;
  }

  .card__image.loading {
    height: 160px;
    width: 100%;
  }

  /* Card title */
  .card__title {
    padding: 8px;
    font-size: 22px;
    font-weight: 700;
  }

  .card__title.loading {
    height: 1rem;
    width: 50%;
    margin: 1rem;
    border-radius: 3px;
  }

  /* Card description */
  .card__description {
    padding: 8px;
    font-size: 16px;
  }

  .card__description.loading {
    height: 3rem;
    margin: 1rem;
    border-radius: 3px;
  }

  /* The loading Class */
  .loading {
    position: relative;
    background-color: #e2e2e2;
  }

  /* The moving element */
  .loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent)
    );

    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );

    /* Adding animation */
    animation: loading 0.8s infinite;
  }

  /* Loading Animation */
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
  margin: 50px;
  max-height: 300px;
  min-height: 300px;
  min-width: 180px;
  max-width: 180px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 350px;
  width: 200px;
  display: flex;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px;
`;

export const ListViewSkeleton = ({ useStyles }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div>
        <div
          className={`product-list-view ${classes.headerContainer}`}
          id="ProductHeaderValues"
          style={{ overflow: "hidden" }}
        >
          <Box display="flex" textAlign="center">
            <TableCell
              className={clsx(classes.th, classes.fixedColumnHeader)}
              component="span"
            >
              Product
            </TableCell>
            {Array.from(new Array(3)).map((_, i) => (
              <TableCell
                component="span"
                className={classes.th}
                key={i}
                style={{ padding: "10px 50px" }}
              >
                <Skeleton animation="wave" style={{ height: 32 }} />
              </TableCell>
            ))}
          </Box>
        </div>
        <div className={classes.tableContainer} style={{ overflow: "hidden" }}>
          <Table>
            <TableBody>
              {Array.from(new Array(3)).map((_, i) => (
                <TableRow key={i} className={classes.tr}>
                  <TableCell
                    className={clsx(classes.td, classes.fixedColumn)}
                    style={{ maxWidth: 240 }}
                    key={i}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ImageContainer>
                        <L1L2Button
                          productDetails={{}}
                          resetChangesArray={() => null}
                          changesArr={[]}
                          handleRecategorization={() => null}
                          handleModal={() => null}
                          handleWarning={() => null}
                        />
                        <Skeleton
                          animation="wave"
                          style={{
                            height: "140%",
                            width: "80%",
                            top: "-12px",
                          }}
                        />
                      </ImageContainer>
                    </Box>
                  </TableCell>
                  {Array.from(new Array(3)).map((_i) => (
                    <TableCell
                      className={classes.td}
                      style={{ maxWidth: 240 }}
                      key={i}
                    >
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: 4,
                          minHeight: 38,
                          display: "flex",
                          padding: "0 0 3px 10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          style={{ height: 32, width: "80%" }}
                        />

                        <KeyboardArrowDownIcon />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Paper>
  );
};
