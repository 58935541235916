import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormTable from '../components/form-table'
import Loader from '../../../Utils/Loader/loader'
import FormControl from '@material-ui/core/FormControl';
import { getTranstions } from "../../../actions/workflowActions"
import { TRANSITION } from '../../../config/api';
import axiosInstance from '../../../Utils/axios';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { set } from 'lodash';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
  },

  formControl: {
    margin: theme.spacing(1),
    marginLeft: '0px',
    minWidth: 200
  },
}))

function States(props) {
  const classes = useStyles()
  const [addForm, setAddForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)

  const [statesOption, setStatesOption] = useState([])
  const [transitionData, setTransitionData] = useState([])
  const [openMessage, setOpenMessage] = useState(false);
  const [workflowOption,setWorkflowOption] =useState('')

  useEffect(async () => {
    try {
     
      let getData = props.transitions.map(row => {
        let sourceState = props.states.filter(item => item.id === row.source_state).map(item => item.label)[0];
        let destinationState = props.states.filter(item => item.id === row.destination_state).map(item => item.label)[0];
        return {
          ...row,
          source_state_label: sourceState,
          destination_state_label: destinationState
        }
      })
      setTransitionData(getData)
      setStatesOption(props.states)
      setWorkflowOption(props.workflows[0].id)
      setShowForm(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

    return () => {
      //cleanup
    };
  }, [])

 const onChange=(e)=>{
  setWorkflowOption(e.target.value)
 }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);


  }

  const saveRequest = async (rowdata) => {
    let postData = rowdata.map(item => {
      return {
        source_state_id: item.source_state_id,
        destination_state_id: item.destination_state_id
      }
    })

    try {
      setIsLoading(true)
      await axiosInstance({
        url: TRANSITION,
        method: 'POST',
        data: {
          workflow: workflowOption,
          transitions: postData,
        }
      })
      const { data } = await axiosInstance({
        url: TRANSITION,
        method: 'GET',
      })
      let getData = data.data.results.map(row => {
        let sourceState = props.states.filter(item => item.id === row.source_state).map(item => item.label)[0];
        let destinationState = props.states.filter(item => item.id === row.destination_state).map(item => item.label)[0];
        return {
          ...row,
          source_state_label: sourceState,
          destination_state_label: destinationState
        }
      })
      setTransitionData(getData)
      props.setTransitions(data.data.results)
      setIsLoading(false)
      setOpenMessage(true);
      return { status: true };
    } catch (err) {
      if (err.response && !err.response.data.status) {
        setIsLoading(false)
        return { status: false, message: err.response.data.data };
      }
      setIsLoading(false)

    }


  }


  return (
    <Loader loader={isLoading} >
      {showForm && <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-simple-select-label">Workflow</InputLabel>
          <Select
            className={classes.select}
            id="demo-simple-select"
            label='Name'
            value={workflowOption}
            onChange={onChange}
            inputProps={{
              name: 'age',
              id: 'demo-simple-select-label',
            }}
          >
                   {props.workflows &&  props.workflows.map((option, index) => {
                    return <MenuItem key={index} value={option.id}>{option.field_name}</MenuItem>
                  })}
          </Select>
        </FormControl>
        <FormTable transitions={true}
          optSelect1={statesOption}
          form={[{ label: 'Source State', type: 'select', name: 'source_state_id', options: props.states },
          { label: 'Destination State', type: 'select', name: 'destination_state_id', options: props.states }]}


          optSelect2={statesOption}
          columns={[{ label: 'Source State', key: 'source_state_label' }, { label: 'Destination State', key: 'destination_state_label' }]}
          rowData={[...transitionData]}
          title='Transitions'
          saveRequest={saveRequest}
        ></FormTable>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openMessage} autoHideDuration={2000} onClose={handleClose} >
          <Alert severity="success">
            Transition saved successfully.
     </Alert>
        </Snackbar>

      </div>
      }
    </Loader>
  )
}
const mapStateToProps = (state) => {
  return ({
    states: state.workflowReducer.states,
    transitions:state.workflowReducer.transitions,
    workflows:state.workflowReducer.workflows
  })
};

const mapActionsToProps = (dispatch) => {
  return {
      getTranstions,
      setTransitions: (data) =>dispatch({ type: 'GET_TRANSITIONS', payload: data }),
  }  
}
export default connect(mapStateToProps, mapActionsToProps)(States)