import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SideBar } from "../sidebar";
import Header from "../header";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { APP_PLATFORM, APP_MARKSMART } from "../../config/constants";
import Reporting from "../../pages/reporting";
import Workflow from "../../pages/workflow";
import Admin from "../../pages/admin";
import NotFound from "../notFound/NotFound";
import LoadingOverlay from "../../Utils/Loader/loader";
import layoutActions from "../../actions/layoutActions";
import TenantContainer from "../../pages/tenant-settings-screen/tenant-container";
import NotificationContainer from "../../pages/notifications-panel";
import Ticketing from "../../pages/ticketing";
import posthog from "posthog-js";
import * as Icons from '@material-ui/icons/';
import "./layout.css";

const componentMap = {
  Admin,
  Reporting,
  Tenants: TenantContainer,
  Notifications: NotificationContainer,
  Workflow,
  Ticketing,
};
class Layout extends Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      errorMessage: false,
    };
    props.setLayout(APP_MARKSMART.APP_NAME);
  }

  componentDidMount() {
    //update the title of the application
    document.title = APP_PLATFORM.APP_NAME;
    let token = this.props.authReducer
      ? this.props.authReducer.isAuthenticated
      : false;
    token = !token ? localStorage.getItem("token") : token;
  }

  render() {
    const { path } = this.props.match;
    let token = this.props.authReducer
      ? this.props.authReducer.isAuthenticated
      : false;
    token = !token ? localStorage.getItem("token") : token;
    const sideBarOptions = this.props.sidebarMenu
      .map((menu) => {
        return {
          link: menu.menu_route.route_path,
          title: menu.menu_item_name,
          icon: React.createElement(Icons[menu.menu_icon]),
          order: menu.menu_item_order,
        };
      })
      .sort((a, b) => a.order - b.order);
    // <div className="d-md-flex h-md-100 align-items-center">
    return (
      <LoadingOverlay loader={this.props.overlayLoaderState} spinner>
        <div className="wrapper">
          <SideBar options={sideBarOptions} pathPrefix={`${path}`} />
          <div className="layout">
            <Header title={APP_PLATFORM.APP_NAME} />

            <div className="main-content" id="content">
              {/* <ContainerText /> */}
              <div id="sub-content">
                <Switch>
                  {this.props.routes.map((route) => (
                    <ProtectedRoute
                      path={`${path}${route.route_path}`}
                      auth={token}
                      component={componentMap[route.route_key]}
                      isAuthenticated={this.props.authReducer.isAuthenticated}
                      isTokenVerified={this.props.authReducer.isTokenVerified}
                    />
                  ))}

                  {this.props.layoutFetched && (
                    <ProtectedRoute
                      exact
                      path={`${path}/*`}
                      component={NotFound}
                      auth={token}
                      isAuthenticated={this.props.authReducer.isAuthenticated}
                      isTokenVerified={this.props.authReducer.isTokenVerified}
                    />
                  )}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

Layout.propTypes = {
  loginUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errors: state.errors,
  success: state.success,
  routes: state.layoutReducer.routes,
  sidebarMenu: state.layoutReducer.sidebarMenu,
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  layoutFetched: state.layoutReducer.layoutFetched,
});

const mapActionsToProps = {
  setLayout: layoutActions.setLayout,
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          const allProps = { ...props, ...rest };
          return <Component {...allProps} />;
        } else {
          if (rest.isTokenVerified) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return <LoadingOverlay loader={true} spinner></LoadingOverlay>;
          }
        }
      }}
    />
  );
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Layout));
