import { SET_TENANT_APPS, LOADER, SET_APP_SETTINGS } from './types';
import { toast } from 'react-toastify';
import axiosInstance from '../Utils/axios';

export const setTenantApps = () => (dispatch) => {
  axiosInstance
    .get('/tenant/tenant_settings_choices/')
    .then((res) => {
      let choices = res.data.data;
      let appNames = [];
      choices.app_code_choices.map((app_code, index) => {
        appNames.push({
          id: app_code,
          label: choices.app_label_choices[index],
        });
      });
      dispatch({
        type: SET_TENANT_APPS,
        payload: appNames,
      });
    })
    .catch((error) => {});
};

export const setAppSettings = (appId) => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: {
      status: true,
    },
  });
  axiosInstance
    .get(`/tenant/tenant_settings/?filter_app=${appId}`)
    .then((res) => {
      const app_settings = res.data.data;
      const setting = {
        [appId]: res.data.data,
      };
      dispatch({
        type: SET_APP_SETTINGS,
        payload: app_settings.length > 0 ? setting : {},
      });

      dispatch({
        type: LOADER,
        payload: {
          status: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: LOADER,
        payload: {
          status: false,
        },
      });
    });
};

export const updateAppSettings = (recordObj) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/tenant/tenant_settings/${recordObj.id}/`)
      .then((res) => {
        let setting_record = res.data.data;
        setting_record['value'] = recordObj.value;
        axiosInstance
          .put(`/tenant/tenant_settings/${recordObj.id}/`, setting_record)
          .then((updateResponse) => {
            if (updateResponse.data.status) {
              axiosInstance
                .get(
                  `/tenant/tenant_settings/?filter_app=${updateResponse.data.data.app_code}`
                )
                .then((res) => {
                  const app_settings = res.data.data;
                  const setting = {
                    [updateResponse.data.data.app_code]: res.data.data,
                  };
                  dispatch({
                    type: SET_APP_SETTINGS,
                    payload: app_settings.length > 0 ? setting : {},
                  });
                  resolve(true);
                  toast.success('Value Updated', {
                    autoClose: 15000,
                    hideProgressBar: true,
                  });
                });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.data.detail, {
              autoClose: 15000,
              hideProgressBar: true,
            });
            reject(new Error(error.message));
          });
      })
      .catch((error) => {
        toast.error(error.response.data.detail, {
          autoClose: 15000,
          hideProgressBar: true,
        });
        reject(new Error(error.message));
      });
  });
};
