import {
  LOGOUT_CURRENT_USER,
  SET_CURRENT_USER,
  SET_TENANT_ID,
} from '../actions/types';

const initialState = {
  isTokenVerified: false,
  isAuthenticated: false,
  tenantId: null,
  user: {},
  landingPage: '/home'
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isTokenVerified: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        landingPage: action.payload.landingPage
      };
    case LOGOUT_CURRENT_USER:
      return {
        ...state,
        isTokenVerified: false,
        isAuthenticated: false,
        user: {},
      };
    case SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload,
      };
    default:
      return state;
  }
}
