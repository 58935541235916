import React, {useState, useEffect} from 'react';
import { GET_TRANSITION_APPROVALS } from '../../../actions/types';
import Loader from '../../../Utils/Loader/loader';
import { getTranstionApprovals } from "../../../actions/workflowActions";
import { connect } from 'react-redux';
import { TRANSITIONAPPROVAL } from '../../../config/api';
import axiosInstance from '../../../Utils/axios';
import FormTable from '../components/form-table'
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, Snackbar } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
    },
    formControl: {
      display: "flex",
      flexFlow: "row",
      margin: theme.spacing(1),
      minWidth: 400,
    },
    form: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    select: {
      minWidth: 350,
    },
}))
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const TransitionApprovals = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [transtionApprovalData, setTranstionApprovalData] = useState([]);
    const [groupOption, setGroupOption] = useState([]);
    const [transitionOption, setTransitionOption] = useState();
    const [openMessage, setOpenMessage] = useState(false);
    const [transition, setTransition] = useState();
    const [message, setmessage] = useState({message:"", severity:"success"});

    useEffect(async ()=>{
      let getData = props.transitions.map(row => {
            let sourceState = props.states.filter(item => item.id === row.source_state).map(item => item.label)[0];
            let destinationState = props.states.filter(item => item.id === row.destination_state).map(item => item.label)[0];
            let workflowName = props.workflows.filter(item => item.id === row.workflow).map(item => item.field_name)[0];
            return(
              {
                ...row,
                state_label: `Workflow - ${workflowName}, \n Transition - ${sourceState} -> ${destinationState}`,
              }
            ) 
          })
      setTransitionOption(getData)
      let tableData = props.transitionapprovals.map(row => {
        let transitionName = getData.filter(item => item.id === row.id).map(item => item.state_label)[0];
        let groupName = row.groups.map(g=>props.groups.filter(item => item.id ===g).map(item => item.name)).join(', ')
        return {
            ...row,
            transtion_name: transitionName,
            group_name: groupName
        }
      })
      setTranstionApprovalData(tableData)  
      setIsLoading(false)
    },[])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenMessage(false);
    }

    const saveRequest = async () => {
      if (transition && groupOption){
          console.log(groupOption)
          try {
            setIsLoading(true)
            await axiosInstance({
            url: TRANSITIONAPPROVAL,
            method: 'POST',
            data:{
                transition_meta:transition,
                groups:groupOption,
                }
            })
            const { data } = await axiosInstance({
                url: TRANSITIONAPPROVAL,
                method: 'GET',
            })
            let tableData = data.data.results.map(row => {
                let transitionName = transitionOption.filter(item => item.id === row.id).map(item => item.state_label)[0];
                let groupName = row.groups.map(g=>props.groups.filter(item => item.id ===g).map(item => item.name)).join(', ')
                return {
                    ...row,
                    transtion_name: transitionName,
                    group_name: groupName
                }
            })
            setGroupOption([])
            setTranstionApprovalData(tableData)
            props.setTranstionApprovals(data.data.results)
            setIsLoading(false)
            setOpenMessage(true);
            setmessage({message: "Transition  mapped with Groups successfully.", severity:"success"});
            return { status: true };
          } catch (err) {
            if (err.response && !err.response.data.status) {
                setIsLoading(false)
                setOpenMessage(true);
                setmessage({message: err.response.data.data, severity:"error"});
                return { status: false, message: err.response.data.data };
            }
            setIsLoading(false)
          }
        } else {
        setOpenMessage(true);
        setmessage({message: "Both Transition and Group is required field", severity:"error"});
      }  
    };

    return (
        <Loader loader={isLoading}>
          {transitionOption&&<div>
                <Typography className={classes.heading}>Create Transition Approvals</Typography>
                  <form noValidate className={classes.form} autoComplete="off">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="open-select-label">Transitions</InputLabel>
                      <Select
                      className={classes.select}
                      labelId="open-select-label"
                      id="open-select-label"
                      required
                      value={transition}
                      onChange={(event)=>setTransition(event.target.value)}
                      MenuProps={MenuProps}
                      >
                      {transitionOption.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                          {item.state_label}
                          </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="open-multiselect-label">Groups</InputLabel>
                      <Select
                      className={classes.select}
                      labelId="open-multiselect-label"
                      id="open-multiselect-label"
                      multiple
                      required
                      value={groupOption}
                      onChange={(event)=>setGroupOption(event.target.value)}
                      MenuProps={MenuProps}
                      renderValue={(selected) => selected.map(i => props.groups.filter(item => item.id===i).map(item => item.name)).join(', ')}
                      >
                      {props.groups.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={groupOption.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                          {/* {item.name} */}
                          </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    className={classes.button}
                    onClick={saveRequest}
                  >
                      Save
                  </Button>
                </form>
            <FormTable transitionapprovals={true}
            hideCreateSegment={true}
            optSelect2={transitionOption}
            columns={[
                { label: 'Transition', key: 'transtion_name' }, { label: 'Groups', key: 'group_name' }]}
            rowData={[...transtionApprovalData]}
            title='Transition Approvals'
            >
            </FormTable>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openMessage} autoHideDuration={2000} onClose={handleClose} >
                <Alert severity={message.severity}>
                  {message.message}
                </Alert>
            </Snackbar>
      </div>}
    </Loader>
    )
}


const mapStateToProps = (state) => {
    return ({
      states: state.workflowReducer.states,
      groups: state.adminReducer.groups,
      workflows: state.workflowReducer.workflows,
      transitions: state.workflowReducer.transitions,
      transitionapprovals: state.workflowReducer.transitionapprovals
    })
  };
  
const mapActionsToProps = (dispatch) => {
    return {
      getTranstionApprovals,
      setTranstionApprovals: (data) => dispatch({ type: GET_TRANSITION_APPROVALS , payload: data }),
    }  
  };

export default connect(mapStateToProps, mapActionsToProps)(TransitionApprovals)
