import React from 'react';

const Copyright = (props) => {
  
  const {year, logo, backgroundImage} = {...props}
  return (
      <div
        id="leftImgDiv"
        className="h-md-100"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img className="logo-img-left" src={logo} />
        <div className="app-info-container">
          <div id="subDivLogin" className="app-info">
            <h3 className="">IA Smart Platform</h3>
            <button type="submit" className="btn-contact">
              Contact Us
            </button>
          </div>
          <div className="copyright-text">©copyright@impactanalytics{year}</div>
        </div>
      </div>
  );
};

export default Copyright;
