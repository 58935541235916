import React from "react";
import CategoryFilter from "./CategoryFilter";
import { FiltersContainer, FilterHeader, FilterButton } from "./index";
import { useHistory } from "react-router-dom";
import { ClearAllText } from "./CategoryCommons";
import { getValueForKey, handleClearAll } from "./helper";
import { Typography } from "@material-ui/core";
import { Inbox } from "react-feather";

const CategoryFilters = ({
  filterData,
  filterLoader,
  confidence,
  filtersOpen,
  handleFiltersOpen,
}) => {
  const history = useHistory();

  const renderFilter = () =>
    filterData.length ? (
      filterData?.map(({ id, label, is_variant, values, type }) => {
        return values?.length ? (
          <CategoryFilter
            key={id}
            parentId={id}
            parentLabel={label}
            isVariant={is_variant}
            values={values}
            type={type}
            confidence={confidence}
            filtersOpen={!!getValueForKey(id)}
          />
        ) : null;
      })
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Inbox size={50} style={{ color: "grey" }} />
        <Typography
          size={96}
          style={{ color: "grey", fontSize: "14px" }}
          gutterBottom
        >
          No Data
        </Typography>
      </div>
    );

  return filtersOpen ? (
    <FiltersContainer $loading={filterLoader}>
      <FilterHeader>
        <FilterButton
          id="exCatBtnOpenFilters"
          $open={true}
          onClick={() => handleFiltersOpen(!filtersOpen)}
        >
          <i className="fa fa-filter fa-lg" aria-hidden="true" />
        </FilterButton>

        <Typography variant="caption" style={{ fontSize: 15 }}>
          Filters
        </Typography>
        <ClearAllText onClick={() => handleClearAll(history)}>
          <Typography variant="caption" id="clearAllBtn">
            Clear All
          </Typography>
        </ClearAllText>
      </FilterHeader>
      {renderFilter()}
    </FiltersContainer>
  ) : (
    <FilterButton
      id="exCatBtnOpenFilters"
      $open={false}
      onClick={() => handleFiltersOpen(!filtersOpen)}
    >
      <i className="fa fa-filter fa-lg" aria-hidden="true" />
    </FilterButton>
  );
};

export default CategoryFilters;
