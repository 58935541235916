// Under Development

import { Chip, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { deleteKeyValue, handleAppend, queryString } from "./helper";

const selectedFilters = (filterData) => {
  let requestPayload = [];
  for (let pair of queryString().entries()) {
    switch (pair[0]) {
      case "page":
      case "sortBy":
      case "image_count":
      case "status":
      case "forceUpdate":
      case "showall":
      case "list":
      case "type":
      case "productId":
      case "variantId":
        break;
      case "composite_confidence_score":
        requestPayload = [
          ...requestPayload,
          {
            label: "composite_confidence_score",
            value: `${pair[1].split(",")[0]} - ${pair[1].split(",")[1]}%`,
          },
        ];

        break;
      default:
        requestPayload = [
          ...requestPayload,
          ...pair[1].split("|").map((tag) => ({
            label: filterData?.find((el) => String(el.id) === pair[0])?.label,
            value: tag,
            id: pair[0],
          })),
        ];
    }
  }
  return requestPayload;
};

const handleDelete = (tag, history) => {
  if (tag.label === "composite_confidence_score") {
    return deleteKeyValue(tag.label, history);
  }
  handleAppend(tag.id, tag.value, history);
};
const SelectedFilters = ({ filterData }) => {
  const history = useHistory();

  return selectedFilters().length ? (
    <SelectedFilterContainer>
      <Typography
        variant="caption"
        style={{ color: "#808080", alignSelf: "center", fontSize: "0.86rem" }}
      >
        Filters Applied :
      </Typography>
      <Tags>
        {selectedFilters(filterData).map((tag, index, arr) => (
          <Chip
            key={tag + index}
            label={
              (arr.map((elem) => elem.value).indexOf(tag.value) !==
              arr.map((elem) => elem.value).lastIndexOf(tag.value)
                ? tag.label + " - "
                : "") + tag.value
            }
            avatar={
              <svg viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            }
            onClick={() => handleDelete(tag, history)}
            className="tag"
          />
        ))}
      </Tags>
    </SelectedFilterContainer>
  ) : null;
};

export default SelectedFilters;

const Tags = styled.div`
  display: flex;
  flex-flow: wrap;
  .tag {
    cursor: pointer;
    margin: 2px 4px;
    color: #fff;
    border-radius: 4px;
    background-color: #808080;
    &:hover,
    &:active {
      background-color: #808080cc;
      span {
        text-decoration: line-through;
      }
    }
    &:focus {
      background-color: #808080;
    }
    svg {
      width: 16px;
      height: 16px;
      stroke: #fff;
      stroke-width: 6px;
    }
  }
`;

const SelectedFilterContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  background: #fff;
  border-radius: 12px;
`;
