import React from "react";
import Modal from "../modal";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
}));

const LoadingOverlay = ({ active }) => {
  const classes = useStyles();

  return active ? (
    <Modal showClose={false}>
      <Backdrop className={classes.backdrop} open={active}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Modal>
  ) : null;
};

export default LoadingOverlay;
