import React from "react";
import styled from "styled-components";

const ProductDetailNavigation = ({
  current_index = 0,
  count = 0,
  prev = null,
  next = null,
  first = null,
  last = null,
  history = {},
  disableCount = false,
  navigateToProduct
}) => {
  const handleNextPrevPath = (product) => {
    let pathArr = history.location.pathname.split("/");
    pathArr.splice(-1, 1, product);
    history.push(pathArr.join("/") + history.location.search);
    navigateToProduct();
  };

  return (
    <ProductNavigation>
      <div style={{ visibility: "hidden" }}>
        <span className={`product-detail-nav`}>{"<<"}</span>
        <span className={`product-detail-nav`}>{"<"}</span>
        <span className={`product-detail-nav`}>{">"}</span>
        <span className={`product-detail-nav`}>{">>"}</span>
      </div>
      {!!count && !disableCount && (
        <div className="product-detail-count">
          Showing #{current_index} of {count} remaining products
        </div>
      )}
      <div>
        <span
          className={`product-detail-nav ${!prev && "btn-disabled"}`}
          onClick={() => (prev ? handleNextPrevPath(first) : null)}
        >
          {"<<"}
        </span>
        <span
          className={`product-detail-nav ${!prev && "btn-disabled"}`}
          onClick={() => (prev ? handleNextPrevPath(prev) : null)}
        >
          {"<"}
        </span>
        <span
          className={`product-detail-nav ${!next && "btn-disabled"}`}
          onClick={() => (next ? handleNextPrevPath(next) : null)}
        >
          {">"}
        </span>
        <span
          className={`product-detail-nav ${!next && "btn-disabled"}`}
          onClick={() => (next ? handleNextPrevPath(last) : null)}
        >
          {">>"}
        </span>
      </div>
    </ProductNavigation>
  );
};

export default ProductDetailNavigation;

const ProductNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 20px 0;
  margin: 0 10px 0 10px;

  .product-detail-count {
    font-size: 14px;
    color: rgb(0 0 0 / 55%);
    font-weight: 600;
  }
  .product-detail-nav {
    font-size: 22px;
    letter-spacing: -5px;
    color: #44677b;
    float: left;
    padding: 0px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    cursor: pointer;
    &.btn-disabled {
      color: grey !important;
      cursor: not-allowed;
    }
    &:hover {
      background-color: #ddd;
    }
  }
`;
