import React from "react";
import styled from "styled-components";
import ProductDetailButton from "./ProductDetailButton";

const EndOfProducts = ({ handlePath, message = "No More Results..." }) => {
  return (
    <Container>
      <div className="container-icon">{/* <ThumbUpIcon /> */}</div>
      <div className="container-text">
        {/* Kudos. you have completed your
        <br />
        work here. Request you to Go Back */}
        {message}
      </div>
      <div className="container-btn">
        <ProductDetailButton
          label="Go Back"
          onClickHandler={handlePath}
          type={0}
          id="goBackBtn"
        />
      </div>
    </Container>
  );
};

export default EndOfProducts;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  .container-icon {
    visibility: hidden;
    align-self: center;
    padding: 20px;
    border: 1px solid rgb(68 103 123 / 55%);
    border-radius: 50%;
    svg {
      font-size: 36px;
      animation: vibrate 10s linear 0s infinite;
      @keyframes vibrate {
        0% {
          color: rgb(68 103 123 / 55%);
        }
        20% {
          color: rgb(68 103 123 / 55%);
          transform: scale(1.1);
        }
        40% {
          color: rgb(68 103 123 / 75%);
          transform: scale(1.2);
        }
        60% {
          color: rgb(68 103 123 / 100%);
          transform: scale(1.2);
        }
        80% {
          color: rgb(68 103 123 / 75%);
          transform: scale(1.1);
        }
        100% {
          color: rgb(68 103 123 / 55%);
          transform: scale(1);
        }
      }
    }
  }
  .container-text {
    margin-top: 20px;
    text-align: center;
    color: rgb(0 0 20 / 55%);
  }
  .container-btn {
    align-self: center;
    margin-top: 40px;
    padding: 20px;
  }
`;
