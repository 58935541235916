import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import UserRoleGrpMapper from "./components/userRoleGrpMapper";
import GroupPermissionMapper from "./components/groupPermission";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "98%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  adminTabContainer: {
    marginTop: 10,
  },
});

const AdminContainer = (props) => {
  const intialActiveTab =
    props.location.pathname === "/marksmart/admin/grouppermission" ? 1 : 0;
  const classes = useStyles();
  const [value, setValue] = React.useState(intialActiveTab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { path } = props.match;
  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginTop: "2%" }}
      >
        <Grid item xs={12}>
          <AppBar position="static" color="default" className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
            >
              <Tab
                label="User Role Group Mapper"
                to="/marksmart/admin/userrole"
                component={Link}
              />
              <Tab
                label="Group Permissions Mapper"
                to="/marksmart/admin/grouppermission"
                component={Link}
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} className={classes.adminTabContainer}>
          <Switch>
            <Route
              exact
              path={`${props.match.path}`}
              render={() => (
                <Redirect replace to={"/marksmart/admin/userrole"} />
              )}
            />
            <Route
              exact
              path={"/marksmart/admin/userrole"}
              component={UserRoleGrpMapper}
            />
            <Route
              exact
              path={"/marksmart/admin/grouppermission"}
              component={GroupPermissionMapper}
            />
          </Switch>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminContainer;
