import React, { useReducer, useState } from 'react'
import { MultiSelect, SingleSelect } from '../../commonComponents/filters'
import axiosInstance from '../../Utils/axios'
import { BASE_API } from '../../config'
import './ProductFilter.css'
import { find, indexOf, cloneDeep } from 'lodash'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload }
    default:
      break
  }
}

function ProductFilterGroup(props) {
  const [{ selected }, dispatch] = useReducer(reducer, {
    selected: [],
  })
  const [dependencyChange, setDependency] = useState(false)

  const updateDependency = (key, params) => {
    //updated selected with the values

    if (params.length) {
      updateSelected(key, params)
      setDependency(true)
    } else {
      removeSelected(key, params)
    }
  }

    const updateSelected = async(key, params) => {
        let newValue = {
            'filter_id': key['filter_id'],
            "filter_type": "hierarchy",
            'key_values': params
        }
        var match = find(selected, key)
        if (match) {
            let index = indexOf(selected, match)
            selected.splice(index, 1, newValue)
        }
        else {
            selected.push(newValue)
        }
        await props.update(selected);
        dispatch({
            type: 'SET_DATA',
            payload: selected
        })
    }

    const removeSelected = async(key, params) => {

        var match = find(selected, key)
        // await props.update(selected);
        if (match) {
            let index = indexOf(selected, match)
            selected.splice(index, 1)
        await props.update(selected);
            dispatch({
                type: 'SET_DATA',
                payload: selected
            })
        }

    }
    

  //render the type of filter
  const renderContent = (param, i) => {
    // console.log(data)
    switch (param.is_multiselect) {
      case true:
        return (
          <MultiSelect
            {...param}
            dependency={selected}
            updation={dependencyChange}
            updateDependency={updateDependency}
          />
        )
      case false:
        return (
          <SingleSelect
            {...param}
            dependency={selected}
            updateDependency={updateDependency}
          />
        )
      default:
        break
    }
  }

  return (
    <div className='filter-row'>
      {props.filters &&
        props.filters.map((param, i) => (
          <div  data-testid='filtertype'  className='col-md-3' key={i}>
            <React.Fragment>
              <label style={{ textTransform: 'uppercase' }}>
                {param.label}
              </label>
              {renderContent(param)}
            </React.Fragment>
          </div>
        ))}
    </div>
  )
}

export default ProductFilterGroup
