import React from "react";
import ReactSelect from "react-select";

const borderMap = {
  "Reviewed": "8px solid #006d00cc",
  "Edited": "8px solid #42a5f5",
  "Pending": "1px solid lightgrey"
}
  
const borderColorMap = {
  "Reviewed": "#006d00cc",
  "Edited": "#42a5f5",
  "Pending": "none"
}

const styles = (value = {}, selected = {}, noStyleUpdate = false, product_attribute_status= "Pending") => ({
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "10 !important",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    letterSpacing: "0.03333em",
    fontSize: "0.8rem !important",
    lineHeight: 1.66,
  }),
  control: (base) => ({
    ...base,
    border: "1px solid lightgrey",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    letterSpacing: "0.03333em",
    minHeight: noStyleUpdate ? 33 : 38,
    fontSize: noStyleUpdate ? 14 : "inherit",
    width: noStyleUpdate ? 165 : "inherit",
    borderLeft: `${
      value.label !== selected.label && !noStyleUpdate
        ? "8px solid #ffa500b5"
        : borderMap[product_attribute_status]
    }`,
    boxShadow:
      value.label !== selected.label && !noStyleUpdate ? "#ffa500b5" : "lightgrey",
    "&:hover": {
      borderColor:
        value.label !== selected.label && !noStyleUpdate
          ? "#ffa500b5 !important"
          : borderColorMap[product_attribute_status],
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "inherit",
    padding: "0px !important",
    paddingRight: "5px !important",
  }),
});

const Select = ({ dropdownData, ...props }) => {
  return (
    <ReactSelect
      {...props}
      styles={styles(props.value, props.selected, props.noStyleUpdate, props.product_attribute_status)}
      className="product-attribute-select"
      menuPortalTarget={document.querySelector("body")}
      isSearchable={false}
      isClearable={false}
    />
  );
};

export default Select;
