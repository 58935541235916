import React from 'react'
import { emphasize, withStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Chip from '@material-ui/core/Chip'

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300]
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12)
        }
    }
}))(Chip)

function handleClick(event, action) {
    event.preventDefault()
    action()
}

export default function HeaderBreadCrumbs({ options }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {options.map((option, i) => (
                <StyledBreadcrumb
                    key={i}
                    id={option.id}
                    label={option.label}
                    icon={option.icon}
                    onClick={(event) => handleClick(event, option.action)}
                />
            ))}
        </Breadcrumbs>
    )
}
