import LoadingOverlay from '../../Utils/Loader/loader';
import { Redirect, Route } from 'react-router-dom';
import posthog from 'posthog-js';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          const allProps = { ...props, ...rest };
          return <Component {...allProps} />;
        } else {
          if (rest.isTokenVerified) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return <LoadingOverlay loader={true} spinner></LoadingOverlay>;
          }
        }
      }}
    />
  );
};
