import "./index.scss";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../Utils/axios";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {Select, Button} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

function CreateTicket(props) {
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketSubType, setTicketSubType] = useState([]);
  const [selectedTicketSubType, setSelectedTicketSubType] = useState("");
  //const [ticketSubType,setTicketSubType] = useState([...props.ticketSubType])
  //const saveRequest = async (rowdata) =>

  useEffect(() => {
    async function saveRequest() {
      try {
        const { data } = await axiosInstance({
          url: "/ticket/subtype",
          method: "GET",
        });
        console.log("data ---------", data);
        setTicketSubType(data.data.results);
        return { status: true };
      } catch (err) {
        if (err.response && !err.response.data.status) {
          //setIsLoading(false)
          return { status: false, message: err.response.data.data };
        }
        //setIsLoading(false)
      }
    }
    saveRequest();
  }, []);

  async function postTicketData() {
    try {
      const { resp } = await axiosInstance({
        url: "/ticket/",
        method: "POST",
        data: {
          title: ticketTitle,
          description: ticketDescription,
          ticket_sub_type: Number(selectedTicketSubType),
        },
      });
      alert("Ticket created successfully");
      return { status: true };
    } catch (err) {
      alert("Failed error :- ", err);
      if (err.response && !err.response.data.status) {
        //setIsLoading(false)
        return { status: false, message: err.response.data.data };
      }
      //setIsLoading(false)
    }
  }

  return (
    <div className="ticket-form">
      <h4>Create new ticket</h4>
      <form onSubmit={handleSubmit} onReset={resetForm}>
        <div className="input-group">
          <label className="ticket-text">Ticket Title</label>
          <br />
          <TextField
          variant='outlined'
            placeholder="Title"
            className="ticket-inp"
            value={ticketTitle}
            onChange={updatetitle}
          />
        </div>
        <br />
        <div className="input-group">
          <label className="ticket-text">Ticket Description</label>
          <br />
          <TextareaAutosize
            placeholder="Description"
            rowsMax={6}
            className="ticket-desc"
            value={ticketDescription}
            onChange={updatedesc}
          />
        </div>
        <br />
        <div className="input-group">
          <label className="ticket-text">Ticket Sub Type</label>
          <br />
          <Select
            className="ticket-inp"
            onChange={handleChange}
            placeholder="Select Sub Type"
            variant="outlined"
          >
            {ticketSubType.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id}>
                  <em>{item.name}</em>
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <br />
        <Button variant="contained" color="primary" type="submit" value="submit" className="ticket-button">Submit</Button>
        <Button variant="contained" color="primary" type="reset">Reset</Button>
      </form>
    </div>
  );

  function updatetitle(event) {
    setTicketTitle(event.target.value);
  }
  function updatedesc(event) {
    setTicketDescription(event.target.value);
  }
  function handleSubmit(event) {
    postTicketData();
    event.preventDefault();
  }
  function handleChange(event) {
    setSelectedTicketSubType(event.target.value);
  }

  function resetForm(event) {
    setTicketDescription("");
    setSelectedTicketSubType("");
  }
}

export default CreateTicket;
