import React, { useEffect } from "react";
import { USERROLEGROUPMAP_COLUMNS } from "../utils/usrRoleGrpColumns";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import Table from "../../../Utils/reactTable/table";
const UserRoleGrpSummary = (props) => {
  const setTableData = () => {
    return props.users.map((user) => {
      return {
        email: user.email,
        roles_length: user.roles.length,
        groups_length: user.groups.length,
      };
    });
  };
  return (
    <Container maxWidth="md" style={{ height: "180px" }}>
      <Table
        columns={USERROLEGROUPMAP_COLUMNS}
        rowdata={setTableData()}
        isrowclickable={true}
        rowclickHandler={(option) => props.handleUserSelect(option)}
        style={{ height: "180px" }}
      />
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    users: state.adminReducer.users,
  };
};
export default connect(mapStateToProps, null)(UserRoleGrpSummary);
