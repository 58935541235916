import React, {useState, useEffect} from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import Select from 'react-select';

const ProductDetailRecategorization = ({L1L2Categories,onChangeSelectAttribute,reset,subCategoryRef,subCategoryFocus}) => {
    const defaultValueCat = L1L2Categories?.find(i=>i.selected);
    const defaultValueSubCat = defaultValueCat["Sub Category"].find(i=>i.selected);
    
    const [cat, setCat] = useState(defaultValueCat);
    const [subCat, setSubCat] = useState(defaultValueSubCat);

    let catValues = function () {
        let obj = {};
        obj.label = "Category"
        obj.product_attribute_mapping_id = L1L2Categories[0].product_attribute_mapping_id;
        obj.values = L1L2Categories
        return obj
    }();
    let subCatValues = function () {
        let obj = {};
        obj.label = "Sub Category"
        obj.product_attribute_mapping_id = subCat.product_attribute_mapping_id;
        obj.values = cat["Sub Category"]
        return obj
    }();
        
    useEffect(() => {
        if (reset) {
            setCat(defaultValueCat);
            setSubCat(defaultValueSubCat)
        }
    }, [reset]);
    
    const dropdownStyles = (val,defaultValue) => ({
        indicatorSeparator: (base) => ({
        display: 'none',
        }),
        menu: (base) => ({
        ...base,
        zIndex: '10 !important',
        }),
        control: (base) => ({
        ...base,
        pointerEvents: 'all',
        backgroundColor: '#fff',
        border: `${val!==''? '1px solid lightgrey': '1px solid orange'}`,
        borderLeft: `${ val!=='' && 
            (val.label === defaultValue.label
            ? '1px solid lightgrey'
            : '8px solid orange')
        }`,
        boxShadow: val.label !== defaultValue.label ? 'orange' : 'lightgrey',
        '&:hover': {
            borderColor:
            val.label !== defaultValue.label ? 'orange !important' : 'none',
        },
        }),
        dropdownIndicator: (base) => ({
        ...base,
        color: 'inherit',
        padding: '0px !important',
        paddingRight: '5px !important',
        }),
    });
      
    return (
        <>
            <Attribute key={"cat"} id={"cat"}>
                <label htmlFor="">Category</label>
                <Box position='relative'>
                    <div key={"cat"}>
                        <Select
                            id='productDetaildropdown'
                            options={catValues.values}
                            styles={dropdownStyles(cat,defaultValueCat)}
                            className='product-detail-attribute-select'
                            isSearchable={false}
                            isClearable={false}
                            name='product-attribute-edit'
                            value={cat}
                            onChange={(obj) => {
                            setCat(obj)
                            onChangeSelectAttribute(subCat, {product_attribute_mapping_id: subCat.product_attribute_mapping_id, label: subCat.tenant_attribute_name}, 'delete')
                            setSubCat('')
                            subCategoryFocus();
                            defaultValueCat.label === obj.label
                                ? onChangeSelectAttribute(obj, catValues, 'delete')
                                : onChangeSelectAttribute(obj, catValues, 'add');
                            }}
                        />
                    </div>
                </Box>
          </Attribute>

          <Attribute key={"subCat"} id={"subCat"} subCat={subCat}>
            <label htmlFor="">Sub Category</label>
            <Box position='relative'>
                <div key={"subCat"}>
                    <Select
                        placeholder="Select Sub Category"
                        ref={subCategoryRef} 
                        openMenuOnFocus
                        id='productDetaildropdown'
                        options={subCatValues.values}
                        styles={dropdownStyles(subCat,defaultValueSubCat)}
                        className='product-detail-attribute-select'
                        isSearchable={false}
                        isClearable={false}
                        name='product-attribute-edit'
                        value={subCat}
                        onChange={(obj) => {
                            setSubCat(obj)
                            // defaultValueSubCat.label === obj.label
                            //     ? onChangeSelectAttribute(obj, {product_attribute_mapping_id: obj.product_attribute_mapping_id, label: obj.tenant_attribute_name}, 'delete')
                            //     : 
                            onChangeSelectAttribute(obj, {product_attribute_mapping_id: obj.product_attribute_mapping_id, label: obj.tenant_attribute_name}, 'add');
                        }}
                    />
                </div>
            </Box>
          </Attribute>
        </>
    )
}

export default ProductDetailRecategorization;

const Attribute = styled.div`
  display: flex;
  // padding: 0 48px;
  margin: 10px 0;
  padding-right: 10px;
  align-items: center;
  justify-content: 'space-between';

  label {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px 10px 0px;
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 0.7);
  }
  .product-detail-attribute-select {
    font-size: 14px;
    flex: 1 1 auto;
    width: 280px;
    color: rgba(0, 0, 0, 0.8);
    animation: ${props => props.subCat===''? 'vibrate 0.1s cubic-bezier(0,1,0,1.01) 0s 2': 'none'};
    @keyframes vibrate
    {
        0%   {margin-right: 0px;}
        25%  {margin-right: -6px;}
        50%  {margin-right: 0px;}
        75%  {margin-right: 6px;}
        100% {margin-right: 0px;}
    }
  }
`;
