import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginComponent from './components/loginComponent';

const Auth = (props) => {
  return (
    <>
      <LoginComponent tenantId={props.tenantId} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantId: state.authReducer.tenantId,
  };
};

export default connect(mapStateToProps, null)(Auth);
