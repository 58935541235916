import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormTable from '../components/form-table'
import Loader from '../../../Utils/Loader/loader'
import FormControl from '@material-ui/core/FormControl';
import { getWorkflows } from "../../../actions/workflowActions"
import { WORKFLOW } from '../../../config/api';
import axiosInstance from '../../../Utils/axios';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
  },

  formControl: {
    margin: theme.spacing(1),
    marginLeft: '0px',
    minWidth: 200
  },
}))

function States(props) {
  const classes = useStyles()
  const [addForm, setAddForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)

  const [statesOption, setStatesOption] = useState([])
  const [workflowData, setWorkflowData] = useState([])
  const [openMessage, setOpenMessage] = useState(false);

  useEffect(async () => {
    try {
     
      let getData = props.workflows.map(row => {
        let initialState = props.states.filter(item => item.id === row.initial_state).map(item => item.label)[0];
        return {
          ...row,
          initial_state_label: initialState,
        }
      })
      setWorkflowData(getData)
      setStatesOption(props.states)
      setShowForm(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

    return () => {
    };
  }, [])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);


  }

  const saveRequest = async (rowdata) => {
    let postData = rowdata.map(item => {
   
      return {
        initial_state_id: item.initial_state_id
      }
    })

    try {
      setIsLoading(true)
      await axiosInstance({
        url: WORKFLOW,
        method: 'POST',
        data:{
          initial_state_id:postData[0].initial_state_id
        }
      })
      const { data } = await axiosInstance({
        url: WORKFLOW,
        method: 'GET',
      })
      let getData =data.data.results.map(row => {
        let initialState = props.states.filter(item => item.id === row.initial_state).map(item => item.label)[0];
        return {
          ...row,
          initial_state_label: initialState,
        }
      })
      setWorkflowData(getData)
      props.setWorkflow(data.data.results)
      setIsLoading(false)
      setOpenMessage(true);
      return { status: true };
    } catch (err) {
      if (err.response && !err.response.data.status) {
        setIsLoading(false)
        return { status: false, message: err.response.data.data };
      }
      setIsLoading(false)

    }


  }


  return (
    <Loader loader={isLoading} >
      {showForm && <div>
        <FormTable workflow={true}
          optSelect={statesOption}
          form={[{ label: 'Name', type: 'textfield', name: 'field_name' },
          { label: 'Initial State', type: 'select', name: 'initial_state_id', options: props.states }]}
          columns={[{ label: 'Name', key: 'field_name' }, { label: 'Initial State', key: 'initial_state_label' }]}
          rowData={[...workflowData]}
          title='Workflow'
          hideCreateSegment={workflowData.length>0? true:false}
          saveRequest={saveRequest}
        ></FormTable>

        <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openMessage} autoHideDuration={2000} onClose={handleClose} >
          <Alert severity="success">
            Workflow saved successfully.
     </Alert>
        </Snackbar>

      </div>
      }
    </Loader>
  )
}
const mapStateToProps = (state) => {
  return ({
    states: state.workflowReducer.states,
    workflows:state.workflowReducer.workflows
  })
};

const mapActionsToProps = (dispatch) => {
  return {
    getWorkflows,
    setWorkflow: (data) =>dispatch({ type: 'GET_WORKFLOWS', payload: data }),
  }  
}
export default connect(mapStateToProps, mapActionsToProps)(States)