import React, { memo, useState } from "react";
import styled from "styled-components";
import clsx from "clsx";
import ReactImageMagnify from "react-image-magnify";
import { RotateCw } from "react-feather";
import ProductDetailEditDropdown from "./ProductDetailEditDropdown";
import VariantDeleteButton from "./VariantDeleteButton";

const tagColor = ["#44677be0", "#00A0B4"];

const VariantImageDropdown = ({
  index,
  productDetails,
  imageUrl,
  imageUrlHighReso,
  attribute,
  onChangeSelectAttribute,
  reset,
  changesArr,
  listView = false,
  deleteVariant,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const ImageMagnify = ({ imageUrl, imageUrlHighReso, index, listView }) => {
    return (
      <>
        {isLoading && (
          <span className="image-loading">
            <RotateCw />
          </span>
        )}
        <ReactImageMagnify
          {...{
            smallImage: {
              isFluidWidth: true,
              src: imageUrl,
              onLoad: () => setIsLoading(false),
            },
            largeImage: {
              src: imageUrlHighReso,
              width: 600,
              height: 900,
            },
            enlargedImageContainerDimensions: {
              width: 220,
              height: 360,
            },
            enlargedImagePosition: "beside",
            enlargedImageStyle: { zIndex: "999", position: "absolute" },
            enlargedImageContainerStyle: {
              position: "absolute",
              top: "-180px",
              left: "0px",
              borderRadius: "3px",
              border: "1px solid lightgray",
              marginLeft: listView
                ? "0px"
                : index % 3 === 0 && !listView
                ? "130px"
                : "90px",
            },
            enlargedImagePortalId: `portalDiv${index}`,
          }}
        />
      </>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: listView ? "row" : "column",
        flex: "1 1 auto",
      }}
      id={"exCatImg" + index}
      key={index}
    >
      <ParentImgDiv
        id={`exCatImage${productDetails.product_id}`}
        // tag={productDetails.product_display_status?.text}
        // bgcolor={tagColor[productDetails.product_display_status?.color]}
        inset={listView}
      >
        <div id={`portalDiv${index}`} className="portalDiv"></div>
        <div
          className={clsx(
            "figure",
            "tag",
            "tag-edited"
            // productDetails.product_display_status?.text
          )}
        >
          <ImgDiv
            labelDisplay={index % 3 === 0 && !listView}
            listView={listView}
          >
            <VariantDeleteButton
              productDetails={productDetails}
              changesArr={changesArr}
              deleteVariant={deleteVariant}
            />
            <ImageMagnify
              imageUrl={imageUrl}
              imageUrlHighReso={imageUrlHighReso}
              index={index}
              listView={listView}
            />
          </ImgDiv>
        </div>
      </ParentImgDiv>
      <DropdownView listView={listView} id={`dropdownBox${index}`}>
        {attribute.map((o, i) => (
          <Attribute
            labelDisplay={index % 3 === 0 && !listView}
            key={i}
            id={o.label}
            listView={listView}
          >
            <label htmlFor="">{o.label}</label>
            <ProductDetailEditDropdown
              key={index}
              attribute={{
                ...o,
                product_id: productDetails.product_id,
                product_attribute_status: o.variant_attribute_status
              }}
              index={index}
              onChangeSelectAttribute={onChangeSelectAttribute}
              reset={reset}
            />
          </Attribute>
        ))}
      </DropdownView>
    </div>
  );
};

export default memo(VariantImageDropdown);

const ParentImgDiv = styled.div`
  height: 400px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  .portalDiv {
    position: relative;
    border-radius: 3px;
    border: 1px solid lightgray;
  }

  .figure {
    margin: 0;
    font-size: 13px;
  }
  .figure.tag {
    position: relative;
  }
  .figure.tag::before {
    position: absolute;
    top: 30px;
    right: 0;
    display: block;
    color: white;
    font-weight: bold;
    opacity: 0.8;
  }
  .figure.tag-edited::before {
    content: "${(props) => props.tag}";
    background: ${(props) => props.bgcolor};
    padding: 2px 8px;
    z-index: 1;
    border-radius: 6px 0 0 6px;
  }
`;

const ImgDiv = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  margin-left: ${(props) =>
    props.listView ? "0px" : props.labelDisplay ? "130px" : "90px"};
  height: 350px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  .magnifier {
    border: 2px solid red;
    div {
      div:first-child {
        border: 2px solid red;
        heigth: 100px !important;
      }
    }
  }
  .image-loading {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    background: #0000007a;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const DropdownView = styled.div`
  display: flex;
  flex-flow: ${(props) => (props.listView ? "row" : "column")};
  //   width: 100%;
`;
const Attribute = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 5px 0;
  align-items: center;
  justify-content: "space-between";
  padding-right: ${(props) => (props.listView ? "10px" : "0px")};
  padding-left: ${(props) => (props.listView ? "10px" : "0px")};

  label {
    display: ${(props) => (props.listView ? "none" : "unset")};
    visibility: ${(props) => (props.labelDisplay ? "" : "hidden")};
    font-size: ${(props) => (props.labelDisplay ? "14px" : "12px")};
    font-weight: 500;
    padding-right: ${(props) => (props.labelDisplay ? "30px" : "10px")};
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 1);
  }
  .product-detail-attribute-select {
    font-size: 13px;
    flex: 1 1 auto;
    width: 200px;
    color: rgba(0, 0, 0, 0.8);
  }
`;