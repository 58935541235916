import React, { useState } from "react";
import styled from "styled-components";
import ShowToast from "../../../../../commonComponents/toast";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, Button } from "@material-ui/core";
import ConfirmPrompt from "../../../../../commonComponents/confirmPrompt";

export const FilterButton = styled.button`
  outline: none;
  align-self: flex-start;
  background-color: #d3d3d39c;
  border-radius: 6px;
  margin-right: 4px;
  margin-top: ${({ $open }) => ($open ? "0" : "20px")};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px 8px;
  margin-left: 8px;
  .fa-filter {
    color: #5a7c90;
  }
  &:hover,
  :active {
    background-color: #d3d3d3eb;
  }
`;

export const FilterContainer = styled.div`
  transition: width 6s ease;
  min-width: 15%;
  max-width: 15%;
  background-color: #fff;
  margin-right: 10px;
`;

export const FiltersContainer = styled.div`
  margin-top: 20px;
  height: ${({ $loading }) =>
    $loading ? "var(--attribute-smart-filter-container-width)" : "max-content"};
  min-width: var(--attribute-smart-filter-container-width);
  width: var(--attribute-smart-filter-container-width);
  transition: width 6s ease;
`;

export const ClearAllText = styled.span`
  color: #0098ff;
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
`;
export const FilterHeader = styled.div`
  font-size: 16px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`;

export const ProductViewListWrapper = styled.div`
  width: ${({ $isfilterOpen }) =>
    $isfilterOpen ? "calc(100vw - 436px)" : "calc(100vw - 266px)"};
`;

export const FetchErrorMessage = (callback) => {
  ShowToast(
    <div>
      Something went wrong...
      <p>
        <button
          style={{
            outline: "none",
            border: "1px solid white",
            background: "white",
            borderRadius: "3px",
            cursor: "pointer",
            color: "rgb(0,0,0,0.9)",
          }}
          onClick={() => callback()}
        >
          Retry
        </button>
      </p>
    </div>,
    "error",
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReportProblemIcon size={18} style={{ marginRight: "5px" }} />
      {`  `} Error
    </div>,
    "bottom-right",
    7000
  );
};

export const SaveSuccessMessage = () => {
  ShowToast(
    <p>Changes saved successfully</p>,
    "success",
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <CheckCircleIcon size={18} style={{ marginRight: "5px" }} />
      {`  `} Success
    </div>,
    "bottom-right",
    7000
  );
};

export const SaveErrorMessage = () => {
  ShowToast(
    <p>Failed to save changes. Try again</p>,
    "error",
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReportProblemIcon size={18} style={{ marginRight: "5px" }} />
      {`  `} Error
    </div>,
    "bottom-right",
    7000
  );
};

export const RenderProductAction = ({ show, changesArray, saveEdits }) => {
  const [showWarning, setshowWarning] = useState(false);
  if (show) {
    return (
      <Box>
        <Box component="span" mr={2}>
          <Button
            aria-controls="saveEditBtn"
            style={{
              minWidth: 120,
            }}
            variant="contained"
            color="primary"
            size="small"
            id="saveEditsBtn"
            onClick={() => {
              if (changesArray.length <= 0) {
                ShowToast(
                  <>
                    <p></p>You have not made any changes<p></p>
                  </>,
                  "info",
                  "Info",
                  "top-right"
                );
              } else {
                saveEdits("edits");
              }
            }}
          >
            Save Edits
          </Button>
        </Box>
        <Box component="span">
          <Button
            aria-controls="saveEditBtn"
            style={{ minWidth: 120 }}
            variant="contained"
            size="small"
            id="saveAllBtn"
            onClick={() => {
              const name = localStorage.getItem("name");
              if (localStorage.getItem(`${name} no-prompt`)) {
                return saveEdits("all");
              }
              setshowWarning(true);
            }}
          >
            Save All
          </Button>
        </Box>
        {showWarning && (
          <ConfirmPrompt
            title="Confirm Save All"
            isSaveAll
            message={
              <>
                <Box>
                  All Products displayed on this page (edited or not edited)
                  will be considered as reviewed and finalized.
                </Box>
                <Box mt={1}>Do you want to save all the products?</Box>
              </>
            }
            confirmCallback={(val, noPromptPreference) => {
              if (val) {
                saveEdits("all");
              }
              if (noPromptPreference) {
                const name = localStorage.getItem("name");
                localStorage.setItem(`${name} no-prompt`, true);
              }
              setshowWarning(false);
            }}
          />
        )}
      </Box>
    );
  }
  return <> </>;
};
