import axios from 'axios';
import { config } from '../../config';
import { logoutUser } from '../../actions/authActions';
import store from '../../store';

const axiosInstance = axios.create({ baseURL: config.baseUrl });

const requestHandler = async (request) => {
  let token = localStorage.getItem('token');
  request.headers.common = { Authorization: `${token}` };
  return request;
};

const errorHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch(logoutUser());
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
