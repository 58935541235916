import React from 'react';
import Select from '../notification-utils/select';
import PropTypes from 'prop-types';

const NotificationFilter = (props) => {
  return (
    <>
      <div id="notifiDrpdwnFilter" className="notifi-drpdwn-filter">
        <Select
          isMulti={props.isMulti}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          isClearable={props.isClearable}
        />
      </div>
    </>
  );
};

NotificationFilter.propTypes = {
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default NotificationFilter;
