import React from 'react'
import { TextField } from '@material-ui/core'

const LoginForm = (props) => {
  const {
    email,
    password,
    onChange,
    ResetPassword,
    onSubmit,
    error
  } = { ...props }
  return (
    <>
      <form className='form-width signin-form' onSubmit={onSubmit}>
        <h2 id='signInText' className='sign-in-text'>
          Sign in to <span id='titleColor'>IA Smart Platform</span>
        </h2>
        <p>Enter your details below.</p>
        <div className='input-group'>
          <label className='' htmlFor='email'>
            EMAIL
          </label>
          <TextField
            variant='outlined'
            name='email'
            type='email'
            id='loginInputEmail'
            className={error.email && error.email.length ? 'signin-form-input error-border' : 'signin-form-input'}
            aria-describedby='emailHelp'
            placeholder='E-mail'
            required=''
            value={email}
            onChange={onChange}
          />
          <p className="error-text">{error.email}</p>
        </div>
        <div className='input-group'>
          <label className='' htmlFor='password'>
            PASSWORD
          </label>
          <TextField
            variant='outlined'
            name='password'
            type='password'
            id='loginPassword'
            className={error.password && error.password.length ? 'signin-form-input error-border' : 'signin-form-input'}
            placeholder='Password'
            required=''
            value={password}
            onChange={onChange}
          />
          <p className="error-text">{error.password}</p>
          <div id='divReset'>
            <button
              type='button'
              id='btnReset'
              className='btn'
              onClick={ResetPassword}
            >
              Forgot your Password?
            </button>
          </div>
        </div>
        <button
          type='submit'
          id='btnLogin'
          className='btn-signin'
        >
          Sign in
        </button>
      </form>
    </>
  )
}

export default LoginForm
