import {
  SET_CURRENT_USER,
  LOGOUT_CURRENT_USER,
  SOME_ACTION,
  LOADER,
  SET_TENANT_ID,
} from './types';
import axiosinstance from '../Utils/axios';
import firebaseobj from '../commonComponents/auth/firebase';
import { toast } from 'react-toastify';
import { FETCH_TENANT, VERIFY_TOKEN } from '../config/api';
import posthog from 'posthog-js';

const fetchUserProviders = (userEmail) => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

// Login - Post User Token
export const loginUser = (userData) => async (dispatch) => {
  const providers = await fetchUserProviders(userData.email);
  if (providers.length == 0) {
    toast.error(
      'There is no user record corresponding to this identifier. The user may have been deleted',
      {
        autoClose: 4000,
        hideProgressBar: true,
      }
    );
    return;
  }
  if (!providers.includes('password')) {
    toast.error(`User Record is already associated with ${providers[0]}`, {
      autoClose: 4000,
      hideProgressBar: true,
    });
    return;
  }
  firebaseobj
    .auth()
    .signInWithEmailAndPassword(userData.email, userData.password)
    .then((userCredential) => {
      // Signed in
      let user = userCredential.user;
    })
    .catch((error) => {
      toast.error(error.message, {
        autoClose: 4000,
        hideProgressBar: true,
      });
    });
};

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: {
      isAuthenticated: true,
      isTokenVerified: true,
      user: {
        token: localStorage.getItem('token'),
        name: localStorage.getItem('name'),
      },
    },
  });
};
// Login - Post User Token
export const hidrateUser = () => (dispatch) => {
  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
      },
    });
  } else {
    dispatch({
      type: LOGOUT_CURRENT_USER,
    });
  }
};

export const checkUserProvider = (userEmail) => (dispatch) => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

export const verifyTokenStatus = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    firebaseobj.auth().onAuthStateChanged((user) => {
      if (user) {
        const providers = user.providerData;
        user.getIdToken(true).then((token) => {
          axiosinstance({
            url: VERIFY_TOKEN,
            method: 'POST',
            data: {
              token: token,
            },
          })
            .then((data) => {
              if (data.data.data.message == 'Token verified') {
                localStorage.setItem('token', token);
                localStorage.setItem('name', user.email);
                posthog.people.set({ email : localStorage.getItem('name') });
                posthog.identify(localStorage.getItem('name'),{host : window.location.hostname});
                localStorage.setItem(
                  'session.expiry',
                  user.toJSON().stsTokenManager.expirationTime
                );
                dispatch({
                  type: SET_CURRENT_USER,
                  payload: {
                    isAuthenticated: true,
                    isTokenVerified: true,
                    user: {
                      token: token,
                      name: user.email,
                    },
                    landingPage: data.data.data.landing_page,
                  },
                });
                resolve(true);
              } else {
                firebaseSignOut().then(() => {
                  toast.error('Unable to verify the token', {
                    autoClose: 4000,
                    hideProgressBar: true,
                  });
                });
                reject(false);
              }
            })
            .catch((error) => {
              firebaseSignOut().then(() => {
                toast.error('Unable to verify the token', {
                  autoClose: 4000,
                  hideProgressBar: true,
                });
              });
              reject(false);
            });
        });
      } else {
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            isAuthenticated: false,
            isTokenVerified: true,
            user: {},
          },
        });
        reject(false);
      }
    });
  });
};

const firebaseSignOut = () => {
  return firebaseobj.auth().signOut();
};
export const logoutUser = (token) => (dispatch) => {
  firebaseSignOut()
    .then(() => {
      localStorage.removeItem('token', token);
      localStorage.removeItem('session.expiry');
    })
    .catch((error) => {
      return;
      // An error happened.
    });
  dispatch({
    type: LOGOUT_CURRENT_USER,
  });
  dispatch({
    type: LOADER,
    payload: {
      status: false,
    },
  });
};

export const getTokenId = (hostname) => (dispatch) => {
  //Logic to fetch tenantID from backend using axios
  axiosinstance
    .get(`${FETCH_TENANT}?domain=${hostname}`)
    .then((data) => {
      dispatch({
        type: SET_TENANT_ID,
        payload: data.data.data.results[0],
      });
      dispatch({
        type: LOADER,
        payload: {
          status: false,
        },
      });
    })
    .catch((error) => {
      //Handling the error
      return;
    });
};

export const forgotPassword = (emailAddress) => (dispatch) => {
  firebaseobj
    .auth()
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      // Email sent.
      toast.success(
        'Password reset link sent to the registered email successfully!',
        {
          autoClose: 4000,
          hideProgressBar: true,
        }
      );
    })
    .catch(function (error) {
      // An error happened.
      toast.error(error.message, {
        autoClose: 4000,
        hideProgressBar: true,
      });
    });
};

export const someAction = (data) => (dispatch, getState) => {
  dispatch({
    type: SOME_ACTION,
    pathname: getState().router.pathname,
  });
};