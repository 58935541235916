import { Box, Grid, Typography } from "@material-ui/core";
import AntSwitch from "./AntSwitch";

const SmartCopySwitch = ({ checked, handleChange }) => {
  return (
    <Box marginTop="6px">
      <Typography component="span" variant="caption">
        <Grid component="label" container spacing={1}>
          <Grid item>
            <AntSwitch
              checked={checked}
              onChange={handleChange}
              name="smartCopy"
            />
          </Grid>
          <Grid item>Smart Copy</Grid>
        </Grid>
      </Typography>
    </Box>
  );
};

export default SmartCopySwitch;
