import React, { useEffect, useReducer } from 'react';
import NotificationFilter from './notificationFilter';
import './index.css';
import PropTypes from 'prop-types';
function reducer(state, action) {
  switch (action.type) {
    case 'SET_NOTIFICATION_TYPES':
      return { ...state, notificationTypes: action.payload };
    default:
      return state;
  }
}
const NotificationFilterComponent = (props) => {
  const initialState = {
    notificationTypes: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetchnotificationtypes = async () => {
    const res = await props.getNotificationTypes();
    if (res.data.data.results) {
      const modifiedData = res.data.data.results.map((type) => {
        return {
          label: type.notification_name,
          value: type.notification_name,
          id: type.id,
        };
      });
      dispatch({ type: 'SET_NOTIFICATION_TYPES', payload: modifiedData });
    }
  };
  useEffect(() => {
    //API CALL TO fetch the notification Types
    fetchnotificationtypes();
  }, []);
  return (
    <>
      <div id="notifiFilterDiv" className="notifi-filter-div">
        <label>Notification Type</label>
        <NotificationFilter
          {...props}
          isMulti={false}
          options={state.notificationTypes}
        />
      </div>
    </>
  );
};

NotificationFilterComponent.propTypes = {
  getNotificationTypes: PropTypes.func,
};

export default NotificationFilterComponent;
