import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { setTenantApps } from '../../../actions/tenantActions';
import TenantSettings from '../tenant-settings';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const TenantScreen = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.setTenantApps();
  }, []);

  return (
    <>
      <Grid container className={classes.container} style={{marginTop: '2%'}}>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              centered
              aria-label="scrollable auto tabs example"
            >
              {props.tenantApps.map((item, index) => {
                return (
                  <Tab label={item.label} key={item.id} {...a11yProps(index)} />
                );
              })}
            </Tabs>
          </AppBar>
          {props.tenantApps.map((item, idx) => {
            return (
              <TabPanel value={value} index={idx}>
                <TenantSettings id={item.id} />
              </TabPanel>
            );
          })}
        </div>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTenantApps: () => dispatch(setTenantApps()),
  };
};

const mapStateToProps = (state) => {
  return {
    tenantApps: state.tenantReducer.tenantApps,
    overlayLoaderState: state.loaderReducer.overlayLoaderState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantScreen);
