//This component is used for individual Tenant Settings
import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { connect } from 'react-redux';
import {
  setAppSettings,
  updateAppSettings,
} from '../../../actions/tenantActions';
import _ from 'lodash';
import Settings from './settings';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const TenSettings = (props) => {
  const id = props.id;
  useEffect(() => {
    //API to fetch individual APP data
    props.setAppSettings(id);
  }, [id]);

  const updateAppSettings = async (recordObj) => {
    try {
      const res = await props.updateAppSettings(recordObj);
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <>
      {!_.isEmpty(props.appSettings) ? (
        <>
          {props.appSettings[id] &&
            props.appSettings[id].map((group) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={group.group_code}
                    key={group.group_code}
                  >
                    {group.group_code}
                  </AccordionSummary>
                  <AccordionDetails>
                    {group.settings_values.map((setting, index) => {
                      return (
                        <Settings
                          key={setting.id}
                          id={setting.id}
                          label={setting.key_code}
                          value={setting.value}
                          updateFunc={updateAppSettings}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </>
      ) : (
        <p>No data found</p>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appSettings: state.tenantReducer.appSettings,
    overlayLoaderState: state.loaderReducer.overlayLoaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppSettings: (id) => dispatch(setAppSettings(id)),
    updateAppSettings: (recordObj) => dispatch(updateAppSettings(recordObj)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TenSettings);
