import React from "react";
import { useHistory } from "react-router-dom";
import SortButton from "./SortButton";
import { getValueForKey, handleReplace } from "./helper";

const SortByCategory = ({ sortByOptions, disabled }) => {
  const history = useHistory();

  const getUriValue = () => {
    let val = getValueForKey("sortBy");
    try {
      return sortByOptions.find(({ value }) => value === val).value;
    } catch (e) {
      if (val) {
        handleReplace("sortBy", sortByOptions[1].value || "", history);
      }
      return sortByOptions[1].value;
    }
  };

  const onSort = (e) => {
    handleReplace("sortBy", e.target.value || "", history);
  };
  return (
    <div style={{ opacity: disabled ? 0 : 1 }}>
      <SortButton
        id="exCatSortDiv"
        data={sortByOptions}
        callback={onSort}
        value={getUriValue()}
        disabled={disabled}
      />
    </div>
  );
};

export default SortByCategory;
