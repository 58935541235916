import React from 'react'
import PropTypes from 'prop-types'
import SummaryRow from './SummaryRow'
import { Divider } from '@material-ui/core'

const sortOptions = [
    { label: 'A to Z Order', value: 'name' },
    { label: 'No of Images', value: 'count' },
    { label: 'No of Tags', value: 'tag_status' }
]
function SummaryContainer(props) {
    return (
        <React.Fragment>
            {props.categories &&
                props.categories.map((category, i) => (
                    <>
                        {i !== 0 ? <Divider /> : ''}
                        <SummaryRow
                            id={i}
                            key={i}
                            batch={props.batch}
                            category={category}
                            sortOptions={sortOptions}
                        />
                    </>
                ))}
        </React.Fragment>
    )
}

SummaryContainer.propTypes = {
    categories: PropTypes.array
}

export default SummaryContainer
