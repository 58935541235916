import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import "./table.css";
import { Checkbox } from "./extraUtils/Checkbox";

//props for column
// disableSortBy :true   ---to disable sorting
// rowSelection :true    ---to selecta row
// columnchildren :[{ colID:'', childRows:[]}]  ---for nested child rows according to column
// renderSubComp: React Node    --- custom nested componted

function SubRows({ row, rowProps, visibleColumns, expandedColumns, loading }) {
  let data = row.original.rowChildren
    .filter((item) => item.colID === expandedColumns[row.id])
    .map((item) => item.childRows)[0];
  return (
    <>
      {data.map((x, i) => {
        return (
          <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
            {row.cells.map((cell) => {
              let newrow = { ...row, original: x };
              return (
                <td {...cell.getCellProps()}>
                  {cell.render(cell.column.SubCell ? "SubCell" : "Cell", {
                    value: cell.column.accessor && cell.column.accessor(x, i),
                    row: newrow,
                  })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <div className="filterInput">
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search`}
      />
      {/* <span>
        <i className="fa fa-filter filterIcon"></i>
      </span> */}
    </div>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "0.8rem",
        }}
      />
    </span>
  );
}

function Table(props) {
  const columns = useMemo(() => props.columns, []);
  const [data, setRowData] = useState(props.rowdata);
  const [expandedColumns, setExpandedColumns] = useState({});

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    visibleColumns,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    selectedFlatRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: props.selectedRows || {},
      },
      manualPagination: props.paginatorCallback ? true : false,
      getSubRows: (row, relativeIndex) => {
        return [];
      },
      autoResetPage: false,
      autoResetExpanded: false,
      pageCount: props.totalCount
        ? Math.ceil(props.totalCount / 10)
        : Math.ceil(data.length / 10),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      props.rowSelection &&
        hooks.visibleColumns.push((column) => {
          return [
            {
              id: "selection",
              disableSortBy: true,
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => {
                return <Checkbox {...row.getToggleRowSelectedProps()} />;
              },
            },
            ...column,
          ];
        });

      props.expandable &&
        hooks.visibleColumns.push((column) => {
          return [
            {
              id: "expander",
              disableSortBy: true,
              Header: ({
                getToggleAllRowsExpandedProps,
                isAllRowsExpanded,
              }) => (
                <span {...getToggleAllRowsExpandedProps()}>
                  {isAllRowsExpanded ? (
                    <i class="fas fa-chevron-down"></i>
                  ) : (
                    <i class="fas fa-angle-right"></i>
                  )}
                </span>
              ),
              Cell: ({ row }) =>
                row.canExpand ? (
                  <span
                    {...row.getToggleRowExpandedProps({
                      style: {
                        // We can even use the row.depth property
                        // and paddingLeft to indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                      },
                    })}
                  >
                    {row.isExpanded ? (
                      <i class="fas fa-chevron-down"></i>
                    ) : (
                      <i class="fas fa-angle-right"></i>
                    )}
                  </span>
                ) : null,
            },
            ...column,
          ];
        });
    }
  );

  useEffect(async () => {
    if (props.paginatorCallback) {
      setRowData([]);
      let nextPageData = await props.paginatorCallback({ pageIndex, pageSize });
      setRowData(nextPageData);
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setRowData(props.rowdata);
  }, [props.rowdata]);

  const changeSubrows = (cell, row) => {
    let obj = {};
    obj[row.id] = cell.column.id;
    obj = { ...expandedColumns, ...obj };
    setExpandedColumns(obj);
  };

  let tableData = props.showPagination ? page : rows;
  useEffect(() => {
    props.selectedRowHandler &&
      props.selectedRowHandler(selectedFlatRows, selectedRowIds);
  }, [selectedRowIds]);
  return (
    <div>
      <div className="table-container" style={props.style}>
        {props.showSearchBar && (
          <>
            <div
              colSpan={visibleColumns.length}
              style={{
                textAlign: "right",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          </>
        )}
        <table className="maintable" {...getTableProps()}>
          <thead className="table-head">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="table-header" {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {!column.disableSortBy && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i
                                className="fa fa-sort-desc"
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="fa fa-sort-asc"
                                aria-hidden="true"
                              />
                            )
                          ) : (
                            <i className="fa fa-sort" aria-hidden="true" />
                          )}
                        </span>
                      )}

                      <React.Fragment>
                        {column.showFilter ? column.render("Filter") : null}
                      </React.Fragment>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {tableData.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <>
                  <tr
                    {...row.getRowProps()}
                    onClick={() =>
                      props.rowclickHandler &&
                      props.rowclickHandler(row.original)
                    }
                  >
                    {row.cells.map((cell) => {
                      let checkExpandable = false;
                      if (row.original.rowChildren) {
                        let expandedChildren = row.original.rowChildren.filter(
                          (rowCell) => rowCell.colID === cell.column.id
                        );
                        checkExpandable =
                          expandedChildren.length > 0 ? true : false;
                      }
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                            {checkExpandable && (
                              <span
                                className={"expandedPosition"}
                                onClick={() => changeSubrows(cell, row)}
                              >
                                <span
                                  {...row.getToggleRowExpandedProps({
                                    style: {
                                      paddingLeft: "1 rem",
                                    },
                                  })}
                                >
                                  {row.isExpanded &&
                                  expandedColumns[row.id] === cell.column.id ? (
                                    <i
                                      className="fa fa-angle-down fa-lg"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <i
                                      class="fa fa-angle-right fa-lg"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </span>
                            )}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  {row.isExpanded &&
                    (props.renderSubComp
                      ? props.renderSubComp({
                          row,
                          rowProps,
                          visibleColumns,
                          expandedColumns,
                        })
                      : SubRows({
                          row,
                          rowProps,
                          visibleColumns,
                          expandedColumns,
                        }))}
                </>
              );
            })}
          </tbody>
        </table>
        {tableData.length === 0 && (
          <div className="no-data-Container">No data</div>
        )}
      </div>
      {props.showPagination && data.length != 0 && (
        <div className="row">
          <div className="col-md-4 pagination-text">
            <span style={{ fontSize: "12px" }}>
              Showing{" "}
              <strong>
                {pageIndex * pageSize + 1} -{" "}
                {pageIndex * pageSize + page.length} of{" "}
                {props.totalCount ? props.totalCount : data.length}
              </strong>{" "}
            </span>
          </div>
          <div className="col-md-6">
            <div className="pagination-btns">
              <button
                className="btn btn-style"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>
              <button
                className="btn btn-style"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <button
                className="btn btn-style"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>
              <button
                className="btn btn-style"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
          {!props.hidePageSelection && (
            <div className="col-md-2 pagination-slct-pgsize">
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Table;
