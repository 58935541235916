import { SET_NOTIFICATIONS, SET_USER_NOTIFICATION_TYPES } from '../actions/types'

const initialState = {
  notificationData: [],
  userNotificationTypes: [],
  notificationCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notificationData: [...action.payload.results],
        notificationCount: action.payload.count,
      }
    case SET_USER_NOTIFICATION_TYPES: {
      return {
        ...state,
        userNotificationTypes: action.payload,
      }
    }
    default:
      return state
  }
}
