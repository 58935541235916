import React from "react";
import styled from "styled-components";
import VariantImageDropdown from "./VariantImageDropdown";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";

const Header = withStyles({
  root: {
    fontSize: "16px",
    fontWeight: "500",
    color: "rgba(0,0,0,0.75)",
  },
})(Typography);

const VariantView = ({
  listMode,
  data,
  onChangeSelectAttribute,
  reset,
  deleteVariant,
  changesArray,
}) => {
  const classes = useStyles();

  return (
    <>
      <ScrollSync>
        <div
          style={{
            margin: "20px",
            boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 20%)",
            backgroundColor: "rgba(254,253,254,1)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {listMode && (
            <ScrollSyncPane group="horizontal">
              <ListHeaderRow className={classes.scrollbar} listMode={listMode}>
                <div style={{padding: '8px 0 6px 0'}}>
                  <Header>Variant</Header>
                </div>
                {data[0].attributes.map((o) => (
                  <div style={{padding: '8px 0 6px 0'}}>
                    <Header>{o.label}</Header>
                  </div>
                ))}
              </ListHeaderRow>
            </ScrollSyncPane>
          )}
          <ScrollSyncPane group="horizontal">
            <ListGrid listMode={listMode}>
              {data.map((o, i) => (
                <div
                  style={{
                    margin: listMode ? "0px" : "30px",
                    width: listMode ? "1100px" : "unset",
                    display: listMode ? "inline-flex" : "unset",
                    flex: listMode ? "1 1 auto" : "unset",
                  }}
                >
                  <VariantImageDropdown
                    index={i}
                    productDetails={{ product_id: o.id }}
                    imageUrl={o.images[0].cropped}
                    imageUrlHighReso={o.images[0].original}
                    attribute={o.attributes}
                    onChangeSelectAttribute={onChangeSelectAttribute}
                    reset={reset}
                    changesArr={changesArray}
                    listView={listMode}
                    deleteVariant={deleteVariant}
                  />
                </div>
              ))}
            </ListGrid>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    </>
  );
};

export default VariantView;

const ListGrid = styled.div`
  display: inline-flex;
  justify-content: start;
  flex-flow: ${(props) => (props.listMode ? "column" : "row")};
  flex-wrap: wrap;
  height: fit-content;
  overflow-y: visible;
  width: 1100px;
  overflow-x: ${(props) => (props.listMode ? "auto" : "unset")};
  &::-webkit-scrollbar {
    height: 0px;
  }
`;
const ListHeaderRow = styled.div`
  width: 1100px;
  // height: 50px;
  background-color: rgba(255,255,255);
  display: inline-box;
  justify-content: ${(props) => (props.listMode ? "center" : "start")};
  align-items: center;
  overflow-x: ${(props) => (props.listMode ? "auto" : "unset")};
  div {
    width: 220px;
    height: 40px;
    text-align: center;
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 4px 0px;
  }
  div:first-child {
    width: 280px;
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  div:last-child: {
    border-right: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    position: "sticky",
    top: "50px",
    overflowX: "auto",
    zIndex: 4,
    background: "#fff",
    "&::-webkit-scrollbar": {
      height: "15px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundClip: "padding-box",
      border: "2px solid transparent",
      borderRadius: "10px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgb(112, 112, 112)",
    },

    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "rgb(128, 128, 128)",
    },
    "&::-webkit-scrollbar-button:single-button": {
      backgroundColor: "#98989840",
      display: "block",
      backgroundSize: "10px",
      backgroundRepeat: "no-repeat",
    },
    "&::-webkit-scrollbar-button:single-button:horizontal:decrement": {
      height: "12px",
      width: "12px",
      backgroundPosition: "3px 3px",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:decrement:hover": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    " &::-webkit-scrollbar-button:single-button:horizontal:decrement:active": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:increment": {
      height: "12px",
      width: "12px",
      backgroundPosition: "3px 3px",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },

    "&::-webkit-scrollbar-button:single-button:horizontal:increment:hover": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },

    " &::-webkit-scrollbar-button:single-button:horizontal:increment:active": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>")`,
    },
  },
}));
