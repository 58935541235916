import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const ActionBtns = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.actions &&
            props.actions.map((action) => {
              return (
                <MenuItem>
                  <ListItemText
                    primary={action.replace(action[0], action[0].toUpperCase())}
                    onClick={() => {
                      props.performAction(props.row.original, action);
                      handleClose();
                    }}
                  />
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    </>
  );
};

export default ActionBtns;
