import React from "react";
import ProductDetailButton from "./ProductDetailButton";

const ProductDetailActions = ({
  saveEdits,
  handlePath,
  onChangeSelectAttribute,
  loading,
}) => {
  return (
    <div
      style={{
        display: "flex",
        paddingTop: "10px",
        justifyContent: "space-between",
        margin: "0 20px 0 20px",
        padding: "30px 0 40px 0",
      }}
    >
      <div>
        <ProductDetailButton
          label="Save & Next"
          onClickHandler={() => saveEdits()}
          type={1}
          loading={loading}
          id="saveBtn"
        />
        &nbsp;&nbsp;
        <ProductDetailButton
          label="Cancel"
          onClickHandler={() => onChangeSelectAttribute("", {}, "reset")}
          type={0}
          loading={loading}
          id="cancelBtn"
        />
      </div>
      <ProductDetailButton
        label="Go Back"
        onClickHandler={handlePath}
        type={0}
        loading={loading}
        id="goBackBtn"
      />
    </div>
  );
};

export default ProductDetailActions;
