import React, { Component } from 'react';
import { calendarConfig } from '../../../../config';
import moment from 'moment';
import _ from 'lodash';

const { fstMoOfYr } = calendarConfig;
const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const nrfWksPatternForAYr = [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
const moJanIdx = 0;
const _4Wks = 4;
const _5Wks = 5;
const nrfCalYrs = {};

function generateNrfCalYrs(yr, nrfCalYrs) {
  const noOfDaysInAWk = days.length;
  const noOfMosInAYr = nrfWksPatternForAYr.length;
  const stDt = moment().year(yr).week(1).startOf('week');

  const doesJanHaveFiveWks = moment().year(yr).weeksInYear() === 53;

  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _5Wks);

  // Inserting Dates by forward calculating from the start date
  let nxtDt = moment(stDt);
  for (let moIdx = fstMoOfYr; moIdx < noOfMosInAYr + fstMoOfYr; moIdx++) {
    !nrfCalYrs[yr] && (nrfCalYrs[yr] = {});
    moIdx > 11 && !nrfCalYrs[yr + 1] && (nrfCalYrs[yr + 1] = {});
    const momentMoIdx = moment().month(moIdx).month();
    const moDts = [];
    for (let nrfWks = 0; nrfWks < nrfWksPatternForAYr[momentMoIdx]; nrfWks++) {
      for (let dayIdx = 0; dayIdx < noOfDaysInAWk; dayIdx++) {
        if (dayIdx === 0) {
          moDts[nrfWks] = [];
        }
        moDts[nrfWks].push(moment(nxtDt));
        nxtDt = nxtDt.add(1, 'days');
      }
    }
    moIdx < 12 ? (nrfCalYrs[yr][momentMoIdx] = moDts) : (nrfCalYrs[yr + 1][momentMoIdx] = moDts);
  }

  // Setting no of weeks for Jan back to default
  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _4Wks);
  return nrfCalYrs;
}

const Calendar = function (props) {
  const getCalendarCellStyle = (dt, stDt, endDt) => {
    let className = 'calendar-date_default';
    if (props.startDate && !props.endDate) {
      dt.isSame(props.startDate) && (className = 'selected-date');
      dt.isAfter(props.startDate) &&
        dt.isSameOrBefore(props.mouseOveredDt) &&
        (className = 'calendar-date_hovered');
    } else if (props.startDate && props.endDate) {
      (dt.isSame(props.startDate) || dt.isSame(props.endDate)) && (className = 'selected-date');
      dt.isAfter(props.startDate) &&
        dt.isBefore(props.endDate) &&
        (className = 'calendar-date_hovered');
      props.mouseOveredDt &&
        (dt.isBefore(props.startDate) || dt.isAfter(props.endDate)) &&
        props.mouseOveredDt.isBetween(
          moment(stDt).subtract(1, 'days'),
          moment(endDt).add(1, 'days')
        ) &&
        (className = 'calendar-date_default-hover');
    } else {
      props.mouseOveredDt &&
        props.mouseOveredDt.isBetween(
          moment(stDt).subtract(1, 'days'),
          moment(endDt).add(1, 'days')
        ) &&
        (className = 'calendar-date_default-hover');
    }
    return className;
  };
  return (
    <div>
      <div className="t-a-c mt-2">
        {props.month.format('MMM')} {props.month.format('YYYY')}
      </div>
      <table className="m-3">
        <tbody>
          <tr>
            <th></th>
            {props.nrfCalDays &&
              props.nrfCalDays[0] &&
              _.map(props.nrfCalDays[0], (day, idx) => (
                <th className="calendar-cell-size calendar-day" key={idx}>
                  {day.format('dd')}
                </th>
              ))}
          </tr>
          {props.nrfCalDays &&
            _.map(props.nrfCalDays, (week, idx) => (
              <tr key={idx}>
                <th className="calendar-cell-size calendar-date">
                  {props.nrfCalDays[idx][0].week()}
                </th>
                {week.map((dt, idx) => {
                  return (
                    <td
                      key={idx}
                      onClick={() => {
                        if (!props.startDate) {
                          props.onClick(week[0]);
                        } else {
                          if (dt.isBefore(props.startDate)) {
                            props.onClick(week[0]);
                          } else {
                            props.onClick(week[6]);
                          }
                        }
                      }}
                      onMouseOver={() => {
                        if (props.startDate) {
                          if (dt.isSameOrAfter(props.startDate)) {
                            props.onMouseOver(week[6]);
                          } else {
                            props.onMouseOver(week[0]);
                          }
                        } else {
                          props.onMouseOver(week[0]);
                        }
                      }}
                      className={`calendar-cell-size ${getCalendarCellStyle(
                        dt,
                        week[0],
                        week[6]
                      )} calendar-date`}>
                      {dt.date()}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

class MultipleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOveredDt: null,
    };
  }
  render() {
    return (
      <div style={{ display: 'flex' }}>
        {this.props.months.map((month, idx) => {
          return (
            <Calendar
              key={idx}
              month={month}
              onClick={(dt) => {
                this.props.onClick(dt);
              }}
              onMouseOver={(dt) => {
                this.setState({
                  mouseOveredDt: dt,
                });
              }}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              mouseOveredDt={this.state.mouseOveredDt}
              nrfCalDays={
                this.props.nrfCalYrs[month.year()] &&
                this.props.nrfCalYrs[month.year()][month.month()]
              }
            />
          );
        })}
      </div>
    );
  }
}

class RetailCalendar extends Component {
  constructor(props) {
    super(props);
    const curDt = props.startDate || moment();
    const dispMosCnt = 2; //min:1, max:12
    const width = { width: `${344 * dispMosCnt}px` };
    let dispMos = [];
    generateNrfCalYrs(curDt.year(), nrfCalYrs);
    if (curDt.month() === 11) generateNrfCalYrs(curDt.year() + 1, nrfCalYrs);
    for (let i = 0; i < dispMosCnt; i++) {
      dispMos.push(moment(curDt).add(i, 'month'));
    }
    this.state = {
      dispMos,
      curDt,
      financialYear: curDt.month() === 11 ? curDt.year() + 1 : curDt.year(),
      width,
    };
  }

  onPrevMoClick = () => {
    const fstMo = this.state.dispMos[0];
    if (fstMo.month() === fstMoOfYr) {
      this.onPrevYrClick();
    }
    const sftdMosBkByOneMo = this.state.dispMos.map((month) => {
      return month.subtract(1, 'month');
    });
    this.setState({ dispMos: sftdMosBkByOneMo });
  };
  onNxtMoClick = () => {
    const { dispMos } = this.state;
    const lstMo = dispMos[dispMos.length - 1];
    const lstMoOfYr = moment()
      .month(fstMoOfYr - 1)
      .month();
    if (lstMo.month() === lstMoOfYr) {
      this.onNxtYrClick();
    }
    const sftdMosFwdByOneMo = this.state.dispMos.map((month) => {
      return month.add(1, 'month');
    });
    this.setState({ dispMos: sftdMosFwdByOneMo });
  };
  onPrevYrClick = () => {
    const { financialYear: yr } = this.state;
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(prevYr, nrfCalYrs);
    this.setState({ financialYear: prevYr });
    if (nrfCalYrs.hasOwnProperty(nxtYr)) delete nrfCalYrs[nxtYr];
  };
  onNxtYrClick = () => {
    const { financialYear: yr } = this.state;
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(nxtYr, nrfCalYrs);
    this.setState({ financialYear: nxtYr });
    if (nrfCalYrs.hasOwnProperty(prevYr)) delete nrfCalYrs[prevYr];
  };
  render() {
    return (
      <div className="m-3" style={{ ...this.state.width }}>
        <div className="arrow left-arrow">
          <i
            onClick={this.onPrevMoClick}
            className="dr-calendar-month-arrow fa fa-angle-left"
            aria-hidden="true"></i>
        </div>
        <div className="arrow right-arrow">
          <i
            onClick={this.onNxtMoClick}
            className="dr-calendar-month-arrow fa fa-angle-right"
            aria-hidden="true"></i>
        </div>
        <MultipleCalendar
          months={this.state.dispMos}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          nrfCalYrs={nrfCalYrs}
          onClick={(dt) => {
            if (dt.month() === 11) this.onNxtYrClick();
            this.props.onClick(dt);
          }}
        />
      </div>
    );
  }
}

export default RetailCalendar;
