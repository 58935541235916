import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { blue, deepOrange, orange } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import clsx from 'clsx'
import { downloadErrorReport } from '../../../actions/dashboardActions'

import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        position: 'absolute',
        width: 36,
        height: 36,
        top: '50%',
        left: '0%',
        transform: 'translate(-110%, -50%)'
    },
    buttonSuccess: {
        backgroundColor: blue[400],
        '&:hover': {
            backgroundColor: blue[700]
        }
    },
    fapButton: {
        width: 36,
        height: 36,
        backgroundColor: orange[50],
        boxShadow: 'none',
        color: deepOrange[400],
        '&:hover': {
            backgroundColor: orange[100]
        }
    },
    fabProgress: {
        color: orange[600],
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1
    },
    buttonProgress: {
        color: blue[100],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}))

const ErrorButton = (props) => {
    const classes = useStyles()
    const [isLoading, setisLoading] = React.useState(false)

    const handleClick = () => {
        setisLoading(true)
        props.downloadErrorReport(
            props.rowId,
            props.failedCount,
            props.batchName,
            handleLoading
        )
    }

    const handleLoading = (params) => {
        setisLoading(params)
    }


    const buttonClassname = clsx({
        [classes.fapButton]: true,
        [classes.buttonSuccess]:
            props.isDownloadingError && props.id === props.rowId
    })

    return (
        <>
            <div className={classes.wrapper}>
                <Fab
                    aria-label="delete batch"
                    className={buttonClassname}
                    //onClick={handleButtonClick}
                    id={'errorReport' + props.rowId}
                    color={props.color}
                    disabled={props.disabled}
                    onClick={handleClick}
                >
                    <WarningRoundedIcon fontSize="small" />
                </Fab>
                {isLoading && (
                    <CircularProgress
                        size={38}
                        className={classes.fabProgress}
                    />
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isDownloadingError: state.dashboardReducer.isDownloadingError,
    id: state.dashboardReducer.id
})

export default connect(mapStateToProps, { downloadErrorReport })(ErrorButton)
