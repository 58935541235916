import React from "react";
import { Box, Typography } from "@material-ui/core";
import CategoryPagination from "./CategoryPagination";
import SortByCategory from "./SortByCategory";
import ShowAllSwitch from "./ShowAllSwitch";
import { fetchPayload } from "./helper";

const sortByOptions1 = [
  { id: 1, value: "1", label: "Product Confidence (low-high)" },
  { id: 2, value: "-1", label: "Product Confidence (high-low)" },
];

const sortByOptions2 = [
  { id: 1, value: "1", label: "Product Confidence (low-high)" },
  { id: 2, value: "-1", label: "Product Confidence (high-low)" },
  { id: 3, value: "2", label: "Attribute Confidence (low-high)" },
  { id: 4, value: "-2", label: "Attribute Confidence (high-low)" },
];

const checkNoFilter = () => {
  const payload = fetchPayload();
  const filters = payload?.filters;
  if (filters) {
    return filters?.some(({ id }) => !isNaN(id));
  }
  return false;
};

const ProductListHeader = (props) => {
  const sortToggler = () => {
    if (
      (props.singleEdit && !props.list) ||
      (props.list && !props.listattribute && checkNoFilter())
    ) {
      return sortByOptions2;
    }

    return sortByOptions1;
  };

  return (
    <Box
      id="exCatToolbar"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Typography variant="caption" style={{ opacity: props.count ? 1 : 0 }}>
          Showing
        </Typography>
        <CategoryPagination count={props.count} />
      </Box>
      <ShowAllSwitch
        labelLeft="Product View"
        labelRight="Variant View"
        urlKey="type"
        name="view"
      />
      <SortByCategory sortByOptions={sortToggler()} />
    </Box>
  );
};

export default ProductListHeader;
