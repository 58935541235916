/**
 * App Global Styles, Themes, Constants go below
 */

export const APP_PLATFORM = {
  APP_NAME: 'IA Smart Platform',
};

export const POSTHOG_KEY = '52vkz2GHhslnKOeUfI2Vnmq3ls6KJeTWWJiAQ6SCFAg'


/**
 * Constants for the App Attribute Smart are defined below
 */
export const APP_ATTRIBUTE_SMART = {
  APP_NAME: 'AttributeSmart',
  ATTRIBUTE_SMART_SAMPLE_CSV:
    'https://storage.googleapis.com/iss-prod-4673-public-prod/sample_csv/dsg_sample.csv',
};

export const APP_MARKSMART = {
  APP_NAME: 'MarkSmart'
}
