import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
    overflow: "hidden",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  select: {
    minWidth: 150,
  },
  tableContainer: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  warning: {
    color: "red",
    paddingLeft: "20px",
  },
  saveButton: {
    margin: theme.spacing(1),
  },
}));

export default function FormTable(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [errorText, setErrorText] = useState(
    "This combination already exist. Please try with a new combination"
  );
  const [rowData, setRowData] = useState([]);
  const [savedData, setSavedData] = useState([...props.rowData]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setSavedData([...props.rowData]);
    return () => {};
  }, [props.rowData]);

  const addNewState = () => {
    if (props.transitions) {
      let sourceState = props.optSelect1
        .filter((item) => item.id === formData.source_state_id)
        .map((item) => item.label)[0];
      let destinationState = props.optSelect2
        .filter((item) => item.id === formData.destination_state_id)
        .map((item) => item.label)[0];
      let dupPair = rowData.filter(
        (item) =>
          item.source_state_id === formData.source_state_id &&
          item.destination_state_id === formData.destination_state_id
      );
      if (dupPair.length) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
        const newPair = {
          source_state_id: formData.source_state_id,
          source_state_label: sourceState,
          destination_state_label: destinationState,
          destination_state_id: formData.destination_state_id,
        };
        if (sourceState && destinationState) {
          setFormData({});
          setRowData([...rowData, newPair]);
        } else {
          setErrorText("Please enter some values in both fields.");
          setShowWarning(true);
        }
      }
    } else if (props.workflow) {
      let dupPair = rowData.filter(
        (item) => item.field_name === formData.field_name
      );
      if (dupPair.length > 0) {
        setErrorText("Please provide different name. It already exist");
        setShowWarning(true);
      } else {
        let initial_state_label = props.optSelect
          .filter((item) => item.id === formData.initial_state_id)
          .map((item) => item.label)[0];
        const newState = {
          field_name: formData.field_name,
          initial_state_id: formData.initial_state_id,
          initial_state_label,
        };
        if (formData.field_name) {
          setShowWarning(false);
          setRowData([...rowData, newState]);
          setFormData({});
        } else {
          setErrorText("Please enter some value in name field.");
          setShowWarning(true);
        }
      }
    } 
    else {
      let dupPair = rowData.filter((item) => item.label === formData.name);

      if (dupPair.length > 0) {
        setErrorText("Please provide different name. It already exist");
        setShowWarning(true);
      } else {
        const newState = {
          label: formData.name,
          description: formData.description,
        };
        if (formData.name) {
          setShowWarning(false);
          setRowData([...rowData, newState]);
          setFormData({});
        } else {
          setErrorText("Please enter some value in name field.");
          setShowWarning(true);
        }
      }
    }
  };

  const removeState = (e) => {
    let statesCopy = [...rowData];
    statesCopy.splice(e, 1);
    setRowData([...statesCopy]);
  };

  const handleChange = (e) => {
    let obj = { ...formData };
    obj[e.target.name] = e.target.value;
    setFormData(obj);
  };

  const saveRequest = async () => {
    let data = [...rowData];

    if (data.length > 0) {
      let savedObj = await props.saveRequest(data);
      if (savedObj.status) {
        setFormData({})
        setRowData([])
        setErrorText('')
        setShowWarning(false)
      } else {
        setErrorText(savedObj.message);
        setShowWarning(true);
      }
    } else {
      setErrorText("Nothing to save. Please add some data");
      setShowWarning(true);
    }
  };

  const onBlur = () => {
    let dupPair = rowData.filter((item) => item.label === formData.name);
    if (dupPair.length > 0) {
      setErrorText("Please provide different name. It already exist");
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const renderForm = (item,index) => {
    switch (item.type) {
      case "textfield":
        return (
          <TextField
          key={index}
            className={classes.formControl}
            id={`new${item.name}`}
            onBlur={item.showValidationonBlur ? onBlur : null}
            onChange={handleChange}
            value={formData[item.name] || ""}
            name={item.name}
            label={item.label}
          />
        );
      case "select":
        return (
          <FormControl  key={index} className={classes.formControl}>
            <InputLabel id="open-select-label">{item.label}</InputLabel>
            <Select
              className={classes.select}
              name={item.name}
              label={item.label}
              onChange={handleChange}
              data-testid={`select${item.name}`}
              value={formData[item.name] || ""}
            >
              {item.options.map((option, index) => {
                return (
                  <MenuItem   data-testid={`option-${index}`}  key={index} value={option.id}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
    }
  };

  return (
    <div>
  { !props.hideCreateSegment && <div> 
     <Typography className={classes.heading}>Create {props.title} </Typography>
      {addForm && (
        <form noValidate className={classes.form} autoComplete="off">
          {props.form.map((formItem ,index) => {
            return renderForm(formItem,index);
          })}
          <Button variant="outlined" color="primary" onClick={addNewState}>
            Add
          </Button>
          {showWarning && <div role='errorBox' className={classes.warning}>{errorText} </div>}
        </form>
      )}

      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.columns &&
                props.columns.map((item ,index) => {
                  return <TableCell key={index} >{item.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row, i) => (
              <TableRow key={i}>
                {props.columns &&
                  props.columns.map((col,index) => {
                    return (
                      <TableCell key={index}  component="th" scope="row">
                        {row[col.key]}
                      </TableCell>
                    );
                  })}
                <TableCell>
                  <DeleteIcon data-testid={`delete-${i}`}  id={i} onClick={() => removeState(i)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        className={classes.saveButton}
        color="primary"
        onClick={saveRequest}
      >
        Save
      </Button>
      </div> 
}

      <Typography className={classes.heading}> {props.title} </Typography>

      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.columns &&
                props.columns.map((item,index) => {
                  return <TableCell key={index}  >{item.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {savedData.map((row, i) => (
              <TableRow key={row.name}>
                {props.columns &&
                  props.columns.map((col,index) => {
                    return (
                      <TableCell  key={index}  component="th" scope="row">
                        {row[col.key]}
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

FormTable.propTypes = {
  rowData: PropTypes.any.isRequired,
};
