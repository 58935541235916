import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'

import { APP_ATTRIBUTE_SMART } from '../../../../../config/constants'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    emphasize,
    FormControl,
    InputLabel
} from '@material-ui/core'
import PublishSharpIcon from '@material-ui/icons/PublishSharp'
import Input from '@material-ui/core/Input'
import Link from '@material-ui/core/Link'
import { debounce } from 'lodash'
import BatchUpload from './BatchUpload'
import MuiAlert from '@material-ui/lab/Alert'
import { blue } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        boxShadow: theme.shadows[5]
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    input: {
        fontSize: 'small',
        marginTop: '0 !important'
    },
    status: {
        fontSize: 12,
        fontWeight: 400
    },
    inputLabel: {
        top: '50%',
        left: -theme.spacing(11),
        transform: 'translateY(-50%)',
        fontSize: 14
    },
    formControl: {
        marginLeft: theme.spacing(11)
    },
    downloadButton: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300]
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12)
        }
    },
    alert: {
        fontSize: 10,
        padding: '0 4px',
        '& .MuiAlert-icon': {
            padding: '3px 0',
            marginRight: 3
        },
        '& .alert-label': {
            textTransform: 'uppercase',
            paddingRight: 4
        }
    },
    uploadButton: {
        // backgroundColor: blue[400],
        // color: '#ffffff',
        // '&:hover': {
        //     backgroundColor: blue[700]
        // }
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UploadButton = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [batchName, setbatchName] = useState('')
    const [status, setstatus] = useState('')

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setstatus('')
    }

    const handleChange = (event) => {
        updateBatchName(event)
    }

    const updateBatchName = debounce((event) => {
        setbatchName(event.target.value)
    }, 300)

    const updateStatus = (params) => {
        setstatus(params)
    }

    const onLoad = (params) => {
        handleClose()
        if(props.pageNo===0) {
            props.onSuccess(0)
        } else {
            props.onPageChange(0)
        }
    }

    return (
        <div id="dashboardHeaderActions">
            <Button
                variant="contained"
                size="small"
                style={{ minWidth: 120 }}
                startIcon={<PublishSharpIcon />}
                id={'dashboardUploadBtn'}
                onClick={handleOpen}
                className={classes.uploadButton}
                color="primary"
            >
                Upload
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <DialogTitle variant="button">
                            <div className={classes.title}>
                                <span>Upload Batch</span>
                                <span className={classes.status}>
                                    <StatusMessage
                                        message={status}
                                        className={classes.alert}
                                    />
                                </span>
                            </div>
                        </DialogTitle>

                        <DialogContent>
                            <Box
                                component="div"
                                display="flex"
                                justifyContent="space-between"
                                my={2}
                            >
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        className={classes.inputLabel}
                                        shrink
                                        htmlFor="upldInputBatchName"
                                    >
                                        Batch Name
                                    </InputLabel>
                                    <Input
                                        className={classes.input}
                                        id="upldInputBatchName"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <Link
                                    color="primary"
                                    variant="body2"
                                    href={
                                        APP_ATTRIBUTE_SMART.ATTRIBUTE_SMART_SAMPLE_CSV
                                    }
                                    id='DownloadLinkSample'
                                >
                                    Download Input Template
                                </Link>
                            </Box>
                            <Box my={2}>
                                <BatchUpload
                                    batch={batchName}
                                    status={updateStatus}
                                    onSuccess={onLoad}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box paddingX={2}>
                                <Button
                                    autoFocus
                                    onClick={handleClose}
                                    color="primary"
                                    id='UploadTabCloseBtn'
                                >
                                    Close
                                </Button>
                            </Box>
                        </DialogActions>
                    </div>
                </Fade>
            </Dialog>
        </div>
    )
}

export default UploadButton

const StatusMessage = ({ message, className }) => {
    if (message?.type) {
        const label =
            message?.label.split('_').length > 1
                ? message?.label.split('_').join(' ')
                : message?.label
        return (
            <Alert className={className} severity={message?.type}>
                <span className="alert-label">{label}</span>
                <span>{message?.value}</span>
            </Alert>
        )
    }

    return ''
}
