import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ObjectToQueryString, QueryStringToObject } from "./helper";
import { blue } from "@material-ui/core/colors";

const Status = [
  { label: "All", show: true },
  { label: "Reviewed", show: true },
  { label: "Pending", show: true },
];

const useStyles = makeStyles(() => ({
  tabWrapper: {
    position: "relative",
    width: "100%",
  },
  root: {
    height: 44,
    minHeight: 44,
  },
  tabroot: {
    paddingBottom: 0,
    minWidth: 80,
    fontSize: "0.80rem",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontWeight: 500,
  },
  indicator: {
    backgroundColor: blue[400],
    minWidth: 80,
  },
  textColorPrimary: {
    color: `${blue[400]} !important`,
  },
}));

export default function ProductCategoryTabs() {
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (_, value) => {
    let URIparams = QueryStringToObject();
    history.push({
      search: `${ObjectToQueryString({
        ...URIparams,
        status: value,
        page: 1,
      })}`,
    });
  };

  return (
    <Tabs
      value={QueryStringToObject().status || "All"}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      classes={{
        indicator: classes.indicator,
        root: classes.root,
        fixed: classes.fixed,
      }}
    >
      {Status.map(({ label, show }) => {
        return (
          <Tab
            key={label}
            label={label}
            value={label}
            disabled={!show}
            classes={{
              root: classes.tabroot,
              selected: classes.textColorPrimary,
            }}
            id={label}
          />
        );
      })}
    </Tabs>
  );
}
