import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getgroups,
  setGroups,
  getroles,
  getusers,
  setRoles,
  setUsers,
  setGrpDataFetchStatus,
  setUserDataFetchStatus,
  setRoleDataFetchStatus,
  ToggleLoader,
} from "../../../actions/adminActions";
import UserRoleGrpSummary from "./userRoleSummary";
import GroupRoleTables from "./roleGroupTables";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "./usrrolegrp.scss";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles({
  hrStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
});
const UserRoleGrpMapper = (props) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      props.toggleLoader(true);
      try {
        if (!props.isUserDataFetched) {
          const userResponse = await props.getUsers();
          props.setUsers(userResponse.data.data);
          props.setUserDataFetchStatus();
        }
        if (!props.isGrpDataFetched) {
          const grpResponse = await props.getGroups();
          props.setGroups(grpResponse.data.data);
          props.setGrpDataFetchStatus();
        }
        if (!props.isRoleDataFetched) {
          const roleResponse = await props.getRoles();
          props.setRoles(roleResponse.data.data);
          props.setRoleDataFetchStatus();
        }
        props.toggleLoader(false);
        setShowComponent(true);
      } catch (error) {
        seterrorMsg("Something went wrong!");
        setOpen(true);
        setShowComponent(false);
        props.toggleLoader(false);
      }
    };
    fetchData();
  }, []);
  const handleClose = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }
    setOpen(false);
    seterrorMsg("");
  };
  return (
    <>
      {showComponent ? (
        <div className="usr-role-grp-container">
          <UserRoleGrpSummary
            handleUserSelect={(option) => setSelectedUser(option)}
          />
          {selectedUser && <hr className={classes.hrStyle} />}
          {selectedUser && (
            <GroupRoleTables
              selectedUser={selectedUser}
              handleReset={() => setSelectedUser("")}
            />
          )}
        </div>
      ) : (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "50vh" }}
          >
            Can't Fetch Data
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
          >
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isGrpDataFetched: state.adminReducer.isGrpDataFetched,
    isRoleDataFetched: state.adminReducer.isRoleDataFetched,
    isUserDataFetched: state.adminReducer.isUserDataFetched,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (loadStatus) => dispatch(ToggleLoader(loadStatus)),
    getGroups: () => dispatch(getgroups()),
    setGrpDataFetchStatus: () => dispatch(setGrpDataFetchStatus()),
    setUserDataFetchStatus: () => dispatch(setUserDataFetchStatus()),
    setRoleDataFetchStatus: () => dispatch(setRoleDataFetchStatus()),
    getRoles: () => dispatch(getroles()),
    setGroups: (groups) => dispatch(setGroups(groups)),
    getUsers: () => dispatch(getusers()),
    setRoles: (roles) => dispatch(setRoles(roles)),
    setUsers: (users) => dispatch(setUsers(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleGrpMapper);
