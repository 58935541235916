import { ExportToCsv } from "export-to-csv";

export const GET_ERRORS = "GET_ERRORS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const DATA_LOADING = "DATA_LOADING";
export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_CHART_DATA_FILTERS = "GET_CHART_DATA_FILTERS";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const GET_EDA_CHART_DATA = "GET_EDA_CHART_DATA";
export const CLEAR_EDA_CHART_DATA = "CLEAR_EDA_CHART_DATA";
// export const CLEAR_FILTERS_DATA = 'CLEAR_FILTERS_DATA'
export const AUTO_PROMO_DATA = "AUTO_PROMO_DATA";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SET_TENANT_ID = "SET_TENANT_ID";
export const SOME_ACTION = "SOME_ACTION";
export const GET_EDA_CHART_DATA_FILTERS = "GET_EDA_CHART_DATA_FILTERS";
export const GET_EDA_DEPART_DATA_FILTERS = "GET_EDA_DEPART_DATA_FILTERS";
export const GET_EDA_ERRORS = "GET_EDA_ERRORS";
export const GET_BUDGET_DATA = "GET_BUDGET_DATA";
export const GET_BUDGET_CALENDAR_DATA = "GET_BUDGET_CALENDAR_DATA";
export const GET_BUDGET_GRAPH_DATA = "GET_BUDGET_GRAPH_DATA";
export const GET_BUDGET_EDIT = "GET_BUDGET_EDIT";
export const AUTO_GET_NEW_OPTIMIZED_DATA = "AUTO_GET_NEW_OPTIMIZED_DATA";
export const SKU_IDS = "SKU_IDS";
export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA";
export const GET_INSEASON_DATA = "GET_INSEASON_DATA";
export const GET_INSEASON_DATA_ERROR = "GET_INSEASON_DATA_ERROR";
export const GET_INSEASON_DATA_SUCCESS = "GET_INSEASON_DATA_SUCCESS";
export const INVENTORY_DATA_BY_FILTERED = "INVENTORY_DATA_BY_FILTERED";
export const SKU_COCKPIT_LINE_DATA = "SKU_COCKPIT_LINE_DATA";
export const GET_SKUSELECTION_DATA = "GET_SKUSELECTION_DATA";
export const SIMULATE_DATA_FOR_SKU = "SIMULATE_DATA_FOR_SKU";
export const FLUSH_COCKPIT_DATA = "FLUSH_COCKPIT_DATA";
// export const SHOW_ERROR = 'SHOW_ERROR'

/**
 * Ai Assisted in sseason
 */
export const GET_STORE_INVENTORY = "@@aiAssistedInSeason/GET_STORE_INVENTORY";
export const SIMULATE_CADENCE_SKU = "@@aiAssistedInSeason/SIMULATE_IN_SEASON";
export const FLUSH_STORE_DATA = "@@aiAssistedInSeason/FLUSH_STORE_DATA";
export const SIMULATE_CADENCE_SKU_LOADING =
  "@@aiAssistedInSeason/SIMULATE_CADENCE_SKU_LOADING";
export const SIMULATE_PRE_SEASON_MARKDOWN_DATA =
  "@@aiAssistedInSeason/SIMULATE_PRE_SEASON_MARKDOWN_DATA";
export const SAVE_OVERALL_MARKDOWN =
  "@@saveOverAllMarkdown/SAVE_OVERALL_MARKDOWN";
export const RESET_MARKDOWN_SAVED =
  "@@saveOverAllMarkdown/RESET_MARKDOWN_SAVED";
export const SAVE_SKU_MARKDOWN = "@@saveSKUMarkdown/SAVE_SKU_MARKDOWN";
// export const SHOW_ERROR = 'SHOW_ERROR'

export const GET_ALL_CASCADING_FILTERS = "GET_ALL_CASCADING_FILTERS";
export const GET_ALL_CASCADING_FILTERS_SUCCESS =
  "GET_ALL_CASCADING_FILTERS_SUCCESS";
export const GET_ALL_CASCADING_FILTERS_ERR = "GET_ALL_CASCADING_FILTERS_ERR";
export const PERSIST_CASCADING_FILTERS = "PERSIST_CASCADING_FILTERS";

// markdown calendar

export const GET_MARKDOWN_CALENDARDATA = "GET_MARKDOWN_CALENDARDATA";
export const GET_MARKDOWN_CALENDARPLOTDATA = "GET_MARKDOWN_CALENDARPLOTDATA";
//markdown config data
export const GET_MARKDOWN_CONFIG_DATA = "GET_MARKDOWN_CONFIG_DATA";
export const CREATE_MARKDOWN_SKU_GROUP = "CREATE_MARKDOWN_SKU_GROUP";
export const UPDATE_MARKDOWN_GROUP = "UPDATE_MARKDOWN_GROUP";
export const DELETE_MARKDOWN_GROUP = "DELETE_MARKDOWN_GROUP";
export const MARKDOWN_SKUGROUP_GRAPH_DATA = "MARKDOWN_SKUGROUP_GRAPH_DATA";

//pricing
export const GET_PRICING_TABLE_DATA = "GET_PRICING_TABLE_DATA";

//recommended testing
export const GET_RECCOMENDED_TESTING_FILTER_LOADING =
  "GET_RECCOMENDED_TESTING_FILTER_LOADING";
export const GET_RECCOMENDED_TESTING_FILTER_SUCCESS =
  "GET_RECCOMENDED_TESTING_FILTER_SUCCESS";
export const GET_RECCOMENDED_TESTING_FILTER_ERROR =
  "GET_RECCOMENDED_TESTING_FILTER_ERROR";

export const GENERIC_FILTER = "GENERIC_FILTER";
export const GENERIC_FILTER_SUCCESS = "GENERIC_FILTER_SUCCESS";
export const GENERIC_FILTER_ERR = "GENERIC_FILTER_ERR";

export const COMPETITORS_PRICING = "COMPETITORS_PRICING";
export const COMPETITORS_PRICING_SUCCESS = "COMPETITORS_PRICING_SUCCESS";
export const COMPETITORS_PRICING_FAILURE = "COMPETITORS_PRICING_FAILURE";

// markdown global promo data
export const GET_MARKDOWN_GLOBAL_PROMO_DATA =
  "@@globalPromo/GET_MARKDOWN_GLOBAL_PROMO_DATA";
export const SAVE_GLOBAL_PROMO = "@@globalPromo/SAVE_GLOBAL_PROMO";
export const GET_MARKDOWN_GLOBAL_PROMO_DATA_PER_MARKDOWN =
  "GET_MARKDOWN_GLOBAL_PROMO_DATA_PER_MARKDOWN";
export const LOADER = "LOADER";

// Actual Vs Planned Reports
export const GET_PLANNED_REPORTS_SUCCESS = "GET_PLANNED_REPORTS_SUCCESS";
export const GET_PLANNED_REPORTS_ERROR = "GET_PLANNED_REPORTS_ERROR";

// Summary
export const SET_KPI_DATA = "SET_KPI_DATA";

//Admin
export const SET_ROLE_OPTIONS = "SET_ROLE_OPTIONS";
export const SET_ATTRIBUTE_OPTIONS = "SET_ATTRIBUTE_OPTIONS";
export const SET_PERMISSION_OPTIONS = "SET_PERMISSION_OPTIONS";
export const SET_USER_OPTIONS = "SET_USER_OPTIONS";
export const SET_GROUP_OPTIONS = "SET_GROUP_OPTIONS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_GROUPS = "SET_GROUPS";
export const SET_USER_ROLE_GROUP_DATA = "SET_USER_ROLE_GROUP_DATA";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_TOTAL_ROLES = "SET_TOTAL_ROLES";
export const SET_GRP_DATA_FETCH_STATUS = "SET_GRP_DATA_FETCH_STATUS";
export const SET_ROLES = "SET_ROLES";
export const SET_USERS = "SET_USERS";
export const SET_ROLE_DATA_FETCH_STATUS = "SET_ROLE_DATA_FETCH_STATUS";
export const SET_USER_DATA_FETCH_STATUS = "SET_USER_DATA_FETCH_STATUS";
export const SET_PERMISSION_DATA_STATUS = "SET_PERMISSION_DATA_STATUS";

//ProductFilters
export const CHANGE_FILTER_DATA = "CHANGE_FILTER_DATA";

//Layout
export const LAYOUT_FETCHED = "LAYOUT_FETCHED";
export const SET_ROUTES = "SET_ROUTES";
export const SET_HEADER_MENU = "SET_HEADER_MENU";
export const SET_SIDEBAR_MENU = "SET_SIDEBAR_MENU";
//Tenant Settings
export const SET_TENANT_APPS = "SET_TENANT_APPS";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";

//Notifications
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_USER_NOTIFICATION_TYPES = "SET_USER_NOTIFICATION_TYPES";

//Workflow
export const GET_STATES = 'GET_STATES'
export const GET_TRANSITIONS = 'GET_TRANSITIONS'
export const GET_WORKFLOWS = 'GET_WORKFLOWS'
export const GET_TRANSITION_APPROVALS = 'GET_TRANSITION_APPROVALS'


//Ticketing
export const GET_TICKET = 'GET_TICKET'
