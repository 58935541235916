import React, { useEffect } from 'react';
import { verifyTokenStatus } from '../actions/authActions';
import { connect } from 'react-redux';

let isrefreshing = false;
let intervalId;
const TokenRefreshListener = (props) => {
  const TokenRefreshHandler = async () => {
    let expirationTime = localStorage.getItem('session.expiry');
    let currentTime = Date.now();
    let difference = (expirationTime - currentTime) / (1000 * 60);
    if (difference >= 30 && !isrefreshing) {
      isrefreshing = true;
      await props.tokenRefresh();
      isrefreshing = false;
    }
  };
  useEffect(() => {
    if (props.isAuthenticated) {
      intervalId = setInterval(TokenRefreshHandler, 25 * 60 * 1000);
    } else {
      clearInterval(intervalId);
    }
  }, [props.isAuthenticated]);
  return <>{props.children}</>;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tokenRefresh: () => dispatch(verifyTokenStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TokenRefreshListener);
