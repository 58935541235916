import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProductDetailEditDropdown from "./ProductDetailEditDropdown";
import ProductDetailRecategorization from "./ProductDetailRecategorization";

const DetailAttributes = ({
  attributes,
  L1L2Categories,
  confidence_score_percentage,
  productId,
  reset,
  loading,
  subCategoryRef,
  subCategoryFocus,
  onChangeSelectAttribute
}) => {
  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="baseline"
        style={{ marginBottom: "20px" }}
      >
        <Typography
          variant="h4"
          style={{
            fontSize: "24px",
            fontWeight: "500",
            color: "rgba(0,0,0)",
            // paddingLeft: '48px',
            paddingBottom: "0px",
            alignSelf: "center",
          }}
        >
          Attributes
        </Typography>
        <Box ml={1}>
          {/* <CircularProgressWithLabel style={{marginLeft: '20px'}} value={confidence_score_percentage} /> */}
          {/* <Typography variant="button" display="inline">
            {confidence_score_percentage}%
          </Typography> */}
        </Box>
      </Box>

      <div
        style={{ width: "80%", maxWidth: "550px", minWidth: "450px" }}
        id="productAttributes"
      >
        {loading && <LinearProgress variant="indeterminate" />}
        <ProductDetailRecategorization
          key={productId}
          L1L2Categories={L1L2Categories}
          onChangeSelectAttribute={onChangeSelectAttribute}
          reset={reset}
          subCategoryRef={subCategoryRef}
          subCategoryFocus={subCategoryFocus}
        />
        {attributes.map((attribute, i) => (
          <Attribute key={i} id={attribute.label}>
            <label htmlFor="">{attribute.label}</label>
            <Box position="relative">
              <ProductDetailEditDropdown
                key={i}
                attribute={attribute}
                index={i}
                onChangeSelectAttribute={onChangeSelectAttribute}
                reset={reset}
              />
              {/* <Box position="absolute" right={-40} top={4}>
                <Typography variant="overline" display="inline">
                  {attribute.mapping_confidence}%
                </Typography>
              </Box> */}
            </Box>
          </Attribute>
        ))}
      </div>
    </React.Fragment>
  );
};

DetailAttributes.propTypes = {
  attributes: PropTypes.array,
};

export default DetailAttributes;

const Attribute = styled.div`
  display: flex;
  // padding: 0 48px;
  margin: 10px 0;
  padding-right: 10px;
  align-items: center;
  justify-content: "space-between";

  label {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px 10px 0px;
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 0.7);
  }
  .product-detail-attribute-select {
    font-size: 14px;
    flex: 1 1 auto;
    width: 280px;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const CircularProgressWithLabel = (props) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" {...props} />
    <Box
      top={2}
      left={20}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
      >{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
);
