import axiosInstance from '../Utils/axios';
import {
  LOADER,
  SET_NOTIFICATIONS,
  SET_USER_NOTIFICATION_TYPES,
} from './types';
import { toast } from 'react-toastify';

export const getNotifications = (pageIndex, pageSize, filterOptions) => async (
  dispatch
) => {
  dispatch({
    type: LOADER,
    payload: {
      status: true,
    },
  });
  const data = await axiosInstance({
    url: `/user/notification/?page=${pageIndex}&&notification_type=${filterOptions.notification_type}&&status=${filterOptions.status}`,
    method: 'GET',
  });
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: Object.assign(
      {},
      {
        results: [...data.data.data.results],
        count: data.data.data.count,
      }
    ),
  });
  dispatch({
    type: LOADER,
    payload: {
      status: false,
    },
  });
};

export const onNotificationAction = (notification, action) => async (
  dispatch
) => {
  const data = {
    notification_id: [notification.id],
    action: action,
  };
  const res = await axiosInstance({
    url: `user/notification_action/`,
    method: 'POST',
    data: data,
  });
  if (res.data.status) {
    return [notification.id, true, res.data.data.message];
  } else {
    return [notification.id, false, res.data.data.message];
  }
};

export const getNotificationTypes = () => (dispatch) => {
  return axiosInstance({ url: `/user/notificationtype/`, method: 'GET' });
};

export const setNotifications = (notifications) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: Object.assign(
      {},
      {
        results: notifications.results,
        count: notifications.count,
      }
    ),
  });
};

export const getUserNotificationTypes = () => (dispatch) => {
  return axiosInstance({
    url: '/user/usernotificationtype/',
    method: 'GET',
  }).then((data) => {
    dispatch({
      type: SET_USER_NOTIFICATION_TYPES,
      payload: data.data.data.results,
    });
  });
};

export const setUserNotificationTypes = (payload) => (dispatch) => {
  return axiosInstance({
    url: '/user/user_notification_type_action/',
    method: 'POST',
    data: payload,
  }).then((res) => {
    if (res.data.status) {
      toast.success(res.data.data.message, {
        autoClose: 15000,
        hideProgressBar: true,
      });
    } else {
      toast.error(res.data.data.message, {
        autoClose: 15000,
        hideProgressBar: true,
      });
    }
  });
};
