import React, { useEffect } from 'react'
import Header from '../header'
import { APP_ATTRIBUTE_SMART, APP_PLATFORM } from '../../config/constants'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 50,
    padding: 50,
    minHeight: 'calc(100vh - 50px)',
    backgroundColor: '#f9f9f9',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(24),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    borderRadius: 6,
    border: 'solid 1px rgba(129, 147, 174, 0.12)',
    backgroundColor: '#fff',
    textAlign: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#63a2ff',
      top: -8,
      left: -1,
      width: 'calc(100% + 2px)',
      height: 8,
      borderRadius: '6px 6px 0 0'
    },
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      transform: 'translateY(2px)'
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = APP_PLATFORM.APP_NAME
  }, [])
  return (
    <div className='layout'>
      <Header title={APP_PLATFORM.APP_NAME} />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <Paper className={classes.paper} id={APP_ATTRIBUTE_SMART.APP_NAME} onClick={() => props.history.push('/attributesmart')}>
              <Typography variant="subtitle1" gutterBottom>
                {APP_ATTRIBUTE_SMART.APP_NAME}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <Paper elevation={1} className={classes.paper} id='MarkSmart' onClick={() => props.history.push('/marksmart/reporting')}>
              <Typography variant="subtitle1" gutterBottom>
                MarkSmart
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Home
