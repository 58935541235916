import React, { useState } from 'react'

import DateRangePicker from './RetailCalendarDatePicker'

function DateRange() {

    const [startDate, setstartDate] = useState(null)
    const [endDate, setendDate] = useState(null)

    const onDatesChange = ({startDate, endDate}) => {
        console.log(startDate, endDate)
        setstartDate(startDate);
        setendDate(endDate)
    }
    

    return (
        <div>
            <DateRangePicker
                startDate={startDate}
                startDateId="startDate_11"
                endDate={endDate}
                endDateId="endDate_11"
                onDatesChange={onDatesChange}
              />
        </div>
    )
}

export default DateRange
