import { AS_FETCH_ATTRIBUTES } from "../../../config/api";
import axiosInstance from "../../../Utils/axios";
import {
  REVIEWED_FLAG,
  COUNT,
  ATTRIBUTES_INIT,
  ATTRIBUTES_SUCCESS,
  ATTRIBUTES_ERROR,
} from "./types";

export const toggleView = (params) => (dispatch) => {
  dispatch({
    type: REVIEWED_FLAG,
    payload: params,
  });
};

export const setCount = (params) => (dispatch) => {
  dispatch({
    type: COUNT,
    payload: params,
  });
};

export const fetchAttributes = () => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTES_INIT,
    });

    const response = await axiosInstance.get(AS_FETCH_ATTRIBUTES);
    dispatch({
      type: ATTRIBUTES_SUCCESS,
      payload: response.data.data.tenant_attribute_list,
    });
  } catch (error) {
    dispatch({
      type: ATTRIBUTES_ERROR,
    });
  }
};
