export const ROLE_COLUMNS = [
  {
    Header: "Role Name",
    accessor: "role_name",
    disableSortBy: true,
  },
  {
    Header: "Role Desc",
    accessor: "role_desc",
    disableSortBy: true,
  },
];

export const GROUP_COLUMNS = [
  {
    Header: "Group Name",
    accessor: "name",
    disableSortBy: true,
  },
];

export const USERROLEGROUPMAP_COLUMNS = [
  {
    Header: "User",
    accessor: "email",
  },
  {
    Header: "# of Roles",
    accessor: "roles_length",
  },
  {
    Header: "# of Groups",
    accessor: "groups_length",
  },
];
