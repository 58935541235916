import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 120,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
  },
  select: {
    fontSize: 12,
    marginTop: "0 !important",
    marginLeft: 10,
  },
  menuItem: {
    fontSize: 12,
  },
  label: {
    top: "22%",
    left: -56,
    color: "#000000",
  },
}));

export default function SortButton({
  id,
  data,
  placeholder,
  callback,
  value,
  disabled,
}) {
  const classes = useStyles();

  return (
    <div variant="standard" className={classes.container}>
      <Typography variant="caption" id={`${id}-label`}>
        Sort By :
      </Typography>
      <Select
        disabled={disabled}
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={callback}
        className={classes.select}
      >
        {data.map((item, i) => (
          <MenuItem
            key={i}
            className={classes.menuItem}
            dense
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
