import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      marginTop:8,
    },
    list:{
        padding:0
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));


  const StyledMenuItem = withStyles((theme) => ({
    root: {
        lineHeight:'unset',
        fontSize:12,
        justifyContent:'center',
        width:120,
      '&:hover': {
        // backgroundColor: theme.palette.primary.light,
        // color:theme.palette.primary.contrastText,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


export default function ExportButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (params) => {
        console.log('handle close called')
        setAnchorEl(null)
    }

    const onExport = (params) => {
        setAnchorEl(null)
        props.onExport(params)
    }

    return (
        <div>
            <Button
                aria-controls="exportBtn"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                size="small"
                onClick={handleClick}
                style={{minWidth:120}}
                id='exportBtn'
                
            >
                Export
            </Button>
            <StyledMenu
                id="exportBtn"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem key='csv' onClick={() => onExport('csv')}>CSV</StyledMenuItem>
                <StyledMenuItem key='json' onClick={() => onExport('json')}>JSON</StyledMenuItem>
            </StyledMenu>
        </div>
    )
}
