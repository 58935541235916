import React from 'react';
import PropTypes from 'prop-types'; // for our bonus, see below
// eslint-disable-next-line
import ReactSelect, { components } from 'react-select';
import './index.css';

const ValueContainer = ({ children, selectProps, ...props }) => {
  const { getValue, hasValue } = props;
  
  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }
  const values = getValue()
  return (
    <components.ValueContainer {...props}>
      <span className="valueStyle">{values[0].label}</span>
      {children[1]}
      {values.length !== 1 && (
        <span className="counterStyle">+{selectProps.value.length - 1}</span>
      )}
    </components.ValueContainer>
  );
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.isMulti && !!props.options.length && (
        <div
          className="select-all"
          onClick={() => {
            if (
              props.options.length === (props.selectProps.value && props.selectProps.value.length)
            ) {
              props.setValue([]);
            } else {
              props.setValue(props.options);
            }
          }}>
          Select/Unselect All
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

const Select = (props) => {
  return (
    <ReactSelect
      {...props}
      hideSelectedOptions={false}
      components={{ ValueContainer, MenuList }}
      closeMenuOnSelect={false}
      styles={{
        input: (provided) => {
          const margin = 0;
          const padding = 0;
          const width = '50%';
          return { ...provided, margin, padding, width };
        },
        valueContainer: (provided) => {
          const position = 'static';
          return { ...provided, position };
        },
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'aliceblue',
          primary50: '#aabdc8',
          primary: '#8fa9b8',
        },
      })}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
};

export default Select;